

@media screen and (min-width: 650px) {
  .anyjob-error-comp-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }
  
  .anyjob-error-comp-container-title {
    font-size: 32px;
    font-weight: 700;
    color: #1a1a1a;
    text-align: center;
    margin-top: 40px;
  
  }
  
  .anyjob-error-comp-container-desc {
    font-size: 16px;
    font-weight: 500px;
    color: #1a1a1a;
    text-align: center;
    margin-top: 10px;
  }
  
  .anyjob-error-comp-btn {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    background-color: #F7CE38;
    border-radius: 50px;
    height: 56px;
    width: 361px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    cursor: pointer;
  }
}
@media screen and (max-width: 649px) {
  .anyjob-error-comp-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .anyjob-error-comp-container-title {
    font-size: 24px;
    font-weight: 700;
    color: #1a1a1a;
    text-align: center;
    margin-top: 40px;
  
  }
  
  .anyjob-error-comp-container-desc {
    font-size: 14px;
    font-weight: 500px;
    color: #1a1a1a;
    text-align: center;
    margin-top: 10px;
  }
  
  .anyjob-error-comp-btn {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    background-color: #F7CE38;
    border-radius: 50px;
    height: 56px;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    cursor: pointer;
  }
}