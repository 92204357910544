
@media screen and (min-width: 1031px) {
  .anyjob-auth-page-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header {
    display: flex;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-select-user-type-container {
    padding: 50px 100px;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header-go-back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .anyjob-auth-page-header-go-back-btn p {
    margin-left: 10px;
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .anyjob-auth-page-select-user-type-heading {
    margin-top: 60px;
  }
  
  .anyjob-auth-page-heading {
    color: #1A1A1A;
    font-size: 32px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }

  .anyjob-auth-page-heading-1 {
    color: #1A1A1A;
    font-size: 24px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }

  .anyjob-auth-page-email-icon {
    width: 250px;
    align-self: center;
    margin-top: 50px;
  }
  
  .anyjob-auth-page-desc {
    color: #555;
    margin-top: 10px;
    font-size: 16px;
    /* font-style: normal; */
    font-weight: 500;
    text-align: center;
  }
  
  .anyjob-auth-page-user-type-selections {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
  }
  
  .anyjob-auth-page-user-type-selections-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 392px;
    height: 320px;
    margin: 0px 15px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-user-type-selections-item:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 0px solid #D0C9D0;
    width: 392px;
    height: 320px;
    margin: 0px 15px;
    cursor: pointer;
    background-color: #ffeca8;
  }
  
  .anyjob-auth-page-user-type-selected-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 392px;
    height: 320px;
    margin: 0px 15px;
    cursor: pointer;
    background-color: #F7CE38;
  }
  
  .anyjob-auth-page-user-type-selections-item-title {
    color: #1A1A1A;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 81.25% */
    letter-spacing: -0.289px;
    margin-top: 15px;
  }
  
  .anyjob-auth-page-user-type-selections-item-desc {
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    margin-top: 15px;
  }
  
  .anyjob-auth-page-submit-btn {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 520px;
    height: 56px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-disabled-submit-btn {
    border-radius: 100px;
    background: #ffeca8;
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 520px;
    height: 56px;
  }
  
  .anyjob-auth-page-ctas {
    margin-top: 72px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .anyjob-auth-page-alt-action {
    margin-top: 40px;
    display: flex;
    align-items: center;
    color: #2D2D2D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-alt-action a {
    color: var(--Black-1, #FF5851);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-err-msg {
    color: #FF5851;
    font-size: 14px;
    text-align: center;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-content-container {
    display: flex;
    height: 100vh;  
  }
  
  .anyjob-auth-page-content-image-sec {
    background: var(--Primary-Color, #F7CE38);
    flex: 1;
    height: 100vh;  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .anyjob-auth-page-content-form-sec {
    flex: 1.4;
    background-color: white;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    padding: 60px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-auth-page-pagination-container {
    margin: 35px 0px;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-auth-page-active-page-count {
    background-color: #FDFDFD;
    border: 1px solid #FF5851;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF5851;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-inactive-page-count {
    background-color: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E0E0E0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-pagination-divider {
    width: 34px;
    height: 1px;
    background-color: #D4D4D4;
    margin: 0px 10px;
  }
  
  .anyjob-auth-page-form {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 560px;
  }
  
  .anyjob-auth-page-inputs-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    width: 100%;
  }
  
  .anyjob-auth-page-input-span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 30px;
  }
  
  .anyjob-auth-page-input-span1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .anyjob-auth-page-input-label {
    color: var(--Grey-1, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-auth-page-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
  }
  
  .anyjob-auth-page-input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
  }
  
  .anyjob-auth-page-inputs-group-divider {
    margin: 0px 20px;
  }
  
  .anyjob-auth-input-err-msg {
    color: #FF5851;
    font-size: 14px;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-phone-input-selector-btn {
    height: 60px;
  }
  
  .anyjob-auth-page-phone-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    width: 100%;
  }
  
  
  .anyjob-auth-page-phone-inner-input {
    height: 58px;
    width: 100%;
    border-radius: 50;
  }
  
  .anyjob-auth-page-street-suggestions {
    background: #FFF;
    border-radius: 10px;
    padding: 0px 10px 10px 10px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    height: 140px; /* Set the desired height for your container */  
    width: 560px;
    overflow: hidden; /* Hide any content that overflows the container */
    position: absolute;
    margin-top: 100px;
    z-index: 1000;
  }
  
  .anyjob-auth-page-street-suggestions-contents {
    height: 100%;
    overflow-y: scroll;
  }
  
  .anyjob-auth-page-suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: #FFF;
  
    z-index: 100;
  }
  
  .anyjob-auth-page-suggestion-item:hover {
    background-color: #F7F7F7;
    border-radius: 6px;
  }
  
  .anyjob-auth-page-suggestion-item p {
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-password-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }
  
  .anyjob-auth-page-password-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }

  .anyjob-auth-page-password-2-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  
  .anyjob-auth-page-password-2-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-2-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }
  
  .anyjob-auth-page-password-2-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }
  
  .anyjob-auth-page-toggle-password {
  }
  
  .anyjob-auth-terms-input-group {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 15px;
  }
  
  .anyjob-auth-terms-input-group p {
    margin-left: 10px;
    color: #4B4B4B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
  
  .anyjob-auth-terms-input-group a {
    color: #FF5851;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  
  .anyjob-auth-terms-input-group input {
    background-color: #FF5851;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid #FF5851;
    background-color: #fff;
  }
  
  .anyjob-auth-terms-input-group input:checked {
    background-color: #FF5851;
    width: 24px;
    height: 24px;
    border: 1.389px solid #FF5851;
  }
  
  .anyjob-auth-page-password-validations-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -10px;
  }
  
  .anyjob-auth-page-password-validation-active {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #CBFADE;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation-active p {
    color: #06BE54;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }
  
  .anyjob-auth-page-password-validation {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #F3F3F3;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation p {
    color: #555555;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }

  .anyjob-auth-page-country-n-city-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
    justify-content: space-between;
    gap: 30px;
  }

  .anyjob-auth-page-country-n-city-section-item {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 769px) and (max-width: 1030px) {
  .anyjob-auth-page-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header {
    display: flex;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-select-user-type-container {
    padding: 50px 50px;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header-go-back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .anyjob-auth-page-header-go-back-btn p {
    margin-left: 10px;
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .anyjob-auth-page-select-user-type-heading {
    margin-top: 60px;
  }
  
  .anyjob-auth-page-heading {
    color: #1A1A1A;
    font-size: 32px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }
  
  .anyjob-auth-page-heading-1 {
    color: #1A1A1A;
    font-size: 24px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }

  .anyjob-auth-page-email-icon {
    width: 220px;
    align-self: center;
    margin-top: 50px;
  }
  
  .anyjob-auth-page-desc {
    color: #555;
    margin-top: 10px;
    font-size: 16px;
    /* font-style: normal; */
    font-weight: 500;
    text-align: center;
  }
  
  .anyjob-auth-page-user-type-selections {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
  }
  
  .anyjob-auth-page-user-type-selections-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 392px;
    height: 320px;
    margin: 0px 15px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-user-type-selections-item:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 0px solid #D0C9D0;
    width: 392px;
    height: 320px;
    margin: 0px 15px;
    cursor: pointer;
    background-color: #ffeca8;
  }
  
  .anyjob-auth-page-user-type-selected-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 392px;
    height: 320px;
    margin: 0px 15px;
    cursor: pointer;
    background-color: #F7CE38;
  }
  
  .anyjob-auth-page-user-type-selections-item-title {
    color: #1A1A1A;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 81.25% */
    letter-spacing: -0.289px;
    margin-top: 15px;
  }
  
  .anyjob-auth-page-user-type-selections-item-desc {
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    margin-top: 15px;
  }
  
  .anyjob-auth-page-submit-btn {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 520px;
    height: 56px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-disabled-submit-btn {
    border-radius: 100px;
    background: #ffeca8;
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 520px;
    height: 56px;
  }
  
  .anyjob-auth-page-ctas {
    margin-top: 72px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .anyjob-auth-page-alt-action {
    margin-top: 40px;
    display: flex;
    align-items: center;
    color: #2D2D2D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-alt-action a {
    color: var(--Black-1, #FF5851);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-err-msg {
    color: #FF5851;
    font-size: 14px;
    text-align: center;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-content-container {
    display: flex;
    height: 100vh;  
  }
  
  .anyjob-auth-page-content-image-sec {
    display: none;
    background: var(--Primary-Color, #F7CE38);
    flex: 1;
    height: 100vh;  
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .anyjob-auth-page-content-form-sec {
    flex: 1.4;
    background-color: white;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    padding: 60px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-auth-page-pagination-container {
    margin: 35px 0px;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-auth-page-active-page-count {
    background-color: #FDFDFD;
    border: 1px solid #FF5851;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF5851;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-inactive-page-count {
    background-color: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E0E0E0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-pagination-divider {
    width: 34px;
    height: 1px;
    background-color: #D4D4D4;
    margin: 0px 10px;
  }
  
  .anyjob-auth-page-form {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 560px;
  }
  
  .anyjob-auth-page-inputs-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    width: 100%;
  }
  
  .anyjob-auth-page-input-span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 30px;
  }

  .anyjob-auth-page-input-span1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .anyjob-auth-page-input-label {
    color: var(--Grey-1, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-auth-page-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
  }
  
  .anyjob-auth-page-input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
  }
  
  .anyjob-auth-page-inputs-group-divider {
    margin: 0px 20px;
  }
  
  .anyjob-auth-input-err-msg {
    color: #FF5851;
    font-size: 14px;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-phone-input-selector-btn {
    height: 60px;
  }
  
  .anyjob-auth-page-phone-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    width: 100%;
  }
  
  
  .anyjob-auth-page-phone-inner-input {
    height: 58px;
    width: 100%;
    border-radius: 50;
  }
  
  .anyjob-auth-page-street-suggestions {
    background: #FFF;
    border-radius: 10px;
    padding: 0px 10px 10px 10px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    height: 140px; /* Set the desired height for your container */  
    width: 560px;
    overflow: hidden; /* Hide any content that overflows the container */
    position: absolute;
    margin-top: 100px;
    z-index: 1000;
  }
  
  .anyjob-auth-page-street-suggestions-contents {
    height: 100%;
    overflow-y: scroll;
  }
  
  .anyjob-auth-page-suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: #FFF;
  
    z-index: 100;
  }
  
  .anyjob-auth-page-suggestion-item:hover {
    background-color: #F7F7F7;
    border-radius: 6px;
  }
  
  .anyjob-auth-page-suggestion-item p {
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-password-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }
  
  .anyjob-auth-page-password-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }

  .anyjob-auth-page-password-2-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-2-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-2-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }
  
  .anyjob-auth-page-password-2-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }

  
  .anyjob-auth-page-toggle-password {
  }
  
  .anyjob-auth-terms-input-group {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 15px;
  }
  
  .anyjob-auth-terms-input-group p {
    margin-left: 10px;
    color: #4B4B4B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
  
  .anyjob-auth-terms-input-group a {
    color: #FF5851;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  
  .anyjob-auth-terms-input-group input {
    background-color: #FF5851;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid #FF5851;
    background-color: #fff;
  }
  
  .anyjob-auth-terms-input-group input:checked {
    background-color: #FF5851;
    width: 24px;
    height: 24px;
    border: 1.389px solid #FF5851;
  }
  
  .anyjob-auth-page-password-validations-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -10px;
  }
  
  .anyjob-auth-page-password-validation-active {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #CBFADE;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation-active p {
    color: #06BE54;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }
  
  .anyjob-auth-page-password-validation {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #F3F3F3;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation p {
    color: #555555;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }


  .anyjob-auth-page-country-n-city-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
    justify-content: space-between;
    gap: 30px;
  }

  .anyjob-auth-page-country-n-city-section-item {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 620px) and (max-width: 768px) {
  .anyjob-auth-page-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header {
    display: flex;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-select-user-type-container {
    padding: 50px 50px;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header-go-back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .anyjob-auth-page-header-go-back-btn p {
    margin-left: 10px;
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .anyjob-auth-page-select-user-type-heading {
    margin-top: 60px;
  }
  
  .anyjob-auth-page-heading {
    color: #1A1A1A;
    font-size: 28px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }

  .anyjob-auth-page-heading-1 {
    color: #1A1A1A;
    font-size: 24px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }

  .anyjob-auth-page-email-icon {
    width: 220px;
    align-self: center;
    margin-top: 50px;
  }
  
  .anyjob-auth-page-desc {
    color: #555;
    margin-top: 10px;
    font-size: 14px;
    /* font-style: normal; */
    font-weight: 500;
    text-align: center;
  }
  
  .anyjob-auth-page-user-type-selections {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  
  .anyjob-auth-page-user-type-selections-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 380px;
    height: 280px;
    padding: 15px;
    margin: 0px 15px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-user-type-selections-item:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 0px solid #D0C9D0;
    width: 392px;
    margin: 0px 15px;
    cursor: pointer;
    background-color: #ffeca8;
  }
  
  .anyjob-auth-page-user-type-selected-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 380px;
    height: 280px;
    padding: 15px;
    margin: 0px 15px;
    cursor: pointer;
    background-color: #F7CE38;
  }
  
  .anyjob-auth-page-user-type-selections-item-title {
    color: #1A1A1A;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 81.25% */
    letter-spacing: -0.289px;
    margin-top: 15px;
  }
  
  .anyjob-auth-page-user-type-selections-item-desc {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    margin-top: 15px;
    text-align: center;
  }
  
  .anyjob-auth-page-submit-btn {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 520px;
    height: 56px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-disabled-submit-btn {
    border-radius: 100px;
    background: #ffeca8;
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 520px;
    height: 56px;
  }
  
  .anyjob-auth-page-ctas {
    margin-top: 72px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .anyjob-auth-page-alt-action {
    margin-top: 40px;
    display: flex;
    align-items: center;
    color: #2D2D2D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-alt-action a {
    color: var(--Black-1, #FF5851);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-err-msg {
    color: #FF5851;
    font-size: 14px;
    text-align: center;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-content-container {
    display: flex;
    height: 100vh;  
  }
  
  .anyjob-auth-page-content-image-sec {
    display: none;
    background: var(--Primary-Color, #F7CE38);
    flex: 1;
    height: 100vh;  
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .anyjob-auth-page-content-form-sec {
    flex: 1.4;
    background-color: white;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    padding: 60px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-auth-page-pagination-container {
    margin: 35px 0px;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-auth-page-active-page-count {
    background-color: #FDFDFD;
    border: 1px solid #FF5851;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF5851;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-inactive-page-count {
    background-color: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E0E0E0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-pagination-divider {
    width: 34px;
    height: 1px;
    background-color: #D4D4D4;
    margin: 0px 10px;
  }
  
  .anyjob-auth-page-form {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 560px;
  }
  
  .anyjob-auth-page-inputs-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    width: 100%;
  }
  
  .anyjob-auth-page-input-span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 30px;
  }

  .anyjob-auth-page-input-span1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .anyjob-auth-page-input-label {
    color: var(--Grey-1, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-auth-page-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
  }
  
  .anyjob-auth-page-input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
  }
  
  .anyjob-auth-page-inputs-group-divider {
    margin: 0px 20px;
  }
  
  .anyjob-auth-input-err-msg {
    color: #FF5851;
    font-size: 14px;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-phone-input-selector-btn {
    height: 60px;
  }
  
  .anyjob-auth-page-phone-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    width: 100%;
  }
  
  
  .anyjob-auth-page-phone-inner-input {
    height: 58px;
    width: 100%;
    border-radius: 50;
  }
  
  .anyjob-auth-page-street-suggestions {
    background: #FFF;
    border-radius: 10px;
    padding: 0px 10px 10px 10px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    height: 140px; /* Set the desired height for your container */  
    width: 560px;
    overflow: hidden; /* Hide any content that overflows the container */
    position: absolute;
    margin-top: 100px;
    z-index: 1000;
  }
  
  .anyjob-auth-page-street-suggestions-contents {
    height: 100%;
    overflow-y: scroll;
  }
  
  .anyjob-auth-page-suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: #FFF;
  
    z-index: 100;
  }
  
  .anyjob-auth-page-suggestion-item:hover {
    background-color: #F7F7F7;
    border-radius: 6px;
  }
  
  .anyjob-auth-page-suggestion-item p {
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-password-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }
  
  .anyjob-auth-page-password-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }

  .anyjob-auth-page-password-2-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-2-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 60px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-2-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }
  
  .anyjob-auth-page-password-2-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 58px;
  }
  
  .anyjob-auth-page-toggle-password {
  }
  
  .anyjob-auth-terms-input-group {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 15px;
  }
  
  .anyjob-auth-terms-input-group p {
    margin-left: 10px;
    color: #4B4B4B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
  
  .anyjob-auth-terms-input-group a {
    color: #FF5851;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  
  .anyjob-auth-terms-input-group input {
    background-color: #FF5851;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid #FF5851;
    background-color: #fff;
  }
  
  .anyjob-auth-terms-input-group input:checked {
    background-color: #FF5851;
    width: 24px;
    height: 24px;
    border: 1.389px solid #FF5851;
  }
  
  .anyjob-auth-page-password-validations-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -10px;
  }
  
  .anyjob-auth-page-password-validation-active {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #CBFADE;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation-active p {
    color: #06BE54;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }
  
  .anyjob-auth-page-password-validation {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #F3F3F3;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation p {
    color: #555555;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }


  .anyjob-auth-page-country-n-city-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
    justify-content: space-between;
    gap: 30px;
  }

  .anyjob-auth-page-country-n-city-section-item {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 480px) and (max-width: 619px) {
  .anyjob-auth-page-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header {
    display: flex;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-select-user-type-container {
    padding: 50px 50px;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header-go-back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .anyjob-auth-page-header-go-back-btn p {
    margin-left: 10px;
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .anyjob-auth-page-select-user-type-heading {
    margin-top: 60px;
  }
  
  .anyjob-auth-page-heading {
    color: #1A1A1A;
    font-size: 28px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }

  .anyjob-auth-page-heading-1 {
    color: #1A1A1A;
    font-size: 18px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }

  .anyjob-auth-page-email-icon {
    width: 180px;
    align-self: center;
    margin-top: 50px;
  }
  
  .anyjob-auth-page-desc {
    color: #555;
    margin-top: 10px;
    font-size: 14px;
    /* font-style: normal; */
    font-weight: 500;
    text-align: center;
  }
  
  .anyjob-auth-page-user-type-selections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .anyjob-auth-page-user-type-selections-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 270px;
    height: 214px;
    padding: 15px;
    margin: 20px 0px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-user-type-selections-item:hover {
    background-color: #ffeca8;
  }
  
  .anyjob-auth-page-user-type-selected-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 270px;
    height: 214px;
    padding: 15px;
    margin: 20px 0px;
    cursor: pointer;
    background-color: #F7CE38;
  }

  .anyjob-auth-page-user-type-selected-item img {
    width: 40px;
    height: 40px;
  }

  .anyjob-auth-page-user-type-selections-item img {
    width: 40px;
    height: 40px;
  }
  
  .anyjob-auth-page-user-type-selections-item-title {
    color: #1A1A1A;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 81.25% */
    letter-spacing: -0.289px;
    margin-top: 15px;
  }
  
  .anyjob-auth-page-user-type-selections-item-desc {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    margin-top: 15px;
    text-align: center;
  }
  
  .anyjob-auth-page-submit-btn {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 350px;
    height: 56px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-disabled-submit-btn {
    border-radius: 100px;
    background: #ffeca8;
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 350px;
    height: 56px;
  }
  
  .anyjob-auth-page-ctas {
    margin-top: 72px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .anyjob-auth-page-alt-action {
    margin-top: 40px;
    display: flex;
    align-items: center;
    color: #2D2D2D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-alt-action a {
    color: var(--Black-1, #FF5851);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-err-msg {
    color: #FF5851;
    font-size: 14px;
    text-align: center;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-content-container {
    display: flex;
    height: 100vh;  
  }
  
  .anyjob-auth-page-content-image-sec {
    display: none;
    background: var(--Primary-Color, #F7CE38);
    flex: 1;
    height: 100vh;  
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .anyjob-auth-page-content-form-sec {
    flex: 1.4;
    background-color: white;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    padding: 60px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-auth-page-pagination-container {
    margin: 35px 0px;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-auth-page-active-page-count {
    background-color: #FDFDFD;
    border: 1px solid #FF5851;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF5851;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-inactive-page-count {
    background-color: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E0E0E0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-pagination-divider {
    width: 34px;
    height: 1px;
    background-color: #D4D4D4;
    margin: 0px 10px;
  }
  
  .anyjob-auth-page-form {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 420px;
  }
  
  .anyjob-auth-page-inputs-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    width: 100%;
  }
  
  .anyjob-auth-page-input-span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 30px;
  }

  .anyjob-auth-page-input-span1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .anyjob-auth-page-input-label {
    color: var(--Grey-1, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-auth-page-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
  }
  
  .anyjob-auth-page-input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
  }
  
  .anyjob-auth-page-inputs-group-divider {
    margin: 0px 10px;
  }
  
  .anyjob-auth-input-err-msg {
    color: #FF5851;
    font-size: 14px;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-phone-input-selector-btn {
    height: 50px;
  }
  
  .anyjob-auth-page-phone-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    margin-top: 10px;
    width: 100%;
  }
  
  
  .anyjob-auth-page-phone-inner-input {
    height: 58px;
    width: 100%;
    border-radius: 50;
  }
  
  .anyjob-auth-page-street-suggestions {
    background: #FFF;
    border-radius: 10px;
    padding: 0px 10px 10px 10px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    height: 140px; /* Set the desired height for your container */  
    width: 420px;
    overflow: hidden; /* Hide any content that overflows the container */
    position: absolute;
    margin-top: 100px;
    z-index: 1000;
  }
  
  .anyjob-auth-page-street-suggestions-contents {
    height: 100%;
    overflow-y: scroll;
  }
  
  .anyjob-auth-page-suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: #FFF;
  
    z-index: 100;
  }
  
  .anyjob-auth-page-suggestion-item:hover {
    background-color: #F7F7F7;
    border-radius: 6px;
  }
  
  .anyjob-auth-page-suggestion-item p {
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-password-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 48px;
  }
  
  .anyjob-auth-page-password-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 48px;
  }
  
  .anyjob-auth-page-password-2-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-2-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-2-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 48px;
  }
  
  .anyjob-auth-page-password-2-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 48px;
  }
  
  .anyjob-auth-page-toggle-password {
  }
  
  .anyjob-auth-terms-input-group {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 15px;
  }
  
  .anyjob-auth-terms-input-group p {
    margin-left: 10px;
    color: #4B4B4B;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
  
  .anyjob-auth-terms-input-group a {
    color: #FF5851;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  
  .anyjob-auth-terms-input-group input {
    background-color: #FF5851;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 2px solid #FF5851;
    background-color: #fff;
  }
  
  .anyjob-auth-terms-input-group input:checked {
    background-color: #FF5851;
    width: 24px;
    height: 24px;
    border: 1.389px solid #FF5851;
  }
  
  .anyjob-auth-page-password-validations-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -10px;
  }
  
  .anyjob-auth-page-password-validation-active {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #CBFADE;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation-active p {
    color: #06BE54;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }
  
  .anyjob-auth-page-password-validation {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #F3F3F3;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation p {
    color: #555555;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }


  .anyjob-auth-page-country-n-city-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    justify-content: space-between;
  }

  .anyjob-auth-page-country-n-city-section-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 479px){
  .anyjob-auth-page-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header {
    display: flex;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-select-user-type-container {
    padding: 20px;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-auth-page-header-go-back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .anyjob-auth-page-header-go-back-btn p {
    margin-left: 10px;
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .anyjob-auth-page-select-user-type-heading {
    margin-top: 20px;
  }
  
  .anyjob-auth-page-heading {
    color: #1A1A1A;
    font-size: 24px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }

  .anyjob-auth-page-heading-1 {
    color: #1A1A1A;
    font-size: 18px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }

  .anyjob-auth-page-email-icon {
    width: 180px;
    align-self: center;
    margin-top: 50px;
  }
  
  .anyjob-auth-page-desc {
    color: #555;
    margin-top: 6px;
    font-size: 14px;
    /* font-style: normal; */
    font-weight: 500;
    text-align: center;
  }
  
  .anyjob-auth-page-user-type-selections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  
  .anyjob-auth-page-user-type-selections-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 46%;
    height: 214px;
    padding: 10px;
    margin: 10px 0px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-user-type-selections-item:hover {
    background-color: #ffeca8;
  }
  
  .anyjob-auth-page-user-type-selected-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #D0C9D0;
    width: 46%;
    height: 214px;
    padding: 10px;
    margin: 10px 0px;
    cursor: pointer;
    background-color: #F7CE38;
  }

  .anyjob-auth-page-user-type-selected-item img {
    width: 40px;
    height: 40px;
  }

  .anyjob-auth-page-user-type-selections-item img {
    width: 40px;
    height: 40px;
  }
  
  .anyjob-auth-page-user-type-selections-item-title {
    color: #1A1A1A;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 81.25% */
    letter-spacing: -0.289px;
    margin-top: 15px;
  }
  
  .anyjob-auth-page-user-type-selections-item-desc {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    margin-top: 15px;
    text-align: center;
  }
  
  .anyjob-auth-page-submit-btn {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 320px;
    height: 56px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-disabled-submit-btn {
    border-radius: 100px;
    background: #ffeca8;
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */  
    width: 320px;
    height: 56px;
  }
  
  .anyjob-auth-page-ctas {
    margin-top: 72px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .anyjob-auth-page-alt-action {
    margin-top: 40px;
    display: flex;
    align-items: center;
    color: #2D2D2D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-alt-action a {
    color: var(--Black-1, #FF5851);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-err-msg {
    color: #FF5851;
    font-size: 14px;
    text-align: center;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-content-container {
    display: flex;
    height: 100vh;  
  }
  
  .anyjob-auth-page-content-image-sec {
    display: none;
    background: var(--Primary-Color, #F7CE38);
    flex: 1;
    height: 100vh;  
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .anyjob-auth-page-content-form-sec {
    flex: 1.4;
    background-color: white;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    padding: 20px;
    display: flex;
    flex-direction: column;

  }
  
  .anyjob-auth-page-pagination-container {
    margin: 35px 0px;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-auth-page-active-page-count {
    background-color: #FDFDFD;
    border: 1px solid #FF5851;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF5851;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-inactive-page-count {
    background-color: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E0E0E0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }
  
  .anyjob-auth-page-pagination-divider {
    width: 34px;
    height: 1px;
    background-color: #D4D4D4;
    margin: 0px 10px;
  }
  
  .anyjob-auth-page-form {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 350px;
  }
  
  .anyjob-auth-page-inputs-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    width: 100%;
  }
  
  .anyjob-auth-page-input-span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 30px;
  }

  .anyjob-auth-page-input-span1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .anyjob-auth-page-input-label {
    color: var(--Grey-1, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-auth-page-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
  }
  
  .anyjob-auth-page-input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
  }
  
  .anyjob-auth-page-inputs-group-divider {
    margin: 0px 10px;
  }
  
  .anyjob-auth-input-err-msg {
    color: #FF5851;
    font-size: 14px;
    margin: 10px 0px;
  }
  
  .anyjob-auth-page-phone-input-selector-btn {
    height: 50px;
  }
  
  .anyjob-auth-page-phone-input {
    outline: none;
    box-shadow: none;
  
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    margin-top: 10px;
    width: 100%;
  }
  
  
  .anyjob-auth-page-phone-inner-input {
    height: 58px;
    width: 100%;
    border-radius: 50;
  }
  
  .anyjob-auth-page-street-suggestions {
    background: #FFF;
    border-radius: 10px;
    padding: 0px 10px 10px 10px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    height: 140px; /* Set the desired height for your container */  
    width: 350px;
    overflow: hidden; /* Hide any content that overflows the container */
    position: absolute;
    margin-top: 100px;
    z-index: 1000;
  }
  
  .anyjob-auth-page-street-suggestions-contents {
    height: 100%;
    overflow-y: scroll;
  }
  
  .anyjob-auth-page-suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: #FFF;
  
    z-index: 100;
  }
  
  .anyjob-auth-page-suggestion-item:hover {
    background-color: #F7F7F7;
    border-radius: 6px;
  }
  
  .anyjob-auth-page-suggestion-item p {
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .anyjob-auth-page-password-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 48px;
  }
  
  .anyjob-auth-page-password-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 48px;
  }
  
  .anyjob-auth-page-password-2-input {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-2-input-focus {
    border-radius: 100px;
    border: 1px solid var(--Gray-5, #F7CE38);
    background: #FFF;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-auth-page-password-2-input input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 48px;
  }
  
  .anyjob-auth-page-password-2-input-focus input {
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 48px;
  }
  
  .anyjob-auth-page-toggle-password {
  }
  
  .anyjob-auth-terms-input-group {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 15px;
  }
  
  .anyjob-auth-terms-input-group p {
    margin-left: 10px;
    color: #4B4B4B;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
  
  .anyjob-auth-terms-input-group a {
    color: #FF5851;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  
  .anyjob-auth-terms-input-group input {
    background-color: #FF5851;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 2px solid #FF5851;
    background-color: #fff;
  }
  
  .anyjob-auth-terms-input-group input:checked {
    background-color: #FF5851;
    width: 24px;
    height: 24px;
    border: 1.389px solid #FF5851;
  }
  
  .anyjob-auth-page-password-validations-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -10px;
  }
  
  .anyjob-auth-page-password-validation-active {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #CBFADE;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation-active p {
    color: #06BE54;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }
  
  .anyjob-auth-page-password-validation {
    height: 32px;
    border-radius: 32px;
    padding: 0px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    background: #F3F3F3;
    margin-bottom: 8px;
  }
  
  .anyjob-auth-page-password-validation p {
    color: #555555;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
    margin-left: 8px;
  }


  .anyjob-auth-page-country-n-city-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    justify-content: space-between;
  }

  .anyjob-auth-page-country-n-city-section-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.anyjob-auth-page-header-logo {
  width: 95px;
}


#form3 {
  margin-top: 60px;
}

.anyjob-auth-page-remember-me-n-forgot-password {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -10px;
  justify-content: space-between;
}

.anyjob-auth-remember-me {
  display: flex;
  align-items: center;
}

.anyjob-auth-remember-me p {
  color: var(--Grey-1, #555);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}

.anyjob-auth-remember-me input {
  width: 18px;
  height: 18px;
}

.anyjob-auth-verify-otp-email {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-top: 10px;
}

.anyjob-auth-verify-otp-email p {
  margin-left: 10px;
  color: var(--Secondary-Color, #FF5851);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
}

.anyjob-auth-otp-input-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.anyjob-auth-otp-input {
  border-radius: 8px;
  border: 1px solid var(--Grey-2, #BDBDBD);
  width: 50px;
  height: 50px;
  margin: 0px 10px;
  display: flex;
  padding: 0px 18px;
  justify-content: center;
  align-items: center;
}

.anyjob-auth-otp-input:focus {
  border-radius: 8px;
  border: 1px solid var(--Grey-2, #FF5851);
  width: 50px;
  height: 50px;
}

.anyjob-auth-page-reset-password-divider {
  width: 100%;
  height: 1px;
  background-color: #BDBDBD;
  margin-top: 30px;
  margin-bottom: 50px;
}