.anyjob-transactions-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
}
.anyjob-transactions-page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;
}

.anyjob-transactions-page-transactions-container {
  margin: 40px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.anyjob-transactions-page-transactions-table {
  width: 100%;
  border-radius: 10px;
}

.anyjob-transactions-page-transactions-table-head {
  background-color: #F4F4F4;
  border-radius: 10px;
}

#anyjob-transactions-page-transactions-table-trans-id {
  width: 25%;
  font-size: 14px;
  font-weight: 400;
  color: #BDBDBD;
  padding: 16px 0px;
  text-align: center;
}

.anyjob-transactions-page-transactions-table th {
  width: 25%;
  font-size: 14px;
  font-weight: 600;
  color: #1A1A1A;
  padding: 16px 0px;
}

.anyjob-transactions-page-transactions-table td {
  width: 20%;
  font-size: 14px;
  font-weight: 400;
  color: #1A1A1A;
  padding: 16px 0px;
  text-align: center;
}