
@media screen and (min-width: 1040px) {
  .anyjob-footer-container {
    display: flex;
    justify-content: space-between;
    padding: 72px 100px;
    background: #F7CE38;
    align-items: center;
  }
  
  .anyjob-footer-company-info-section {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-footer-logo {
    width: 120px;
  }
  
  .anyjob-footer-desc-01 {
    color: #2b2a2a;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin-top: 40px;
    max-width: 360px;
  }
  
  .anyjob-footer-contact-details {
    display: flex;
    align-items: center;
    margin-top: 24px;
    flex-wrap: wrap;
  }
  
  .anyjob-footer-desc-02 {
    color: #2b2a2a;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  
  .anyjob-footer-divider-01 {
    width: 1px;
    height: 17px;
    background: #555;
    margin: 0px 16px;
  }
  
  .anyjob-footer-copyright {
    color: #2b2a2a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 24px;
  }
  
  .anyjob-footer-ctas {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  
  .anyjob-footer-primary-btn {  
    display: flex;
    padding: 16px 24px;
    align-items: center;
    border-radius: 100px;
    background: #FFF;
  
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    letter-spacing: 0.72px;
  }
  
  .anyjob-footer-socials-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 44px;
  }
  
  .anyjob-footer-socials-btn {
    cursor: pointer;
    width: 48px;
    height: 48px;
  }
}

@media screen and (min-width: 720px) and (max-width: 1039px) {
  .anyjob-footer-container {
    display: flex;
    justify-content: space-between;
    padding: 72px;
    background: #F7CE38;
    align-items: center;
    gap: 40px;
  }
  
  .anyjob-footer-company-info-section {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-footer-logo {
    width: 120px;
  }
  
  .anyjob-footer-desc-01 {
    color: #2b2a2a;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin-top: 40px;
    max-width: 360px;
  }
  
  .anyjob-footer-contact-details {
    display: flex;
    align-items: center;
    margin-top: 24px;
    flex-wrap: wrap;
    display: none;
  }
  
  .anyjob-footer-desc-02 {
    color: #2b2a2a;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  
  .anyjob-footer-divider-01 {
    width: 1px;
    height: 17px;
    background: #555;
    margin: 0px 16px;
  }
  
  .anyjob-footer-copyright {
    color: #2b2a2a;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 24px;
  }
  
  .anyjob-footer-ctas {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  
  .anyjob-footer-primary-btn {  
    display: flex;
    padding: 12px 16px;
    align-items: center;
    border-radius: 100px;
    background: #FFF;
  
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    letter-spacing: 0.72px;
  }
  
  .anyjob-footer-socials-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 30px;
  }
  
  .anyjob-footer-socials-btn {
    cursor: pointer;
    width: 48px;
    height: 48px;
  }
}

@media screen and (max-width: 719px) {
  .anyjob-footer-container {
    display: flex;
    justify-content: space-between;
    padding: 50px 20px;
    background: #F7CE38;
    align-items: center;
    gap: 40px;
  }
  
  .anyjob-footer-company-info-section {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-footer-logo {
    width: 120px;
  }
  
  .anyjob-footer-desc-01 {
    color: #2b2a2a;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin-top: 30px;
    max-width: 360px;
  }
  
  .anyjob-footer-contact-details {
    display: flex;
    align-items: center;
    margin-top: 24px;
    flex-wrap: wrap;
    display: none;
  }
  
  .anyjob-footer-desc-02 {
    color: #2b2a2a;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  
  .anyjob-footer-divider-01 {
    width: 1px;
    height: 17px;
    background: #555;
    margin: 0px 16px;
  }
  
  .anyjob-footer-copyright {
    color: #2b2a2a;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 24px;
  }
  
  .anyjob-footer-ctas {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  
  .anyjob-footer-primary-btn {  
    display: flex;
    padding: 8px 12px ;
    align-items: center;
    border-radius: 100px;
    background: #FFF;
  
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    letter-spacing: 0.72px;
  }
  
  .anyjob-footer-socials-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 30px;
  }
  
  .anyjob-footer-socials-btn {
    cursor: pointer;
    min-width: 32px;
    min-height: 32px;
  }
}