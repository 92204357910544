

@media screen and (min-width: 650px) {
  .anyjob-camera-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1;
    z-index: 99999;
  }
  
  .anyjob-camera-modal-contents {
    width: 680px;
    background-color: #FFF;
    border-radius: 24px;
    align-self: center;
    justify-self: center;
    margin-top: 20;
    z-index: 99999;
  }
  
  .anyjob-camera-modal-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FF5851;
    height: 80px;
    padding: 0px 24px;
  }
  
  .anyjob-camera-modal-contents-header p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.48px;
  }
  
  .anyjob-camera-modal-close-btn {
    padding: 10px;
    cursor: pointer;
    
  }
  
  .anyjob-camera-modal-close-icon {
    width: 32px;
    height: 32px;
    color: black;
  }
  
  .anyjob-camera-view {
    width: 680px;
  }
  
  .anyjob-camera-image-capture-container {
    width: 680px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0px;
  }
  
  .anyjob-camera-capture-image-btn {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #e2e2e2;
    align-self: 'center';
  }
  
  .anyjob-camera-video-record-container {
    width: 680px;
    display: flex;
    margin: 20px 0px;
    flex-direction: row;
    justify-content: center;
    height: 90px;
  }
  
  .anyjob-camera-video-pause-btn {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #FF5851;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  
  .anyjob-camera-video-pause-btn div {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: #FFF;
  }
  
  .anyjob-camera-video-recording-action {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 55px;
    
  }
  
  .anyjob-camera-video-time {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
  }
  
  .anyjob-camera-video-pause-play-recording {
    width: 24px;
    cursor: pointer;
  }
}

@media screen and (min-width: 520px) and (max-width: 649px) {
  .anyjob-camera-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1;
    z-index: 99999;
  }
  
  .anyjob-camera-modal-contents {
    width: 500px;
    background-color: #FFF;
    border-radius: 24px;
    align-self: center;
    justify-self: center;
    margin-top: 20;
    z-index: 99999;
  }
  
  .anyjob-camera-modal-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FF5851;
    height: 80px;
    padding: 0px 24px;
  }
  
  .anyjob-camera-modal-contents-header p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.48px;
  }
  
  .anyjob-camera-modal-close-btn {
    padding: 10px;
    cursor: pointer;
    
  }
  
  .anyjob-camera-modal-close-icon {
    width: 32px;
    height: 32px;
    color: black;
  }
  
  .anyjob-camera-view {
    width: 500px;
  }
  
  .anyjob-camera-image-capture-container {
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0px;
  }
  
  .anyjob-camera-capture-image-btn {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #e2e2e2;
    align-self: 'center';
  }
  
  .anyjob-camera-video-record-container {
    width: 500px;
    display: flex;
    margin: 20px 0px;
    flex-direction: row;
    justify-content: center;
    height: 90px;
  }
  
  .anyjob-camera-video-pause-btn {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #FF5851;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  
  .anyjob-camera-video-pause-btn div {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: #FFF;
  }
  
  .anyjob-camera-video-recording-action {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 55px;
    
  }
  
  .anyjob-camera-video-time {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
  }
  
  .anyjob-camera-video-pause-play-recording {
    width: 24px;
    cursor: pointer;
  }
}

@media screen and (max-width: 519px) {
  .anyjob-camera-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1;
    z-index: 9999;
  }
  
  .anyjob-camera-modal-contents {
    width: 320px;
    /* height: 100%; */
    background-color: #FFF;
    align-self: center;
    justify-self: center;
    border-radius: 12px;
    z-index: 99999;
  }
  
  .anyjob-camera-modal-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FF5851;
    height: 60px;
    padding: 0px 12px;
  }
  
  .anyjob-camera-modal-contents-header p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.48px;
  }
  
  .anyjob-camera-modal-close-btn {
    padding: 10px;
    cursor: pointer;
    
  }
  
  .anyjob-camera-modal-close-icon {
    width: 24px;
    height: 24px;
    color: black;
  }
  
  .anyjob-camera-view {
    width: 320px;
  }
  
  .anyjob-camera-image-capture-container {
    width: 320px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0px;
  }
  
  .anyjob-camera-capture-image-btn {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #e2e2e2;
    align-self: 'center';
  }
  
  .anyjob-camera-video-record-container {
    width: 320px;
    display: flex;
    margin: 20px 0px;
    flex-direction: row;
    justify-content: center;
    height: 90px;
  }
  
  .anyjob-camera-video-pause-btn {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #FF5851;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  
  .anyjob-camera-video-pause-btn div {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: #FFF;
  }
  
  .anyjob-camera-video-recording-action {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 55px;
    
  }
  
  .anyjob-camera-video-time {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
  }
  
  .anyjob-camera-video-pause-play-recording {
    width: 24px;
    cursor: pointer;
  }
}
