

@media screen and (min-width: 850px) {
  .anyjob-proposal-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-submit-proposal-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-submit-proposal-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-submit-proposal-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
  }

  .anyjob-submit-proposal-page-contents {
    padding: 40px;
  }

  .anyjob-submit-proposal-page-contents form {
    display: flex;
    flex-direction: column;
  }

  .anyjob-submit-proposal-form {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 40px 32px;
    margin-bottom: 50px;
  }

  .anyjob-submit-proposal-input-group-label {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
  }

  .anyjob-submit-proposal-input-group textarea {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    margin-top: 20px;
    min-height: 182px;
    width: 100%;
    outline: none;
    box-shadow: none;
    align-content: flex-start;
    padding: 20px;
    /* margin-bottom: 30px; */
  }

  .anyjob-submit-proposal-file-upload {
    border-radius: 12px;
    border: 2px dashed #FFC839;
    background: #FFFBEC;
    width: 100%;
    display: flex;
    height: 182px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-submit-proposal-file-upload-label {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }

  .anyjob-submit-proposal-file-upload-label-highlight {
    color: var(--Primary-Color, #F7CE38);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }

  .anyjob-submit-proposal-file-upload-note {
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-top: 10px;
  }

  .anyjob-submit-proposal-submit {
    display: flex;
    align-self: flex-end;
    align-items: center;
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    padding: 17px 141px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-bottom: 100px;
  }
  
}


@media screen and (min-width: 720px)  and (max-width: 849px)  {
  .anyjob-proposal-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-submit-proposal-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-submit-proposal-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-submit-proposal-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
  }

  .anyjob-submit-proposal-page-contents {
    padding: 40px;
  }

  .anyjob-submit-proposal-page-contents form {
    display: flex;
    flex-direction: column;
  }

  .anyjob-submit-proposal-form {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 40px 32px;
    margin-bottom: 50px;
  }

  .anyjob-submit-proposal-input-group-label {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
  }

  .anyjob-submit-proposal-input-group textarea {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    margin-top: 20px;
    min-height: 182px;
    width: 100%;
    outline: none;
    box-shadow: none;
    align-content: flex-start;
    padding: 20px;
    /* margin-bottom: 30px; */
  }

  .anyjob-submit-proposal-file-upload {
    border-radius: 12px;
    border: 2px dashed #FFC839;
    background: #FFFBEC;
    width: 100%;
    display: flex;
    height: 182px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-submit-proposal-file-upload-label {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }

  .anyjob-submit-proposal-file-upload-label-highlight {
    color: var(--Primary-Color, #F7CE38);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }

  .anyjob-submit-proposal-file-upload-note {
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-top: 10px;
  }

  .anyjob-submit-proposal-submit {
    display: flex;
    align-self: flex-end;
    align-items: center;
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    padding: 12px 80px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-bottom: 100px;
  }
  
}

@media screen and (max-width: 719px){
  .anyjob-proposal-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-submit-proposal-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-submit-proposal-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-submit-proposal-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
  }

  .anyjob-submit-proposal-page-contents {
    padding: 40px;
  }

  .anyjob-submit-proposal-page-contents form {
    display: flex;
    flex-direction: column;
  }

  .anyjob-submit-proposal-form {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 40px 32px;
    margin-bottom: 50px;
  }

  .anyjob-submit-proposal-input-group-label {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
  }

  .anyjob-submit-proposal-input-group textarea {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    margin-top: 20px;
    min-height: 182px;
    width: 100%;
    outline: none;
    box-shadow: none;
    align-content: flex-start;
    padding: 20px;
  }

  .anyjob-submit-proposal-file-upload {
    border-radius: 12px;
    border: 2px dashed #FFC839;
    background: #FFFBEC;
    width: 100%;
    display: flex;
    height: 182px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-submit-proposal-file-upload-label {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }

  .anyjob-submit-proposal-file-upload-label-highlight {
    color: var(--Primary-Color, #F7CE38);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }

  .anyjob-submit-proposal-file-upload-note {
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-top: 10px;
  }

  .anyjob-submit-proposal-submit {
    display: flex;
    align-self: flex-end;
    align-items: center;
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    padding: 12px 80px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-bottom: 100px;
  }
  
}
