.anyjob-message-container {
  flex: 1;
  border-radius: 10px;
  background-color: #fff;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
}

.anyjob-message-conversation-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.anyjob-message-conversation-container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px #F2F2F2 solid;
  padding: 20px;

}

.anyjob-message-conversation-container-header-avatar {
  width: 42px;
  height: 42px;
  border-radius: 42px;
  background-color: #FFFBEC;
}

.anyjob-message-conversation-recipient-short-details {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.anyjob-message-conversation-recipient-name {
  font-size: 18px;
  color: black;
  font-weight: 600;

}

.anyjob-message-conversation-recipient-available {
  font-size: 12px;
  color: #828282;
  font-weight: 500;
}

.anyjob-message-conversation-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.anyjob-sender-message-container {  
  padding: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0px;
  background-color: #FFC839;
}

.anyjob-sender-message-container-wrapper {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: auto;
  flex-shrink: 0;
}

.anyjob-receiver-message-container-wrapper {
  margin-left: 20px;
  margin-right: auto;
  margin-top: 20px;
  flex-shrink: 0;
}

.anyjob-receiver-message-container { 
  flex-shrink: 0;
  padding: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #FFFBEC;  
}

.anyjob-message-text {
  font-size: 14px;
  font-weight: 600;
  color: #1A1A1A;
}

.anyjob-message-timestamp {
  font-size: 12px;
  color: #828282;
  margin-top: 6px;
}

.anyjob-message-conversation-input-container {
  flex-direction: row;
  /* align-items: flex-end; */
  display: flex;
  padding: 15px 20px;
  border-top: 2px #F2F2F2 solid;

}

.anyjob-message-conversation-input-field {
  margin: 0px 20px;
  flex: 1;
  /* height: 42px; */
  border-radius: 30px;
  background-color: #FAFAFA;
  padding: 10px 20px;
  resize: none;
  transition: height 0.2s;
  overflow-y: hidden;
}

.anyjob-message-conversation-input-container-icons {
  cursor: pointer;
  width: 24px;
}