@media screen and (min-width: 1300px) {

.anyjob-onboarding-page-container {
  width: 100%;
  background: #FFF;
  height: 100vh;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.anyjob-onboarding-page-sticky-top {
  position: sticky;
  /* z-index: 999; */
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}

.anyjob-onboarding-page-header {
  display: flex;
  padding: 20px 100px;
}

.anyjob-onboarding-page-tab-header {
  border-top: 1px solid #E0E0E0;
  padding: 0px 84px;
  display: flex;
  flex-direction: row;
}

.anyjob-onboarding-page-tab-header-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 16px;
}

.anyjob-onboarding-page-tab-header-active-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 20px 16px;
}

.anyjob-onboarding-page-tab-header-item div {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  border: 1px solid #D4D4D4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #C0C0C0;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-item p {
  color: #C0C0C0;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-active-item div {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background-color: #FF5851;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-active-item p {
  color: #FF5851;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-progress-bar {
  width: 100%;
  background: #E0E0E0;
  height: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.anyjob-onboarding-page-tab-progress-indicator {
  background-color: #FF5851;
  height: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.anyjob-onboarding-page-tab-content {
  width: 100%;
  background-color: #fff;
}

.anyjob-onboarding-page-tab-content-headings {
  display: flex;
  flex-direction: column;
  margin: 50px 350px;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-headings-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-headings-desc {
  color: #828282;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin-top: 6px;
}

.anyjob-onboarding-page-tab-content-headings-2 {
  display: flex;
  flex-direction: column;
  margin: 50px 350px;
}


.anyjob-onboarding-page-upload-profile {
  display: flex;
  flex-direction: row;
  align-items: center;  
  margin: 00px 350px;
}

.anyjob-onboarding-page-profile-icon-container {
  border: 3px solid #e6e6e6;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  background-color: #fff;

  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-profile-icon {
  width: 64px;
  height: 64px;
  color: #e6e6e6;
}

.anyjob-onboarding-page-upload-profile-picture-btn {
  border-radius: 50px;
  background: var(--Secondary-Color, #FF5851);
  display: flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 32px;
}

.anyjob-onboarding-page-profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 148px;
  border: 4.44px solid var(--neutral-white, #FFF);
  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-personal-info-form {
  margin: 50px 350px;
  display: flex;
  flex-direction: column;
}

.anyjob-onboarding-page-input-group {
  margin-top: 30px;
}

.anyjob-onboarding-page-input-group-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.anyjob-onboarding-page-input-group-desc {
  color: var(--Black-1, #1A1A1A);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
}

.anyjob-onboarding-page-input-group-input-field {
  outline: none;
  box-shadow: none;

  border-radius: 30px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  height: 60px;
  margin-top: 10px;
  padding: 0px 15px;
  width: 100%;
}

.anyjob-onboarding-page-input-group-input-textarea {
  outline: none;
  box-shadow: none;

  border-radius: 25px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  min-height: 200px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
}

.anyjob-onboarding-page-form-submit-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 440px;
  height: 60px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-input-err-msg {
  color: #FF5851;
  font-size: 12px;
  margin-top: 10px;
}

.anyjob-onboarding-page-err-container {
  margin: 0px 350px;
}

.anyjob-onboarding-page-tab-content-experience-container {
  display: flex;
  margin: 0px 350px;
  margin-top: 50px;
}

.anyjob-onboarding-page-tab-content-experience-items-container {
  display: flex;
  /* position: relative; */
  overflow-x: scroll;
  overflow-y: hidden;
  /* z-index: 0; */
}

.anyjob-onboarding-page-tab-content-experiences {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  flex: 1;
  background-color: #F7CE38;
}

.anyjob-onboarding-page-tab-content-experience-item {
  border-radius: 16px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF; 
  min-width: 600px;
  height: 200px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 24px;
  margin-left: 20px;
}

.anyjob-onboarding-page-tab-content-experience-item-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings {
  display: flex;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings span {
  margin-left: 20px;
  margin-right: 20px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #767676;
}

.anyjob-onboarding-page-tab-content-experience-item-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-sub-title {
  color: var(--Grey1, #767676);  
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-actions {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
}

.anyjob-onboarding-page-tab-content-experience-item-desc {
  color: #BDBDBD;
  margin-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-btn {
  background-color: #FF5851;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.anyjob-onboarding-page-tab-content-experience-item-btn-icon {
  color: #FFF;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-tab-content-experience-item-close-btn {
  /* padding: 20px; */
}

.anyjob-onboarding-page-tab-content-experience-item-close-icon {
  color: #1A1A1A;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-skills-section {
  /* padding-bottom: 100px; */
}

.anyjob-onboarding-page-skills-auto-complete-container {
  border-radius: 32px;
  width: 700px;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 350px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-container-focused {
  border-radius: 32px;
  width: 700px;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  margin: 0px 350px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-label {
  color: var(--Black-1, #1A1A1A);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 350px;
  margin-top: 50px;
}

.anyjob-onboarding-page-skills-auto-complete-input-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-auto-complete-input-field:focus {  
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-suggestions-container {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  margin: 10px 350px;  
  z-index: 9;
  padding: 10px;
  width: 250px;
}

.anyjob-onboarding-page-skills-suggestion {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}

.anyjob-onboarding-page-selected-skills {
  border-radius: 24px;
  width: 700px;
  /* min-height: 64px; */
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 350px;  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 32px;
  align-items: center;
  margin-top: 30px;
}

.anyjob-onboarding-page-selected-skill {
  border-radius: 6px;
  opacity: 0.8;
  background: #FDF5D7;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.anyjob-onboarding-page-selected-skill p {
  color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  margin-left: 20px;
}

.anyjob-onboarding-page-skill-warning{
  color: #828282;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin: 6px 350px;  
}

.anyjob-onboarding-page-selected-skill-remove-btn {  
  cursor: pointer;
  padding: 0px 20px;
}

.anyjob-onboarding-page-selected-skill-remove-btn-icon {  
  width: 12px;
  height: 12px;
  color: #1A1A1A;
}

.anyjob-onboarding-page-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 100px 350px;  
}

.anyjob-onboarding-page-cont-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 440px;
  height: 60px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;  
}

.anyjob-onboarding-page-back-btn {  
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;  
  cursor: pointer;
}

.anyjob-onboarding-page-back-btn-icon {
  width: 18px;
  height: 18px;
  font-weight: 600;
  margin-right: 10px;
}

.anyjob-onboarding-professional-err-msg {
  margin: 10px 350px;  
  color: #FF5851;
  font-size: 12px;
}

.anyjob-onboarding-page-form-submit-btn-disabled {
  border-radius: 100px;
  background: var(--Primary-Color, #BDBDBD);
  display: flex;
  width: 440px;
  height: 60px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-acct-no-group {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-group-focused {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-acct-no-group-verify-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #FF5851);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.anyjob-onboarding-page-acct-no-group-verified-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #40D47F);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.anyjob-onboarding-page-account-name {
  width: 100%;
  height: 64px;
  background: #C6FFBE;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0px 32px;
  border-radius: 32px;
  font-size: 18px;
  font-weight: 600;
  color: #1A1A1A;
}

.anyjob-onboarding-section-divider {
  height: 1px;
  display: flex;
  background-color: #dbd9d9;  
  margin: 100px 350px;
  /* margin-top: 50px; */
}

.anyjob-onboarding-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 350px;
  background-color: #fff;
}

.anyjob-onboarding-message-image {
  width: 309px;
}

.anyjob-onboarding-message-title {
  color: var(--Black-1, #1A1A1A);  
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 45px;
}

.anyjob-onboarding-message-desc {
  color: #828282;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
}

.anyjob-onboarding-message-primary-btn {
  border-radius: 100px;
  background: #F7CE38;
  display: flex;
  width: 440px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-skip-btn {
  border-radius: 100px;
  background: #ededeb;
  display: flex;
  width: 440px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  /* margin: 30px 0px; */
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-buttons {
  display: flex;
  margin-top: 100px;
  gap: 30px;
}

.anyjob-onboarding-page-add-new-item-container {
  display: none;
}
}

@media screen and (min-width: 1024px) and (max-width: 1299px){

.anyjob-onboarding-page-container {
  width: 100%;
  background: #FFF;
  height: 100vh;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.anyjob-onboarding-page-sticky-top {
  position: sticky;
  /* z-index: 999; */
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}

.anyjob-onboarding-page-header {
  display: flex;
  padding: 20px 100px;
}

.anyjob-onboarding-page-tab-header {
  border-top: 1px solid #E0E0E0;
  padding: 0px 84px;
  display: flex;
  flex-direction: row;
}

.anyjob-onboarding-page-tab-header-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 16px;
}

.anyjob-onboarding-page-tab-header-active-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 20px 16px;
}

.anyjob-onboarding-page-tab-header-item div {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  border: 1px solid #D4D4D4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #C0C0C0;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-item p {
  color: #C0C0C0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-active-item div {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background-color: #FF5851;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-active-item p {
  color: #FF5851;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-progress-bar {
  width: 100%;
  background: #E0E0E0;
  height: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.anyjob-onboarding-page-tab-progress-indicator {
  background-color: #FF5851;
  height: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.anyjob-onboarding-page-tab-content {
  width: 100%;
  background-color: #fff;
}

.anyjob-onboarding-page-tab-content-headings {
  display: flex;
  flex-direction: column;
  margin: 50px 150px;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-headings-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-headings-desc {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin-top: 6px;
}

.anyjob-onboarding-page-tab-content-headings-2 {
  display: flex;
  flex-direction: column;
  margin: 50px 150px;
}


.anyjob-onboarding-page-upload-profile {
  display: flex;
  flex-direction: row;
  align-items: center;  
  margin: 00px 150px;
}

.anyjob-onboarding-page-profile-icon-container {
  border: 3px solid #e6e6e6;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  background-color: #fff;

  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-profile-icon {
  width: 64px;
  height: 64px;
  color: #e6e6e6;
}

.anyjob-onboarding-page-upload-profile-picture-btn {
  border-radius: 50px;
  background: var(--Secondary-Color, #FF5851);
  display: flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 32px;
}

.anyjob-onboarding-page-profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 148px;
  border: 4.44px solid var(--neutral-white, #FFF);
  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-personal-info-form {
  margin: 50px 150px;
  display: flex;
  flex-direction: column;
}

.anyjob-onboarding-page-input-group {
  margin-top: 30px;
}

.anyjob-onboarding-page-input-group-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.anyjob-onboarding-page-input-group-desc {
  color: var(--Black-1, #1A1A1A);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
}

.anyjob-onboarding-page-input-group-input-field {
  outline: none;
  box-shadow: none;

  border-radius: 30px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  height: 60px;
  margin-top: 10px;
  padding: 0px 15px;
  width: 100%;
}

.anyjob-onboarding-page-input-group-input-textarea {
  outline: none;
  box-shadow: none;

  border-radius: 25px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  min-height: 200px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
  font-size: 14px;
}

.anyjob-onboarding-page-form-submit-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-input-err-msg {
  color: #FF5851;
  font-size: 12px;
  margin-top: 10px;
}

.anyjob-onboarding-page-err-container {
  margin: 0px 350px;
}

.anyjob-onboarding-page-tab-content-experience-container {
  display: flex;
  margin: 0px 150px;
  margin-top: 50px;
}

.anyjob-onboarding-page-tab-content-experience-items-container {
  display: flex;
  /* position: relative; */
  overflow-x: scroll;
  overflow-y: hidden;
  /* z-index: 0; */
}

.anyjob-onboarding-page-tab-content-experiences {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  flex: 1;
  background-color: #F7CE38;
}

.anyjob-onboarding-page-tab-content-experience-item {
  border-radius: 16px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF; 
  min-width: 600px;
  height: 200px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 24px;
  margin-left: 20px;
}

.anyjob-onboarding-page-tab-content-experience-item-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings {
  display: flex;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings span {
  margin-left: 20px;
  margin-right: 20px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #767676;
}

.anyjob-onboarding-page-tab-content-experience-item-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-sub-title {
  color: var(--Grey1, #767676);  
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-actions {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
}

.anyjob-onboarding-page-tab-content-experience-item-desc {
  color: #BDBDBD;
  margin-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-btn {
  background-color: #FF5851;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.anyjob-onboarding-page-tab-content-experience-item-btn-icon {
  color: #FFF;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-tab-content-experience-item-close-btn {
  /* padding: 20px; */
}

.anyjob-onboarding-page-tab-content-experience-item-close-icon {
  color: #1A1A1A;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-skills-section {
  /* padding-bottom: 100px; */
}

.anyjob-onboarding-page-skills-auto-complete-container {
  border-radius: 32px;
  width: 700px;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 150px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-container-focused {
  border-radius: 32px;
  width: 700px;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  margin: 0px 150px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-label {
  color: var(--Black-1, #1A1A1A);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 150px;
  margin-top: 50px;
}

.anyjob-onboarding-page-skills-auto-complete-input-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-auto-complete-input-field:focus {  
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-suggestions-container {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  margin: 10px 150px;  
  z-index: 9;
  padding: 10px;
  width: 250px;
}

.anyjob-onboarding-page-skills-suggestion {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}

.anyjob-onboarding-page-selected-skills {
  border-radius: 24px;
  width: 700px;
  /* min-height: 64px; */
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 150px;  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 32px;
  align-items: center;
  margin-top: 30px;
}

.anyjob-onboarding-page-selected-skill {
  border-radius: 6px;
  opacity: 0.8;
  background: #FDF5D7;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.anyjob-onboarding-page-selected-skill p {
  color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  margin-left: 20px;
}

.anyjob-onboarding-page-skill-warning{
  color: #828282;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin: 6px 150px;  
}

.anyjob-onboarding-page-selected-skill-remove-btn {  
  cursor: pointer;
  padding: 0px 20px;
}

.anyjob-onboarding-page-selected-skill-remove-btn-icon {  
  width: 12px;
  height: 12px;
  color: #1A1A1A;
}

.anyjob-onboarding-page-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 100px 150px;  
}

.anyjob-onboarding-page-cont-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;  
}

.anyjob-onboarding-page-back-btn {  
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;  
  cursor: pointer;
}

.anyjob-onboarding-page-back-btn-icon {
  width: 18px;
  height: 18px;
  font-weight: 600;
  margin-right: 10px;
}

.anyjob-onboarding-professional-err-msg {
  margin: 10px 350px;  
  color: #FF5851;
  font-size: 12px;
}

.anyjob-onboarding-page-form-submit-btn-disabled {
  border-radius: 100px;
  background: var(--Primary-Color, #BDBDBD);
  display: flex;
  width: 440px;
  height: 60px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-acct-no-group {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-group-focused {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-acct-no-group-verify-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #FF5851);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.anyjob-onboarding-page-acct-no-group-verified-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #40D47F);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.anyjob-onboarding-page-account-name {
  width: 100%;
  height: 64px;
  background: #C6FFBE;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0px 32px;
  border-radius: 32px;
  font-size: 18px;
  font-weight: 600;
  color: #1A1A1A;
}

.anyjob-onboarding-section-divider {
  height: 1px;
  display: flex;
  background-color: #dbd9d9;  
  margin: 100px 150px;
  /* margin-top: 50px; */
}

.anyjob-onboarding-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 200px;
  background-color: #fff;
}

.anyjob-onboarding-message-image {
  width: 309px;
}

.anyjob-onboarding-message-title {
  color: var(--Black-1, #1A1A1A);  
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 45px;
  text-align: center;
}

.anyjob-onboarding-message-desc {
  color: #828282;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
}

.anyjob-onboarding-message-primary-btn {
  border-radius: 100px;
  background: #F7CE38;
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-skip-btn {
  border-radius: 100px;
  background: #ededeb;
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  /* margin: 30px 0px; */
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-buttons {
  display: flex;
  margin-top: 100px;
  gap: 30px;
}

.anyjob-onboarding-page-add-new-item-container {
  display: none;
}
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

.anyjob-onboarding-page-container {
  width: 100%;
  background: #FFF;
  height: 100vh;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.anyjob-onboarding-page-sticky-top {
  position: sticky;
  /* z-index: 999; */
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}

.anyjob-onboarding-page-header {
  display: flex;
  padding: 20px 100px;
}

.anyjob-onboarding-page-tab-header {
  border-top: 1px solid #E0E0E0;
  padding: 0px 50px;
  display: flex;
  flex-direction: row;
}

.anyjob-onboarding-page-tab-header-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 16px;
}

.anyjob-onboarding-page-tab-header-active-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 20px 16px;
}

.anyjob-onboarding-page-tab-header-item div {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  border: 1px solid #D4D4D4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #C0C0C0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-item p {
  color: #C0C0C0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-active-item div {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: #FF5851;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-active-item p {
  color: #FF5851;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-progress-bar {
  width: 100%;
  background: #E0E0E0;
  height: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.anyjob-onboarding-page-tab-progress-indicator {
  background-color: #FF5851;
  height: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.anyjob-onboarding-page-tab-content {
  width: 100%;
  background-color: #fff;
}

.anyjob-onboarding-page-tab-content-headings {
  display: flex;
  flex-direction: column;
  margin: 50px 150px;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-headings-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.anyjob-onboarding-page-tab-content-headings-desc {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin-top: 6px;
  text-align: center;
}

.anyjob-onboarding-page-tab-content-headings-2 {
  display: flex;
  flex-direction: column;
  margin: 50px 150px;
}


.anyjob-onboarding-page-upload-profile {
  display: flex;
  flex-direction: row;
  align-items: center;  
  margin: 00px 150px;
}

.anyjob-onboarding-page-profile-icon-container {
  border: 3px solid #e6e6e6;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  background-color: #fff;

  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-profile-icon {
  width: 64px;
  height: 64px;
  color: #e6e6e6;
}

.anyjob-onboarding-page-upload-profile-picture-btn {
  border-radius: 50px;
  background: var(--Secondary-Color, #FF5851);
  display: flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 32px;
}

.anyjob-onboarding-page-profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 148px;
  border: 4.44px solid var(--neutral-white, #FFF);
  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-personal-info-form {
  margin: 50px 150px;
  display: flex;
  flex-direction: column;
}

.anyjob-onboarding-page-input-group {
  margin-top: 30px;
}

.anyjob-onboarding-page-input-group-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.anyjob-onboarding-page-input-group-desc {
  color: var(--Black-1, #1A1A1A);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

.anyjob-onboarding-page-input-group-input-field {
  outline: none;
  box-shadow: none;

  border-radius: 30px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  height: 60px;
  margin-top: 10px;
  padding: 0px 15px;
  width: 100%;
}

.anyjob-onboarding-page-input-group-input-textarea {
  outline: none;
  box-shadow: none;

  border-radius: 25px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  min-height: 200px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
}

.anyjob-onboarding-page-form-submit-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-input-err-msg {
  color: #FF5851;
  font-size: 12px;
  margin-top: 10px;
}

.anyjob-onboarding-page-err-container {
  margin: 0px 350px;
}

.anyjob-onboarding-page-tab-content-experience-container {
  display: flex;
  margin: 0px 150px;
  margin-top: 50px;
}

.anyjob-onboarding-page-tab-content-experience-items-container {
  display: flex;
  /* position: relative; */
  overflow-x: scroll;
  overflow-y: hidden;
  /* z-index: 0; */
}

.anyjob-onboarding-page-tab-content-experiences {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  flex: 1;
  background-color: #F7CE38;
}

.anyjob-onboarding-page-tab-content-experience-item {
  border-radius: 16px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF; 
  min-width: 600px;
  height: 200px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 24px;
  margin-left: 20px;
}

.anyjob-onboarding-page-tab-content-experience-item-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings {
  display: flex;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings span {
  margin-left: 20px;
  margin-right: 20px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #767676;
}

.anyjob-onboarding-page-tab-content-experience-item-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-sub-title {
  color: var(--Grey1, #767676);  
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-actions {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
}

.anyjob-onboarding-page-tab-content-experience-item-desc {
  color: #BDBDBD;
  margin-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-btn {
  background-color: #FF5851;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.anyjob-onboarding-page-tab-content-experience-item-btn-icon {
  color: #FFF;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-tab-content-experience-item-close-btn {
  /* padding: 20px; */
}

.anyjob-onboarding-page-tab-content-experience-item-close-icon {
  color: #1A1A1A;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-skills-section {
  /* padding-bottom: 100px; */
}

.anyjob-onboarding-page-skills-auto-complete-container {
  border-radius: 32px;
  width: 700px;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 150px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-container-focused {
  border-radius: 32px;
  width: 700px;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  margin: 0px 350px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-label {
  color: var(--Black-1, #1A1A1A);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 150px;
  margin-top: 50px;
}

.anyjob-onboarding-page-skills-auto-complete-input-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-auto-complete-input-field:focus {  
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-suggestions-container {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  margin: 10px 150px;  
  z-index: 9;
  padding: 10px;
  width: 250px;
}

.anyjob-onboarding-page-skills-suggestion {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}

.anyjob-onboarding-page-selected-skills {
  border-radius: 24px;
  width: 700px;
  /* min-height: 64px; */
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 150px;  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 32px;
  align-items: center;
  margin-top: 30px;
}

.anyjob-onboarding-page-selected-skill {
  border-radius: 6px;
  opacity: 0.8;
  background: #FDF5D7;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.anyjob-onboarding-page-selected-skill p {
  color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  margin-left: 20px;
}

.anyjob-onboarding-page-skill-warning{
  color: #828282;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin: 6px 150px;  
}

.anyjob-onboarding-page-selected-skill-remove-btn {  
  cursor: pointer;
  padding: 0px 20px;
}

.anyjob-onboarding-page-selected-skill-remove-btn-icon {  
  width: 12px;
  height: 12px;
  color: #1A1A1A;
}

.anyjob-onboarding-page-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 100px 150px;  
}

.anyjob-onboarding-page-cont-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;  
}

.anyjob-onboarding-page-back-btn {  
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;  
  cursor: pointer;
}

.anyjob-onboarding-page-back-btn-icon {
  width: 18px;
  height: 18px;
  font-weight: 600;
  margin-right: 10px;
}

.anyjob-onboarding-professional-err-msg {
  margin: 10px 350px;  
  color: #FF5851;
  font-size: 12px;
}

.anyjob-onboarding-page-form-submit-btn-disabled {
  border-radius: 100px;
  background: var(--Primary-Color, #BDBDBD);
  display: flex;
  width: 440px;
  height: 60px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-acct-no-group {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-group-focused {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-acct-no-group-verify-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #FF5851);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.anyjob-onboarding-page-acct-no-group-verified-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #40D47F);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.anyjob-onboarding-page-account-name {
  width: 100%;
  height: 64px;
  background: #C6FFBE;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0px 32px;
  border-radius: 32px;
  font-size: 18px;
  font-weight: 600;
  color: #1A1A1A;
}

.anyjob-onboarding-section-divider {
  height: 1px;
  display: flex;
  background-color: #dbd9d9;  
  margin: 100px 350px;
  /* margin-top: 50px; */
}

.anyjob-onboarding-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 100px;
  background-color: #fff;
}

.anyjob-onboarding-message-image {
  width: 309px;
}

.anyjob-onboarding-message-title {
  color: var(--Black-1, #1A1A1A);  
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 45px;
  text-align: center;
}

.anyjob-onboarding-message-desc {
  color: #828282;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}

.anyjob-onboarding-message-primary-btn {
  border-radius: 100px;
  background: #F7CE38;
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-skip-btn {
  border-radius: 100px;
  background: #ededeb;
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  /* margin: 30px 0px; */
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-buttons {
  display: flex;
  margin-top: 100px;
  gap: 30px;
}

.anyjob-onboarding-page-add-new-item-container {
  display: none;
}
}

@media screen and (min-width: 481px) and (max-width: 768px) {

.anyjob-onboarding-page-container {
  width: 100%;
  background: #FFF;
  height: 100vh;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.anyjob-onboarding-page-sticky-top {
  position: sticky;
  /* z-index: 999; */
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}

.anyjob-onboarding-page-header {
  display: flex;
  padding: 20px 50px;
}

.anyjob-onboarding-page-tab-header {
  border-top: 1px solid #E0E0E0;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
}

.anyjob-onboarding-page-tab-header-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  min-width: 120px;
}

.anyjob-onboarding-page-tab-header-active-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  min-width: 120px;
}

.anyjob-onboarding-page-tab-header-item div {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  border: 1px solid #D4D4D4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #C0C0C0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-item p {
  color: #C0C0C0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-top: 6px;
}

.anyjob-onboarding-page-tab-header-active-item div {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: #FF5851;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-active-item p {
  color: #FF5851;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-top: 6px;
}

.anyjob-onboarding-page-tab-progress-bar {
  width: 100%;
  background: #E0E0E0;
  height: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.anyjob-onboarding-page-tab-progress-indicator {
  background-color: #FF5851;
  height: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.anyjob-onboarding-page-tab-content {
  width: 100%;
  background-color: #fff;
}

.anyjob-onboarding-page-tab-content-headings {
  display: flex;
  flex-direction: column;
  margin: 50px;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-headings-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-headings-desc {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin-top: 16px;
  text-align: center;
}

.anyjob-onboarding-page-tab-content-headings-2 {
  display: flex;
  flex-direction: column;
  margin: 50px;
}


.anyjob-onboarding-page-upload-profile {
  display: flex;
  flex-direction: row;
  align-items: center;  
  margin: 50px;
}

.anyjob-onboarding-page-profile-icon-container {
  border: 3px solid #e6e6e6;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  background-color: #fff;

  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-profile-icon {
  width: 36px;
  height: 36px;
  color: #e6e6e6;
}

.anyjob-onboarding-page-upload-profile-picture-btn {
  border-radius: 50px;
  background: var(--Secondary-Color, #FF5851);
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-left: 24px;
}

.anyjob-onboarding-page-profile-picture {
  width: 90px;
  height: 90px;
  border-radius: 98px;
  border: 4.44px solid var(--neutral-white, #FFF);
  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-personal-info-form {
  margin: 50px;
  display: flex;
  flex-direction: column;
}

.anyjob-onboarding-page-input-group {
  margin-top: 24px;
}

.anyjob-onboarding-page-input-group-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.anyjob-onboarding-page-input-group-desc {
  color: var(--Black-1, #1A1A1A);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

.anyjob-onboarding-page-input-group-input-field {
  outline: none;
  box-shadow: none;

  border-radius: 30px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  height: 60px;
  margin-top: 10px;
  padding: 0px 15px;
  width: 100%;
  font-size: 14px;
}

.anyjob-onboarding-page-input-group-input-textarea {
  outline: none;
  box-shadow: none;

  border-radius: 25px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  min-height: 200px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
  font-size: 14px;
}

.anyjob-onboarding-page-form-submit-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 350px;
  height: 50px;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-input-err-msg {
  color: #FF5851;
  font-size: 12px;
  margin-top: 10px;
}

.anyjob-onboarding-page-err-container {
  margin: 0px 350px;
}

.anyjob-onboarding-page-tab-content-experience-container {
  display: flex;
  margin: 0px 50px;
  margin-top: 50px;
}

.anyjob-onboarding-page-tab-content-experience-items-container {
  display: flex;
  /* position: relative; */
  overflow-x: scroll;
  overflow-y: hidden;
  /* z-index: 0; */
}

.anyjob-onboarding-page-tab-content-experiences {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  flex: 1;
  background-color: #F7CE38;
}

.anyjob-onboarding-page-tab-content-experience-item {
  border-radius: 16px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF; 
  min-width: 350px;
  height: 200px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 24px;
  margin-right: 20px;
}

.anyjob-onboarding-page-tab-content-experience-item-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings {
  display: flex;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings span {
  margin-left: 20px;
  margin-right: 20px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #767676;
}

.anyjob-onboarding-page-tab-content-experience-item-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-sub-title {
  color: var(--Grey1, #767676);  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-actions {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
}

.anyjob-onboarding-page-tab-content-experience-item-desc {
  color: #BDBDBD;
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-btn {
  background-color: #FF5851;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.anyjob-onboarding-page-tab-content-experience-item-btn-icon {
  color: #FFF;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-tab-content-experience-item-close-btn {
  /* padding: 20px; */
}

.anyjob-onboarding-page-tab-content-experience-item-close-icon {
  color: #1A1A1A;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-skills-section {
  /* padding-bottom: 100px; */
}

.anyjob-onboarding-page-skills-auto-complete-container {
  border-radius: 32px;
  width: 400px;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 50px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-container-focused {
  border-radius: 32px;
  width: 400px;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  margin: 0px 50px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-label {
  color: var(--Black-1, #1A1A1A);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 50px;
  margin-top: 50px;
}

.anyjob-onboarding-page-skills-auto-complete-input-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-auto-complete-input-field:focus {  
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-suggestions-container {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  margin: 10px 50px;  
  z-index: 9;
  padding: 10px;
  width: 250px;
}

.anyjob-onboarding-page-skills-suggestion {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}

.anyjob-onboarding-page-selected-skills {
  border-radius: 24px;
  width: 400px;
  /* min-height: 64px; */
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 50px;  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 32px;
  align-items: center;
  margin-top: 30px;
}

.anyjob-onboarding-page-selected-skill {
  border-radius: 6px;
  opacity: 0.8;
  background: #FDF5D7;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.anyjob-onboarding-page-selected-skill p {
  color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  margin-left: 20px;
}

.anyjob-onboarding-page-skill-warning{
  color: #828282;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin: 6px 50px;  
}

.anyjob-onboarding-page-selected-skill-remove-btn {  
  cursor: pointer;
  padding: 0px 20px;
}

.anyjob-onboarding-page-selected-skill-remove-btn-icon {  
  width: 12px;
  height: 12px;
  color: #1A1A1A;
}

.anyjob-onboarding-page-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 50px;  
}

.anyjob-onboarding-page-cont-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 250px;
  height: 50px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;  
}

.anyjob-onboarding-page-back-btn {  
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;  
  cursor: pointer;
}

.anyjob-onboarding-page-back-btn-icon {
  width: 18px;
  height: 18px;
  font-weight: 600;
  margin-right: 10px;
}

.anyjob-onboarding-professional-err-msg {
  margin: 10px 350px;  
  color: #FF5851;
  font-size: 12px;
}

.anyjob-onboarding-page-form-submit-btn-disabled {
  border-radius: 100px;
  background: var(--Primary-Color, #BDBDBD);
  display: flex;
  width: 350px;
  height: 50px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-acct-no-group {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-group-focused {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-acct-no-group-verify-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #FF5851);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.anyjob-onboarding-page-acct-no-group-verified-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #40D47F);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.anyjob-onboarding-page-account-name {
  width: 100%;
  height: 64px;
  background: #C6FFBE;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0px 32px;
  border-radius: 32px;
  font-size: 18px;
  font-weight: 600;
  color: #1A1A1A;
}

.anyjob-onboarding-section-divider {
  height: 1px;
  display: flex;
  background-color: #dbd9d9;  
  margin: 50px 50px;
  /* margin-top: 50px; */
}

.anyjob-onboarding-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 20px;
  background-color: #fff;
}

.anyjob-onboarding-message-image {
  width: 309px;
}

.anyjob-onboarding-message-title {
  color: var(--Black-1, #1A1A1A);  
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 45px;
  text-align: center;
}

.anyjob-onboarding-message-desc {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}

.anyjob-onboarding-message-primary-btn {
  border-radius: 100px;
  background: #F7CE38;
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-skip-btn {
  border-radius: 100px;
  background: #ededeb;
  display: flex;
  width: 350px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  /* margin: 30px 0px; */
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-buttons {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 100px;
  gap: 30px;
}

.anyjob-onboarding-page-add-new-item-container {
  display: flex;
  align-items: center;
  margin: 30px 50px;
  cursor: pointer;
}
.anyjob-onboarding-page-add-new-item-icon {
  width: 12px;
  height: 12px;
  color: #FF5851;
}
.anyjob-onboarding-page-add-new-item-text {
  color: var(--Secondary-Color, #FF5851);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  margin-left: 10px;
}
}

@media screen and (max-width: 480px) {

.anyjob-onboarding-page-container {
  width: 100%;
  background: #FFF;
  height: 100vh;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.anyjob-onboarding-page-sticky-top {
  position: sticky;
  /* z-index: 999; */
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}

.anyjob-onboarding-page-header {
  display: flex;
  padding: 20px 100px;
}

.anyjob-onboarding-page-tab-header {
  border-top: 1px solid #E0E0E0;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
}

.anyjob-onboarding-page-tab-header-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  min-width: 120px;
}

.anyjob-onboarding-page-tab-header-active-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  min-width: 120px;
}

.anyjob-onboarding-page-tab-header-item div {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  border: 1px solid #D4D4D4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #C0C0C0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-item p {
  color: #C0C0C0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-top: 6px;
}

.anyjob-onboarding-page-tab-header-active-item div {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: #FF5851;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-header-active-item p {
  color: #FF5851;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-top: 6px;
}


.anyjob-onboarding-page-tab-progress-bar {
  width: 100%;
  background: #E0E0E0;
  height: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.anyjob-onboarding-page-tab-progress-indicator {
  background-color: #FF5851;
  height: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.anyjob-onboarding-page-tab-content {
  width: 100%;
  background-color: #fff;
}

.anyjob-onboarding-page-tab-content-headings {
  display: flex;
  flex-direction: column;
  margin: 50px 20px;
  /* align-items: center; */
}

.anyjob-onboarding-page-tab-content-headings-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-headings-desc {
  color: #828282;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin-top: 10px;
}

.anyjob-onboarding-page-tab-content-headings-2 {
  display: flex;
  flex-direction: column;
  margin: 50px 20px;
}


.anyjob-onboarding-page-upload-profile {
  display: flex;
  flex-direction: row;
  align-items: center;  
  margin: 50px 20px;
}

.anyjob-onboarding-page-profile-icon-container {
  border: 3px solid #e6e6e6;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  background-color: #fff;

  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-profile-icon {
  width: 36px;
  height: 36px;
  color: #e6e6e6;
}

.anyjob-onboarding-page-upload-profile-picture-btn {
  border-radius: 50px;
  background: var(--Secondary-Color, #FF5851);
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 24px;
}

.anyjob-onboarding-page-profile-picture {
  width: 90px;
  height: 90px;
  border-radius: 148px;
  border: 4.44px solid var(--neutral-white, #FFF);
  box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
}

.anyjob-onboarding-page-personal-info-form {
  margin: 50px 20px;
  display: flex;
  flex-direction: column;
}

.anyjob-onboarding-page-input-group {
  margin-top: 30px;
}

.anyjob-onboarding-page-input-group-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.anyjob-onboarding-page-input-group-desc {
  color: var(--Black-1, #1A1A1A);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

.anyjob-onboarding-page-input-group-input-field {
  outline: none;
  box-shadow: none;

  border-radius: 30px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  height: 60px;
  margin-top: 10px;
  padding: 0px 15px;
  width: 100%;
  font-size: 14px;
}

.anyjob-onboarding-page-input-group-input-textarea {
  outline: none;
  box-shadow: none;

  border-radius: 25px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  min-height: 200px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
  font-size: 14px;
}

.anyjob-onboarding-page-form-submit-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 350px;
  height: 50px;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-input-err-msg {
  color: #FF5851;
  font-size: 12px;
  margin-top: 10px;
}

.anyjob-onboarding-page-err-container {
  margin: 0px 350px;
}

.anyjob-onboarding-page-tab-content-experience-container {
  display: flex;
  margin: 0px 20px;
  margin-top: 50px;
}

.anyjob-onboarding-page-tab-content-experience-items-container {
  display: flex;
  /* position: relative; */
  overflow-x: scroll;
  overflow-y: hidden;
  /* z-index: 0; */
}

.anyjob-onboarding-page-tab-content-experiences {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  flex: 1;
  background-color: #F7CE38;
}

.anyjob-onboarding-page-tab-content-experience-item {
  border-radius: 16px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF; 
  min-width: 350px;
  height: 200px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 24px;
  margin-right: 20px;
}

.anyjob-onboarding-page-tab-content-experience-item-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings {
  display: flex;
  align-items: center;
}

.anyjob-onboarding-page-tab-content-experience-item-headings span {  
  margin-left: 15px;
  margin-right: 15px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #767676;
}

.anyjob-onboarding-page-tab-content-experience-item-title {
  color: var(--Black-1, #1A1A1A);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-sub-title {
  color: var(--Grey1, #767676);  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-actions {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
}

.anyjob-onboarding-page-tab-content-experience-item-desc {
  color: #BDBDBD;
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-onboarding-page-tab-content-experience-item-btn {
  background-color: #FF5851;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.anyjob-onboarding-page-tab-content-experience-item-btn-icon {
  color: #FFF;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-tab-content-experience-item-close-btn {
  /* padding: 20px; */
}

.anyjob-onboarding-page-tab-content-experience-item-close-icon {
  color: #1A1A1A;
  width: 24px;
  height: 24px;
}

.anyjob-onboarding-page-skills-section {
  /* padding-bottom: 100px; */
}

.anyjob-onboarding-page-skills-auto-complete-container {
  border-radius: 32px;
  width: 350px;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 20px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-container-focused {
  border-radius: 32px;
  width: 350px;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  margin: 0px 20px;  
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-skills-auto-complete-label {
  color: var(--Black-1, #1A1A1A);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 20px;
  margin-top: 50px;
}

.anyjob-onboarding-page-skills-auto-complete-input-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-auto-complete-input-field:focus {  
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-skills-suggestions-container {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  margin: 10px 20px;  
  z-index: 9;
  padding: 10px;
  width: 250px;
}

.anyjob-onboarding-page-skills-suggestion {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}

.anyjob-onboarding-page-selected-skills {
  border-radius: 24px;
  width: 350px;
  /* min-height: 64px; */
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  margin: 0px 20px;  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 32px;
  align-items: center;
  margin-top: 30px;
}

.anyjob-onboarding-page-selected-skill {
  border-radius: 6px;
  opacity: 0.8;
  background: #FDF5D7;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.anyjob-onboarding-page-selected-skill p {
  color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  margin-left: 20px;
}

.anyjob-onboarding-page-skill-warning{
  color: #828282;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin: 6px 20px;  
}

.anyjob-onboarding-page-selected-skill-remove-btn {  
  cursor: pointer;
  padding: 0px 20px;
}

.anyjob-onboarding-page-selected-skill-remove-btn-icon {  
  width: 12px;
  height: 12px;
  color: #1A1A1A;
}

.anyjob-onboarding-page-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 20px;  
}

.anyjob-onboarding-page-cont-btn {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  display: flex;
  width: 250px;
  height: 50px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;  
}

.anyjob-onboarding-page-back-btn {  
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;  
  cursor: pointer;
}

.anyjob-onboarding-page-back-btn-icon {
  width: 18px;
  height: 18px;
  font-weight: 600;
  margin-right: 10px;
}

.anyjob-onboarding-professional-err-msg {
  margin: 10px 350px;  
  color: #FF5851;
  font-size: 12px;
}

.anyjob-onboarding-page-form-submit-btn-disabled {
  border-radius: 100px;
  background: var(--Primary-Color, #BDBDBD);
  display: flex;
  width: 440px;
  height: 60px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-page-acct-no-group {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-group-focused {
  border-radius: 32px;
  width: 100%;
  height: 64px;
  border: 1px solid #FF5851;
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-onboarding-page-acct-no-field {
  flex: 1;
  height: 60px;
  padding: 15px 32px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-onboarding-page-acct-no-group-verify-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #FF5851);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.anyjob-onboarding-page-acct-no-group-verified-btn {
  display: flex;
  width: 286px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #40D47F);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.anyjob-onboarding-page-account-name {
  width: 100%;
  height: 64px;
  background: #C6FFBE;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0px 32px;
  border-radius: 32px;
  font-size: 18px;
  font-weight: 600;
  color: #1A1A1A;
}

.anyjob-onboarding-section-divider {
  height: 1px;
  display: flex;
  background-color: #dbd9d9;  
  margin: 50px 50px;
  /* margin-top: 50px; */
}

.anyjob-onboarding-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 20px;
  background-color: #fff;
}

.anyjob-onboarding-message-image {
  width: 309px;
}

.anyjob-onboarding-message-title {
  color: var(--Black-1, #1A1A1A);  
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 45px;
  text-align: center;
}

.anyjob-onboarding-message-desc {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}

.anyjob-onboarding-message-primary-btn {
  border-radius: 100px;
  background: #F7CE38;
  display: flex;
  width: 320px;
  height: 50px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-skip-btn {
  border-radius: 100px;
  background: #ededeb;
  display: flex;
  width: 320px;
  height: 50px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  /* margin: 30px 0px; */
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-onboarding-message-buttons {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 100px;
  gap: 30px;
}

.anyjob-onboarding-page-add-new-item-container {
  display: flex;
  align-items: center;
  margin: 20px;
  cursor: pointer;
}
.anyjob-onboarding-page-add-new-item-icon {
  width: 12px;
  height: 12px;
  color: #FF5851;
}
.anyjob-onboarding-page-add-new-item-text {
  color: var(--Secondary-Color, #FF5851);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  margin-left: 10px;
}
}

.anyjob-onboarding-page-logo {
  height: 35px;
}
