* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

.anyjob-site-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.anyjob-required-auth-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
  background-color: #FAFAFA;
}
