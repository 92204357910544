@media screen and (min-width: 1320px) {
  .anyjob-dashboard-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-dashboard-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-dashboard-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-dashboard-page-hero-headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
  
  .anyjob-proposal-hero-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 42px */
    letter-spacing: 0.56px;
  }

  .anyjob-proposal-hero-desc {
    color: var(--Grey1, #767676);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-dashboard-page-applied-ongoing-section {
    flex: 1;
    display: flex;
    gap: 50px;
  }

  .anyjob-dashboard-page-stats-section {
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding: 32px 40px;
  }

  .anyjob-dashboard-page-stats-count {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    flex: 1;
  }

  .anyjob-dashboard-page-stats-card {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    flex: 1;
    padding: 24px;
  }

  .anyjob-dashboard-page-stats-count-value-n-img {
    display: flex;
    flex-direction: column;
  }

  .anyjob-dashboard-page-stats-count-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
    margin: 24px;
  }


  .anyjob-dashboard-page-stats-count-value {
    color: var(--Black-1, #1A1A1A);
    text-align: left;
    font-size: 120px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 72px */
    margin-left: 32px;
  }

  .anyjob-dashboard-page-stats-count-img {
    width: 120px;
    align-self: flex-end;
  }

  .anyjob-dashboard-page-stats-doughnut-container {
    /* width: 152px; */
  }

  .anyjob-dashboard-page-stats-card-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; 
  }

  .anyjob-dashboard-page-stats-card-stats-section {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .anyjob-dashboard-page-stats-card-stats-legends {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }

  .anyjob-dashboard-page-stats-legends-item {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .anyjob-dashboard-page-stats-legends-indicator {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }

  .anyjob-dashboard-page-stats-legends-value {
    color: var(--Black-1, #1A1A1A);
    
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 28.8px */
  }

  .anyjob-dashboard-page-stats-legends-label {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-dashboard-page-stats-card-cta {
    display: flex;
    align-items: center;
    color: var(--Secondary-Color, #FF5851);
    gap: 10px;
  }

  .anyjob-dashboard-page-stats-card-cta p {
    color: var(--Secondary-Color, #FF5851);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-dashboard-page-stats-card-cta-icon {
    width: 24px;
    height: 24px;
    color: var(--Secondary-Color, #FF5851);
  }

  .anyjob-dashboard-page-stats-empty-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .anyjob-dashboard-page-stats-empty-card p {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    text-align: center;
  }

  .anyjob-dashboard-page-stats-empty-card img {
    width: 150px;
    height: 150px;
  }

  .anyjob-dashboard-page-recent-applications-container {
    margin: 32px 40px;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
  }

  .anyjob-dashboard-page-recent-applications-header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D6DDEB;
  }

  .anyjob-dashboard-page-recent-applications-header-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
  }

  .anyjob-dashboard-applications-table {
    padding: 10px 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .anyjob-dashboard-table-container {
    padding: 30px 15px;
  }
  
}

@media screen and (min-width: 1040px)  and (max-width: 1329px)  {
  .anyjob-dashboard-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-dashboard-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-dashboard-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-dashboard-page-hero-headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
  
  .anyjob-proposal-hero-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 42px */
    letter-spacing: 0.56px;
  }

  .anyjob-proposal-hero-desc {
    color: var(--Grey1, #767676);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-dashboard-page-applied-ongoing-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .anyjob-dashboard-page-stats-section {
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding: 32px 40px;
  }

  .anyjob-dashboard-page-stats-count {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    flex: 1;
  }

  .anyjob-dashboard-page-stats-card {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    flex: 1;
    padding: 24px;
  }

  .anyjob-dashboard-page-stats-count-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
    margin: 24px;
  }


  .anyjob-dashboard-page-stats-count-value {
    color: var(--Black-1, #1A1A1A);
    text-align: left;
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 72px */
    margin-left: 32px;
  }

  .anyjob-dashboard-page-stats-count-img {
    width: 80px;
    height: 80px;
    align-self: flex-end;
  }

  .anyjob-dashboard-page-stats-doughnut-container {
    /* width: 152px; */
  }

  .anyjob-dashboard-page-stats-card-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; 
  }

  .anyjob-dashboard-page-stats-card-stats-section {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .anyjob-dashboard-page-stats-count-value-n-img {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .anyjob-dashboard-page-stats-card-stats-legends {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }

  .anyjob-dashboard-page-stats-legends-item {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .anyjob-dashboard-page-stats-legends-indicator {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }

  .anyjob-dashboard-page-stats-legends-value {
    color: var(--Black-1, #1A1A1A);
    
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 28.8px */
  }

  .anyjob-dashboard-page-stats-legends-label {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-dashboard-page-stats-card-cta {
    display: flex;
    align-items: center;
    color: var(--Secondary-Color, #FF5851);
    gap: 10px;
  }

  .anyjob-dashboard-page-stats-card-cta p {
    color: var(--Secondary-Color, #FF5851);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-dashboard-page-stats-card-cta-icon {
    width: 24px;
    height: 24px;
    color: var(--Secondary-Color, #FF5851);
  }

  .anyjob-dashboard-page-stats-empty-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  .anyjob-dashboard-page-stats-empty-card p {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    text-align: center;
  }

  .anyjob-dashboard-page-stats-empty-card img {
    width: 150px;
    height: 150px;
  }

  .anyjob-dashboard-page-recent-applications-container {
    margin: 32px 40px;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
  }

  .anyjob-dashboard-page-recent-applications-header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D6DDEB;
  }

  .anyjob-dashboard-page-recent-applications-header-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
  }

  .anyjob-dashboard-applications-table {
    padding: 10px 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .anyjob-dashboard-table-container {
    padding: 30px 15px;
  }
  
}


@media screen and (min-width: 720px)  and (max-width: 1039px)  {
  .anyjob-dashboard-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-dashboard-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-dashboard-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-dashboard-page-hero-headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
  
  .anyjob-proposal-hero-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 42px */
    letter-spacing: 0.56px;
  }

  .anyjob-proposal-hero-desc {
    color: var(--Grey1, #767676);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-dashboard-page-applied-ongoing-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .anyjob-dashboard-page-stats-section {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 32px 40px;
  }

  .anyjob-dashboard-page-stats-count {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    flex: 1;
  }

  .anyjob-dashboard-page-stats-card {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    flex: 1;
    padding: 24px;
  }

  .anyjob-dashboard-page-stats-count-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
    margin: 24px;
  }


  .anyjob-dashboard-page-stats-count-value {
    color: var(--Black-1, #1A1A1A);
    text-align: left;
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 72px */
    margin-left: 32px;
  }

  .anyjob-dashboard-page-stats-count-img {
    width: 80px;
    height: 80px;
    align-self: flex-end;
  }

  .anyjob-dashboard-page-stats-doughnut-container {
    /* width: 152px; */
  }

  .anyjob-dashboard-page-stats-card-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; 
  }

  .anyjob-dashboard-page-stats-card-stats-section {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .anyjob-dashboard-page-stats-count-value-n-img {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .anyjob-dashboard-page-stats-card-stats-legends {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }

  .anyjob-dashboard-page-stats-legends-item {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .anyjob-dashboard-page-stats-legends-indicator {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }

  .anyjob-dashboard-page-stats-legends-value {
    color: var(--Black-1, #1A1A1A);
    
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 28.8px */
  }

  .anyjob-dashboard-page-stats-legends-label {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-dashboard-page-stats-card-cta {
    display: flex;
    align-items: center;
    color: var(--Secondary-Color, #FF5851);
    gap: 10px;
  }

  .anyjob-dashboard-page-stats-card-cta p {
    color: var(--Secondary-Color, #FF5851);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-dashboard-page-stats-card-cta-icon {
    width: 24px;
    height: 24px;
    color: var(--Secondary-Color, #FF5851);
  }

  .anyjob-dashboard-page-stats-empty-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  .anyjob-dashboard-page-stats-empty-card p {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    text-align: center;
  }

  .anyjob-dashboard-page-stats-empty-card img {
    width: 150px;
    height: 150px;
  }

  .anyjob-dashboard-page-recent-applications-container {
    margin: 32px 40px;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
  }

  .anyjob-dashboard-page-recent-applications-header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D6DDEB;
  }

  .anyjob-dashboard-page-recent-applications-header-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
  }

  .anyjob-dashboard-applications-table {
    padding: 10px 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .anyjob-dashboard-table-container {
    padding: 30px 15px;
  }
  
}

@media screen and (max-width: 719px)  {
  .anyjob-dashboard-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-dashboard-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-dashboard-page-content-hero {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-dashboard-page-hero-headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
  
  .anyjob-proposal-hero-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 42px */
    letter-spacing: 0.56px;
  }

  .anyjob-proposal-hero-desc {
    color: var(--Grey1, #767676);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-dashboard-page-applied-ongoing-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .anyjob-dashboard-page-stats-section {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 20px;
  }

  .anyjob-dashboard-page-stats-count {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    flex: 1;
  }

  .anyjob-dashboard-page-stats-card {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    flex: 1;
    padding: 24px;
  }

  .anyjob-dashboard-page-stats-count-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
    margin: 24px;
  }


  .anyjob-dashboard-page-stats-count-value {
    color: var(--Black-1, #1A1A1A);
    text-align: left;
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 72px */
    margin-left: 32px;
  }

  .anyjob-dashboard-page-stats-count-img {
    width: 80px;
    height: 80px;
    align-self: flex-end;
  }

  .anyjob-dashboard-page-stats-doughnut-container {
    align-items: flex-start;
  }

  .anyjob-dashboard-page-stats-card-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; 
  }

  .anyjob-dashboard-page-stats-card-stats-section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .anyjob-dashboard-page-stats-count-value-n-img {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .anyjob-dashboard-page-stats-card-stats-legends {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
  }

  .anyjob-dashboard-page-stats-legends-item {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
  }

  .anyjob-dashboard-page-stats-legends-indicator {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }

  .anyjob-dashboard-page-stats-legends-value {
    color: var(--Black-1, #1A1A1A);
    
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 28.8px */
  }

  .anyjob-dashboard-page-stats-legends-label {
    color: var(--Grey1, #767676);    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-dashboard-page-stats-card-cta {
    display: flex;
    align-items: center;
    color: var(--Secondary-Color, #FF5851);
    gap: 10px;
    
  }

  .anyjob-dashboard-page-stats-card-cta p {
    color: var(--Secondary-Color, #FF5851);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-dashboard-page-stats-card-cta-icon {
    width: 16px;
    height: 16px;
    color: var(--Secondary-Color, #FF5851);
  }

  .anyjob-dashboard-page-stats-empty-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  .anyjob-dashboard-page-stats-empty-card p {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    text-align: center;
  }

  .anyjob-dashboard-page-stats-empty-card img {
    width: 120px;
    margin-top: 20px;
  }

  .anyjob-dashboard-page-recent-applications-container {
    margin: 20px;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
  }

  .anyjob-dashboard-page-recent-applications-header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D6DDEB;
  }

  .anyjob-dashboard-page-recent-applications-header-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
  }

  .anyjob-dashboard-applications-table {
    padding: 10px 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .anyjob-empty-state-container img {
    height: 100px;
  }

  .anyjob-empty-state-container-btn {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin: 0px 15px;
  }

  .anyjob-dashboard-table-container {
    padding: 15px;
    border-width: 0px;
  }
  
}

.anyjob-dashboard-table-header {
  background-color: #F4F4F4;
  border-radius: 8px;
}

.anyjob-dashboard-table {
  border-radius: 12px;
}

.anyjob-dashboard-table-item:hover {
  background-color: #F4F4F4;
  cursor: pointer;
}
