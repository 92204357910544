.anyjob-add-experience-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex: 1,
}

.anyjob-add-experience-modal-contents {
  width: 680px;
  height: 850px;
  background-color: #FFF;
  border-radius: 24px;
  align-self: center;
  justify-self: center;
  margin-top: 20;
}

.anyjob-add-experience-modal-contents-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #FF5851;
  height: 80px;
  padding: 0px 24px;
}

.anyjob-add-experience-modal-contents-header p {
  color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px; /* 120.833% */
  letter-spacing: -0.48px;
}

.anyjob-add-experience-modal-close-btn {
  padding: 10px;
  cursor: pointer;
  
}

.anyjob-add-experience-modal-close-icon {
  width: 32px;
  height: 32px;
  color: black;
}

.anyjob-add-experience-modal-form {
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 750px;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.anyjob-add-experience-modal-form-input-group {
  margin-top: 24px;
  width: 100%;
}

.anyjob-add-experience-modal-form-input-group-label {
  color: var(--Black-1, #1A1A1A);  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.anyjob-add-experience-modal-form-input-group-field {
  outline: none;
  box-shadow: none;

  border-radius: 50px;
  border: 0.673px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  height: 50px;
  margin-top: 10px;
  padding: 0px 15px;
  width: 100%;
}

.anyjob-add-experience-modal-form-textarea {
  outline: none;
  box-shadow: none;

  border-radius: 24px;
  border: 0.673px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  min-height: 120px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
}

.anyjob-add-experience-modal-form-input-row-group {
  display: flex;
  width: 100%;
}

.anyjob-add-experience-location {
  margin-right: 15px;
}

.anyjob-add-experience-country {
  margin-left: 15px;
}

.anyjob-add-experience-date-group {
  display: flex;

}

.anyjob-add-experience-start-date {
  margin-right: 10px;
}

.anyjob-add-experience-modal-form-actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;
}

.anyjob-add-experience-modal-form-actions-cancel {
  cursor: pointer;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.anyjob-add-experience-modal-form-actions-save {
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  width: 250px;
  height: 50px;
  margin-left: 30px;
}

.anyjob-add-experience-modal-form-input-check {
  display: flex;
  margin-top: 10px;
}

.anyjob-add-experience-modal-form-input-check p {
  font-size: 12px;
  margin-left: 10px;
}

.anyjob-add-experience-modal-form-err-msg {
  font-size: 12px;
  color: #FF5851;
  margin-top: 6px;
}