.anyjob-proposals-status-table-item-index {
  width: 60px;
  color: var(--neutral-black-02-d-2-d-2-d, #666666);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.28px;
}

@media screen and (min-width: 1320px) {
  .anyjob-proposals-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-proposals-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-proposals-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-proposals-page-hero-headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
  
  .anyjob-proposal-hero-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 42px */
    letter-spacing: 0.56px;
  }

  .anyjob-proposal-hero-desc {
    color: var(--Grey1, #767676);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-proposals-page-hero-date-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 180px; */
    padding: 12px 16px;
    border-radius: 50px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    cursor: pointer;
  }

  .anyjob-proposals-page-hero-date-btn-date-range {
    color: #1A1A1A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-right: 15px;
  }

  .anyjob-proposals-container {
    display: flex;
    flex-direction: column;
    margin: 40px;
    height: 917px;
    padding: 25px 24px;
    border-radius: 12px;
    background: #FFF;
    /* justify-content: center;
    align-items: center;
    flex-shrink: 0; */
  }

  .anyjob-proposals-status-tab-header {
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: scroll;
  }

  .anyjob-proposals-status-tab-header-active-item {
    min-width: 140px;
    padding: 11px;
    border-bottom: 2px solid #FF5851;
    cursor: pointer;

    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */

  }

  .anyjob-proposals-status-tab-header-item {
    min-width: 140px;
    padding: 11px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    color: var(--Black-1, #767676);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */

  }

  .anyjob-proposals-status-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 20px;
    background-color: #F4F4F4;
    border-radius: 8px;
    margin-top: 20px;
  }

  .anyjob-proposals-status-table-header tr {
    display: flex;
    align-items: center;
    
    width: 100%;
  }

  .anyjob-proposals-status-table-header-col {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    /* text-align: center; */
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
    width: 24%;
  }

  .anyjob-proposals-status-table-body {
    height: 100%;
    margin-top: 10px;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .anyjob-proposals-status-table-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 20px;
    /* background-color: #F4F4F4; */
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
    overflow: hidden;
    overflow-x: scroll;
  }

  .anyjob-proposals-status-table-item:hover {
    background-color: #F4F4F4;
  }

  .anyjob-proposals-status-table-body-col {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.14px;
    width: 24%;
    
  }

  .anyjob-empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 50px;
  }

  .anyjob-empty-state-container img {
    width: 200px;
  }

  .anyjob-empty-state-container p {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
  }

  .anyjob-empty-state-container-btn {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    padding: 12px 132px;
    align-items: flex-start;
    gap: 10px;
    margin: 30px 0px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
  }
}


@media screen and (min-width: 1040px)  and (max-width: 1329px)  {
  .anyjob-proposals-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-proposals-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-proposals-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-proposals-page-hero-headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
  
  .anyjob-proposal-hero-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 42px */
    letter-spacing: 0.56px;
  }

  .anyjob-proposal-hero-desc {
    color: var(--Grey1, #767676);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-proposals-page-hero-date-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 180px; */
    padding: 12px 16px;
    border-radius: 50px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    cursor: pointer;
  }

  .anyjob-proposals-page-hero-date-btn-date-range {
    color: #1A1A1A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-right: 15px;
  }

  .anyjob-proposals-container {
    display: flex;
    flex-direction: column;
    margin: 40px;
    height: 917px;
    padding: 25px 24px;
    border-radius: 12px;
    background: #FFF;
    /* justify-content: center;
    align-items: center;
    flex-shrink: 0; */
  }

  .anyjob-proposals-status-tab-header {
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: scroll;
  }

  .anyjob-proposals-status-tab-header-active-item {
    min-width: 120px;
    padding: 11px;
    border-bottom: 2px solid #FF5851;
    cursor: pointer;

    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */

  }

  .anyjob-proposals-status-tab-header-item {
    min-width: 120px;
    padding: 11px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    color: var(--Black-1, #767676);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */

  }

  .anyjob-proposals-status-tab-header-item:hover {
    background-color: #fffafa;
  }

  .anyjob-proposals-status-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 20px;
    background-color: #F4F4F4;
    border-radius: 8px;
    margin-top: 20px;
  }

  .anyjob-proposals-status-table-header tr {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .anyjob-proposals-status-table-header-col {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    /* text-align: center; */
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
    width: 24%;
  }

  .anyjob-proposals-status-table-body {
    height: 100%;
    margin-top: 10px;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .anyjob-proposals-status-table-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 20px;
    /* background-color: #F4F4F4; */
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
  }

  .anyjob-proposals-status-table-item:hover {
    background-color: #F4F4F4;
  }

  .anyjob-proposals-status-table-body-col {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.14px;
    width: 24%;
  }

  .anyjob-empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 50px;
  }

  .anyjob-empty-state-container img {
    width: 200px;
  }

  .anyjob-empty-state-container p {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
  }

  .anyjob-empty-state-container-btn {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    padding: 12px 132px;
    align-items: flex-start;
    gap: 10px;
    margin: 30px 0px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
  }
}

@media screen and (min-width: 720px)  and (max-width: 1039px)  {
  .anyjob-proposals-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-proposals-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-proposals-page-content-hero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-proposals-page-hero-headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
  
  .anyjob-proposal-hero-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 42px */
    letter-spacing: 0.56px;
  }

  .anyjob-proposal-hero-desc {
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-proposals-page-hero-date-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 180px; */
    padding: 12px 16px;
    border-radius: 50px;
    margin-top: 24px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    cursor: pointer;
  }

  .anyjob-proposals-page-hero-date-btn-date-range {
    color: #1A1A1A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-right: 15px;
  }

  .anyjob-proposals-container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 917px;
    padding: 20px;
    border-radius: 12px;
    background: #FFF;
    /* justify-content: center;
    align-items: center;
    flex-shrink: 0; */
  }

  .anyjob-proposals-status-tab-header {
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: scroll;
  }

  .anyjob-proposals-status-tab-header-active-item {
    min-width: 120px;
    padding: 11px;
    border-bottom: 2px solid #FF5851;
    cursor: pointer;

    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */

  }

  .anyjob-proposals-status-tab-header-item {
    min-width: 120px;
    padding: 11px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    color: var(--Black-1, #767676);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */

  }

  .anyjob-proposals-status-tab-header-item:hover {
    background-color: #fffafa;
  }

  .anyjob-proposals-status-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 20px;
    background-color: #F4F4F4;
    border-radius: 8px;
    margin-top: 20px;
  }

  .anyjob-proposals-status-table-header tr {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .anyjob-proposals-status-table-header-col {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    /* text-align: center; */
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
    width: 24%;
  }

  .anyjob-proposals-status-table-body {
    height: 100%;
    margin-top: 10px;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .anyjob-proposals-status-table-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 20px;
    /* background-color: #F4F4F4; */
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
  }

  .anyjob-proposals-status-table-item:hover {
    background-color: #F4F4F4;
  }

  .anyjob-proposals-status-table-body-col {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.14px;
    width: 24%;
  }

  .anyjob-empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 50px;
  }

  .anyjob-empty-state-container img {
    width: 200px;
  }

  .anyjob-empty-state-container p {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
  }

  .anyjob-empty-state-container-btn {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    padding: 12px 132px;
    align-items: flex-start;
    gap: 10px;
    margin: 30px 0px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
  }

  .anyjob-pagination-controls-container {
    display: flex;
    align-self: flex-end;
    margin: 40px;
  
  }
  
  .anyjob-pagination-controls-container p  {
    margin-right: 10px;
    color: var(--neutral-gray-1828282, #828282);
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
  }

  .anyjob-pagination-controls-btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

@media screen and (max-width: 719px){
  .anyjob-proposals-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-proposals-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-proposals-page-content-hero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-proposals-page-hero-headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
  
  .anyjob-proposal-hero-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 42px */
    letter-spacing: 0.56px;
  }

  .anyjob-proposal-hero-desc {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-proposals-page-hero-date-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 180px; */
    padding: 12px 16px;
    border-radius: 50px;
    margin-top: 24px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    cursor: pointer;
  }

  .anyjob-proposals-page-hero-date-btn-date-range {
    color: #1A1A1A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-right: 15px;
  }

  .anyjob-proposals-container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 917px;
    padding: 20px;
    border-radius: 12px;
    background: #FFF;
    /* justify-content: center;
    align-items: center;
    flex-shrink: 0; */
  }

  .anyjob-proposals-status-tab-header {
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: scroll;
  }

  .anyjob-proposals-status-tab-header-active-item {
    min-width: 120px;
    padding: 11px;
    border-bottom: 2px solid #FF5851;
    cursor: pointer;

    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */

  }

  .anyjob-proposals-status-tab-header-item {
    min-width: 120px;
    padding: 11px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    color: var(--Black-1, #767676);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */

  }

  .anyjob-proposals-status-tab-header-item:hover {
    background-color: #fffafa;
  }

  .anyjob-proposals-status-table {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-x: scroll;
  }

  .anyjob-proposals-status-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 20px;
    background-color: #F4F4F4;
    border-radius: 8px;
    margin-top: 20px;
    /* min-width: 600px; */
  }

  .anyjob-proposals-status-table-header tr {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .anyjob-proposals-status-table-header-col {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    /* text-align: center; */
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
    width: 24%;
  }

  .anyjob-proposals-status-table-body {
    height: 100%;
    margin-top: 10px;
    width: 100%;
    min-width: 600px;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .anyjob-proposals-status-table-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 20px;
    /* background-color: #F4F4F4; */
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
  }

  .anyjob-proposals-status-table-item:hover {
    background-color: #F4F4F4;
  }

  .anyjob-proposals-status-table-body-col {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.14px;
    width: 24%;
  }

  .anyjob-empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 50px;
  }

  .anyjob-empty-state-container img {
    width: 200px;
  }

  .anyjob-empty-state-container p {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
  }

  .anyjob-empty-state-container-btn {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 30px 0px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
  }

  .anyjob-pagination-controls-container {
    display: flex;
    align-self: flex-end;
    margin: 40px;
  
  }
  
  .anyjob-pagination-controls-container p  {
    margin-right: 10px;
    color: var(--neutral-gray-1828282, #828282);
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
  }

  .anyjob-pagination-controls-btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}


