.anyjob-pin-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex: 1,
}

.anyjob-pin-modal-container {
  width: 670px;
  min-height: 502px;
  background-color: #FFF;
  border-radius: 24px;
  align-self: center;
  justify-self: center;
  margin-top: 20;
  display: flex;
  flex-direction: column;
}

.anyjob-pin-modal-close-btn {
  padding: 20px 20px 0px 20px;
  cursor: pointer;
  align-self: flex-end;
}

.anyjob-pin-modal-close-icon {
  width: 32px;
  height: 32px;
  color: black;
}

.anyjob-pin-modal-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
  margin-bottom: 20px;
}

.anyjob-pin-modal-img-0 {
  height: 155px;

}

.anyjob-pin-modal-img-1 {
  height: 90px;

}

.anyjob-pin-modal-title {
  font-size: 24px;
  font-weight: 600;
  color: #1A1A1A;
  margin-top: 20px;
  text-align: center;
}

.anyjob-pin-modal-sub-title {
  font-size: 16px;
  color: #555555;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.anyjob-pin-modal-input-container {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  width: 100%;
}

.anyjob-pin-modal-input-label {
  font-size: 14px;
  color: #555555;
  margin-bottom: 10px;
  margin-top: 20px;
}

.anyjob-pin-modal-otp-input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.anyjob-pin-modal-otp-input {
  border-radius: 8px;
  border: 1px solid var(--Grey-2, #BDBDBD);
  width: 64px;
  height: 64px;
  margin-right: 20px;
  display: flex;
  padding: 0px 18px;
  justify-content: center;
  align-items: center;
}

.anyjob-pin-modal-otp-input:focus {
  border-radius: 8px;
  border: 1px solid var(--Grey-2, #FF5851);
  width: 64px;
  height: 64px;
}

.anyjob-pin-modal-btn {
  background-color: #F7CE38;
  width: 470px;
  height: 64px;
  border-radius: 50px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #1A1A1A;
}

.anyjob-pin-modal-btn:hover {
  background-color: #EDC635;
  width: 465px;
  height: 64px;
}

.anyjob-pin-modal-btn-1 {
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  padding: 15px;
  letter-spacing: 1px;
  color: #FF5851;
}

.anyjob-pin-modal-btn-1-disabled {
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  padding: 15px;
  letter-spacing: 1px;
  color: #BDBDBD;
}

.anyjob-pin-modal-resend-otp-container {
  display: flex;
  align-items: center;
}

.anyjob-pin-modal-resend-otp-container p {
  font-size: 16px;
  color: #1A1A1A;
}

.anyjob-pin-modal-err {
  color: #FF5851;
  font-size: 12px;
  margin-top: 10px;
}