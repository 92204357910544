



@media screen and (min-width: 860px) {
  .anyjob-wallet-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }
  .anyjob-wallet-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-wallet-card {
    background-color: #FFFBEC;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #FFC839;
    display: flex;
    height: fit-content;
    margin: 40px;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-wallet-card-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .anyjob-wallet-card-content-label {
    font-size: 18px;
    color: #767676;
    font-weight: 600;
  }
  
  .anyjob-wallet-card-content-value {
    font-size: 42px;
    color: #1A1A1A;
    font-weight: 600;
  }
  
  .anyjob-wallet-card-invisible-divider {
    flex: 1;
    display: block;
  
  }
  
  .anyjob-wallet-card-actions {
    display: flex;
    background-color: #FFC839;
    border-radius: 10px;
    width: fit-content;
  }
  
  .anyjob-wallet-card-btn {
    width: 110px;
    height: 100px;
    border-radius: 10px;
    background-color: #FFC839;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .anyjob-wallet-card-btn img {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-wallet-card-btn p {
    font-size: 16px;
    color: #1A1A1A;
    margin-top: 10px;
    font-weight: 600;
  }
  
  .anyjob-wallet-page-divider {
    
    height: 1px;
    background-color: #E0E0E0;
  }
  
  .anyjob-wallet-page-transactions-container {
    margin: 40px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 28px 24px;
  }
  
  .anyjob-wallet-page-transactions-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  .anyjob-wallet-page-transactions-container-header-label {
    color: #1A1A1A;
    font-size: 14px;
  
  }
  
  .anyjob-wallet-page-transactions-container-header-btn {
    color: #1A1A1A;
    font-size: 14px;
    text-decoration: underline;
  }
  
  .anyjob-wallet-page-transactions-table {
    width: 100%;
    border-radius: 10px;
  }
  
  .anyjob-wallet-page-transactions-table th {
    width: 25%;
    font-size: 14px;
    font-weight: 600;
    color: #1A1A1A;
    padding: 16px 0px;
  }
  
  .anyjob-wallet-page-transactions-table td {
    width: 20%;
    font-size: 14px;
    font-weight: 400;
    color: #1A1A1A;
    padding: 16px 0px;
    text-align: center;
  }
  
  #anyjob-wallet-page-transactions-table-trans-id {
    width: 25%;
    font-size: 14px;
    font-weight: 400;
    color: #BDBDBD;
    padding: 16px 0px;
    text-align: center;
  }
  
  .anyjob-wallet-page-transactions-table-head {
    background-color: #F4F4F4;
    border-radius: 10px;
  }
}

@media screen and (min-width: 650px) and (max-width: 859px) {
  .anyjob-wallet-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }
  .anyjob-wallet-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-wallet-card {
    background-color: #FFFBEC;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #FFC839;
    display: flex;
    height: fit-content;
    margin: 40px;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-wallet-card-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .anyjob-wallet-card-content-label {
    font-size: 16px;
    color: #767676;
    font-weight: 600;
  }
  
  .anyjob-wallet-card-content-value {
    font-size: 36px;
    color: #1A1A1A;
    font-weight: 600;
  }
  
  .anyjob-wallet-card-invisible-divider {
    flex: 1;
    display: none;
  
  }
  
  .anyjob-wallet-card-actions {
    display: flex;
    background-color: #FFC839;
    border-radius: 10px;
    padding: 32px;
    width: fit-content;
  }

  .anyjob-wallet-card-btn {
    width: 110px;
    height: 100px;
    border-radius: 10px;
    background-color: #FFC839;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .anyjob-wallet-card-btn img {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-wallet-card-btn p {
    font-size: 16px;
    color: #1A1A1A;
    margin-top: 10px;
    font-weight: 600;
  }
  
  .anyjob-wallet-page-divider {
    
    height: 1px;
    background-color: #E0E0E0;
  }
  
  .anyjob-wallet-page-transactions-container {
    margin: 40px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 28px 24px;
  }
  
  .anyjob-wallet-page-transactions-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  .anyjob-wallet-page-transactions-container-header-label {
    color: #1A1A1A;
    font-size: 14px;
  
  }
  
  .anyjob-wallet-page-transactions-container-header-btn {
    color: #1A1A1A;
    font-size: 14px;
    text-decoration: underline;
  }
  
  .anyjob-wallet-page-transactions-table {
    width: 100%;
    border-radius: 10px;
  }
  
  .anyjob-wallet-page-transactions-table th {
    width: 25%;
    font-size: 14px;
    font-weight: 600;
    color: #1A1A1A;
    padding: 16px 0px;
  }
  
  .anyjob-wallet-page-transactions-table td {
    width: 20%;
    font-size: 14px;
    font-weight: 400;
    color: #1A1A1A;
    padding: 16px 0px;
    text-align: center;
  }
  
  #anyjob-wallet-page-transactions-table-trans-id {
    width: 25%;
    font-size: 14px;
    font-weight: 400;
    color: #BDBDBD;
    padding: 16px 0px;
    text-align: center;
  }
  
  .anyjob-wallet-page-transactions-table-head {
    background-color: #F4F4F4;
    border-radius: 10px;
  }
}

@media screen and (max-width: 649px) {
  .anyjob-wallet-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }
  .anyjob-wallet-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-wallet-card {
    background-color: #FFFBEC;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #FFC839;
    display: flex;
    height: fit-content;
    margin: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-wallet-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .anyjob-wallet-card-content-label {
    font-size: 14px;
    color: #767676;
    font-weight: 600;
  }
  
  .anyjob-wallet-card-content-value {
    font-size: 28px;
    color: #1A1A1A;
    font-weight: 600;
  }
  
  .anyjob-wallet-card-invisible-divider {
    flex: 1;
    display: none;
  
  }
  
  .anyjob-wallet-card-actions {
    display: flex;
    background-color: #FFC839;
    border-radius: 10px;
    padding: 20px;
    width: fit-content;
  }
  
  .anyjob-wallet-card-btn {
    width: 90px;
    height: 80px;
    border-radius: 10px;
    background-color: #FFC839;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .anyjob-wallet-card-btn img {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-wallet-card-btn p {
    font-size: 14px;
    color: #1A1A1A;
    margin-top: 10px;
    font-weight: 600;
  }
  
  .anyjob-wallet-page-divider {
    
    height: 1px;
    background-color: #E0E0E0;
  }
  
  .anyjob-wallet-page-transactions-container {
    margin: 20px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 15px;
  }
  
  .anyjob-wallet-page-transactions-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  .anyjob-wallet-page-transactions-container-header-label {
    color: #1A1A1A;
    font-size: 14px;
  
  }
  
  .anyjob-wallet-page-transactions-container-header-btn {
    color: #1A1A1A;
    font-size: 14px;
    text-decoration: underline;
  }
  
  .anyjob-wallet-page-transactions-table {
    width: 100%;
    border-radius: 10px;
  }
  
  .anyjob-wallet-page-transactions-table th {
    /* width: 20%; */
    font-size: 12px;
    font-weight: 600;
    color: #1A1A1A;
    padding: 12px 0px;
  }
  
  .anyjob-wallet-page-transactions-table td {
    width: 25%;
    font-size: 12px;
    font-weight: 400;
    color: #1A1A1A;
    padding: 12px 0px;
    text-align: center;
  }
  
  #anyjob-wallet-page-transactions-table-trans-id {
    width: 25%;
    font-size: 12px;
    font-weight: 400;
    color: #BDBDBD;
    padding: 12px 0px;
    text-align: center;
  }
  
  .anyjob-wallet-page-transactions-table-head {
    background-color: #F4F4F4;
    border-radius: 10px;
  }
}