@media screen and (min-width: 850px) {
  .anyjob-notifications-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .anyjob-notifications-page-notification-list {
    width: 100%;
    flex: 1;
    /* position: relative; */
    overflow: scroll;
    overflow-x: hidden;
    border-top: 1px solid #e2e2e2;
    padding: 40px;
  }

  .anyjob-notifications-page-notification-item {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    border: 1.297px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .anyjob-notifications-page-notification-item-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }  

  .anyjob-notifications-page-notification-item-title {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    
  }

  .anyjob-notifications-page-notification-item-date {
    font-size: 14px;
    color: #828282;
    
  }

  .anyjob-notifications-page-notification-item-message {
    font-size: 14px;
    color: #1a1a1a;
    margin-top: 10px;
    
  }
}

@media screen and (min-width: 720px)  and (max-width: 849px) {
  .anyjob-notifications-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .anyjob-notifications-page-notification-list {
    width: 100%;
    flex: 1;
    /* position: relative; */
    overflow: scroll;
    overflow-x: hidden;
    border-top: 1px solid #e2e2e2;
    padding: 40px;
  }

  .anyjob-notifications-page-notification-item {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    border: 1.297px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .anyjob-notifications-page-notification-item-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }  

  .anyjob-notifications-page-notification-item-title {
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
    
  }

  .anyjob-notifications-page-notification-item-date {
    font-size: 12px;
    color: #828282;
    
  }

  .anyjob-notifications-page-notification-item-message {
    font-size: 12px;
    color: #1a1a1a;
    margin-top: 10px;
    
  }
}

@media screen and (min-width: 520px) and (max-width: 719px) {
  .anyjob-notifications-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .anyjob-notifications-page-notification-list {
    width: 100%;
    flex: 1;
    /* position: relative; */
    overflow: scroll;
    overflow-x: hidden;
    border-top: 1px solid #e2e2e2;
    padding: 40px;
  }

  .anyjob-notifications-page-notification-item {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    border: 1.297px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .anyjob-notifications-page-notification-item-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }  

  .anyjob-notifications-page-notification-item-title {
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
    
  }

  .anyjob-notifications-page-notification-item-date {
    font-size: 12px;
    color: #828282;
    
  }

  .anyjob-notifications-page-notification-item-message {
    font-size: 12px;
    color: #1a1a1a;
    margin-top: 10px;
    
  }
}

@media screen and (max-width: 519px) {
  .anyjob-notifications-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .anyjob-notifications-page-notification-list {
    width: 100%;
    flex: 1;
    /* position: relative; */
    overflow: scroll;
    overflow-x: hidden;
    border-top: 1px solid #e2e2e2;
    padding: 20px;
  }

  .anyjob-notifications-page-notification-item {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    border: 1.297px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .anyjob-notifications-page-notification-item-heading {
    display: flex;
    flex-direction: column;
  }  

  .anyjob-notifications-page-notification-item-title {
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
    
  }

  .anyjob-notifications-page-notification-item-date {
    font-size: 12px;
    color: #828282;
    
  }

  .anyjob-notifications-page-notification-item-message {
    font-size: 12px;
    color: #1a1a1a;
    margin-top: 10px;
    
  }
}