.anyjob-header-logo {
  width: 95px;
}

@media screen and (min-width: 720px)  {
  .anyjob-page-header-container {
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    width: 100%;
    justify-content: space-between;
    padding: 30px 40px;
  }
  
  .anyjob-page-header-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 79.167% */
    letter-spacing: 0.48px;
  }
  
  .anyjob-page-header-notification-btn {
    border-radius: 8px;
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 4px 10px 0px rgba(174, 173, 173, 0.03);
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .anyjob-page-mobile-header-container {
    display: none;
  }

}

@media screen and (max-width: 719px)  {
  .anyjob-page-header-container {
    display: none;
    height: 80px;
    /* display: flex; */
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    width: 100%;
    justify-content: space-between;
    padding: 30px 40px;
  }

  .anyjob-page-mobile-header-container {
    display: flex;
    background: #FAFAFA;
    box-shadow: 0px 10px 20px 0px rgba(22, 22, 22, 0.04);
    padding: 10px 24px;
    align-items: center;
    justify-content: space-between;
  }
  
  .anyjob-page-header-title {
    color: var(--Black-1, #1A1A1A);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 79.167% */
    letter-spacing: 0.48px;
  }
  
  .anyjob-page-header-notification-btn {
    border-radius: 8px;
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 4px 10px 0px rgba(174, 173, 173, 0.03);
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .anyjob-mobile-header-menu-logo-section {
    display: flex;
    align-items: center;
  }
}