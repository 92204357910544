

@media screen and (min-width: 1320px) {
  .anyjob-profile-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }

  .anyjob-profile-page-container {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  

  .anyjob-profile-page-content {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    width: 75%;
    
  }
  

  .anyjob-profile-page-meta-data {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 25%;
    /* overflow: scroll;
    overflow-x: hidden; */
  }

  .anyjob-profile-page-hero-section {
    border-radius: 16px;
    /* border: 1px solid #E0E0E0; */
    background: var(--neutrals-0, #FFF);
    margin: 32px 40px;
    display: flex;
    flex-direction: column;    
    position: relative;
  }

  .anyjob-profile-page-cover-image {
    /* width: 100%; */
    width: auto;
    height: 180px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    resize: vertical;
  }

  .anyjob-profile-page-hero-personal-data {
    display: flex;
    padding: 0px 32px;
    padding-bottom: 56px;
    border: 1px solid #E0E0E0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .anyjob-profile-page-profile-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -60px;
    border: 4px solid #FFF;
  }

  .anyjob-profile-page-profile-default-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #e2e2e2;
    background-color: #fff;
  }

  .anyjob-onboarding-page-profile-default-icon {
    width: 60px;
    height: 60px;
    color: #e2e2e2;
  }

  .anyjob-profile-page-hero-personal-bio {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* width: 100%; */
    flex: 1;
    margin-top: 24px;
    margin-left: 24px;
  }

  .anyjob-profile-page-hero-personal-bio button {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-profile-page-hero-full-name {
    color: var(--Black-1, #1A1A1A);
    font-feature-settings: 'cv11' on;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
  }

  .anyjob-profile-page-hero-short-bio {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    display: flex;
  }


  .anyjob-profile-page-name-verification-status {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .anyjob-profile-page-edit-cover-image{
    width: 40px;
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 24px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    margin: 0px 40px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 50px;
  }

  .anyjob-profile-page-about-me-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-about-me-section-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  .anyjob-profile-page-about-me-section-edit-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section-bio {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 30px;
  }

  .anyjob-profile-page-experience-item-container {
    display: flex;
    margin-top: 24px;
  }

  .anyjob-profile-page-experience-item-icon {
    width: 72px;
    height: 72px;
  }

  .anyjob-profile-page-experience-item-contents {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 10px;
    width: 100%;
  }

  .anyjob-profile-page-experience-item-contents-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-experience-item-role {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 28.8px */
  }

  .anyjob-profile-page-experience-item-details-01 {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .anyjob-profile-page-experience-item-details-01-divider {
    width: 4px;
    height: 4px;
    margin: 0px 8px;
    background-color: #A8ADB7;
  }

  .anyjob-profile-page-experience-item-details-01-company-name {
    color: var(--Black-1, #1A1A1A);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-01-desc {
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-location {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-desc {
    color: var(--Black-1, #1A1A1A);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-divider {
    width: 100%;
    height: 1px;
    background-color: #d8d8d8;
    margin-top: 24px;
  }

  .anyjob-profile-page-show-more-btn {
    align-self: center;
    cursor: pointer;
    color: var(--Secondary-Color, #FF5851);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; 
    margin: 20px 0px;
  }

  .anyjob-profile-page-truncatable-text {
    color: #828282; 
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 10px;
  }

  .anyjob-profile-page-truncated-text {
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-profile-page-truncate-control {
    margin-top: 10px;
    color: var(--Secondary-Color, #FF5851);
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    cursor: pointer;
  }

  .anyjob-profile-page-skills-actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .anyjob-profile-page-skills-cancel-changes {
    color: var(--Black-1, #2d2d2d);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    cursor: pointer;
  }

  .anyjob-profile-page-skills-save-changes {
    height: 40px;
    border-radius: 40px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFC839;
    font-weight: 600;
    font-size: 14px;
  }

  .anyjob-profile-page-skills-editing-section {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .anyjob-profile-page-skills-editing-section-input-field {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #c7c7c7;
    font-size: 14px;
    padding: 8px;
  }

  .anyjob-profile-page-skills-suggestions-container {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    z-index: 9;
    padding: 10px;
    width: 250px;
    position: absolute;
    margin-top: 46px;
  }

  .anyjob-profile-page-skills-suggestion {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
  }
  
  .anyjob-profile-page-selected-skills {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }
  
  .anyjob-profile-page-selected-skill {
    display: flex;
    padding: 12px 20px;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    margin-right: 15px;
    margin-top: 10px;
  }
  
  .anyjob-profile-page-selected-skill p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.16px;
  }

  .anyjob-profile-page-current-skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-profile-page-current-skill {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 15px;
    margin-top: 10px;
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }
  
  .anyjob-profile-page-socials-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 32px;
    margin-right: 32px;
  }
  
  .anyjob-profile-page-socials-item {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    width: 100%;
  }
  
  .anyjob-profile-page-socials-item img {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-profile-page-socials-item-details {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-profile-page-socials-item-handles {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    padding-right: 10px;
  }
  
  .anyjob-profile-page-socials-item-label {
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }
}

@media screen and (min-width: 1024px) and (max-width: 1329px) {
  .anyjob-profile-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }

  .anyjob-profile-page-container {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  

  .anyjob-profile-page-content {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    width: 75%;
    /* overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden; */
  }
  

  .anyjob-profile-page-meta-data {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 25%;
    /* overflow: scroll;
    overflow-x: hidden; */
  }

  .anyjob-profile-page-hero-section {
    border-radius: 16px;
    /* border: 1px solid #E0E0E0; */
    background: var(--neutrals-0, #FFF);
    margin: 32px 40px;
    display: flex;
    flex-direction: column;    
    position: relative;
  }

  .anyjob-profile-page-cover-image {
    /* width: 100%; */
    width: auto;
    height: 180px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    resize: vertical;
  }

  .anyjob-profile-page-hero-personal-data {
    display: flex;
    flex-direction: column;
    padding: 0px 32px;
    padding-bottom: 56px;
    border: 1px solid #E0E0E0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .anyjob-profile-page-profile-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -74px;
    border: 4px solid #FFF;
  }

  .anyjob-profile-page-profile-default-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #e2e2e2;
    background-color: #fff;
  }

  .anyjob-onboarding-page-profile-default-icon {
    width: 60px;
    height: 60px;
    color: #e2e2e2;
  }

  .anyjob-profile-page-hero-personal-bio {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* width: 100%; */
    flex: 1;
    margin-top: 24px;
    margin-left: 24px;
  }


  .anyjob-profile-page-name-verification-status {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .anyjob-profile-page-hero-personal-bio button {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-profile-page-hero-full-name {
    color: var(--Black-1, #1A1A1A);
    font-feature-settings: 'cv11' on;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
  }

  .anyjob-profile-page-hero-short-bio {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    display: flex;
  }
  
  .anyjob-profile-page-edit-cover-image{
    width: 40px;
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 24px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    margin: 0px 40px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 50px;
  }

  .anyjob-profile-page-about-me-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-about-me-section-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  .anyjob-profile-page-about-me-section-edit-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section-bio {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 30px;
  }

  .anyjob-profile-page-experience-item-container {
    display: flex;
    margin-top: 24px;
  }

  .anyjob-profile-page-experience-item-icon {
    width: 72px;
    height: 72px;
  }

  .anyjob-profile-page-experience-item-contents {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 10px;
    width: 100%;
  }

  .anyjob-profile-page-experience-item-contents-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-experience-item-role {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 28.8px */
  }

  .anyjob-profile-page-experience-item-details-01 {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .anyjob-profile-page-experience-item-details-01-divider {
    width: 4px;
    height: 4px;
    margin: 0px 8px;
    background-color: #A8ADB7;
  }

  .anyjob-profile-page-experience-item-details-01-company-name {
    color: var(--Black-1, #1A1A1A);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-01-desc {
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-location {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-desc {
    color: var(--Black-1, #1A1A1A);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-divider {
    width: 100%;
    height: 1px;
    background-color: #d8d8d8;
    margin-top: 24px;
  }

  .anyjob-profile-page-show-more-btn {
    align-self: center;
    cursor: pointer;
    color: var(--Secondary-Color, #FF5851);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; 
    margin: 20px 0px;
  }

  .anyjob-profile-page-truncatable-text {
    color: #828282; 
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 10px;
  }

  .anyjob-profile-page-truncated-text {
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-profile-page-truncate-control {
    margin-top: 10px;
    color: var(--Secondary-Color, #FF5851);
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    cursor: pointer;
  }

  .anyjob-profile-page-skills-actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .anyjob-profile-page-skills-cancel-changes {
    color: var(--Black-1, #2d2d2d);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    cursor: pointer;
  }

  .anyjob-profile-page-skills-save-changes {
    height: 40px;
    border-radius: 40px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFC839;
    font-weight: 600;
    font-size: 14px;
  }

  .anyjob-profile-page-skills-editing-section {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .anyjob-profile-page-skills-editing-section-input-field {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #c7c7c7;
    font-size: 14px;
    padding: 8px;
  }

  .anyjob-profile-page-skills-suggestions-container {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    z-index: 9;
    padding: 10px;
    width: 250px;
    position: absolute;
    margin-top: 46px;
  }

  .anyjob-profile-page-skills-suggestion {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
  }
  
  .anyjob-profile-page-selected-skills {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }
  
  .anyjob-profile-page-selected-skill {
    display: flex;
    padding: 12px 20px;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    margin-right: 15px;
    margin-top: 10px;
  }
  
  .anyjob-profile-page-selected-skill p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.16px;
  }

  .anyjob-profile-page-current-skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-profile-page-current-skill {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 15px;
    margin-top: 10px;
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-profile-page-socials-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-right: 32px;
  }
  
  .anyjob-profile-page-socials-item {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
  }
  
  .anyjob-profile-page-socials-item img {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-profile-page-socials-item-details {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-profile-page-socials-item-handles {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    text-wrap: wrap;
    word-wrap: break-word;
  }
  
  .anyjob-profile-page-socials-item-label {
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }
  
}

@media screen and (min-width: 720px) and (max-width: 1023px) {
  .anyjob-profile-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }

  .anyjob-profile-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  

  .anyjob-profile-page-content {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    width: 100%;
    /* overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden; */
  }
  

  .anyjob-profile-page-meta-data {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    /* overflow: scroll;
    overflow-x: hidden; */
  }

  .anyjob-profile-page-hero-section {
    border-radius: 16px;
    /* border: 1px solid #E0E0E0; */
    background: var(--neutrals-0, #FFF);
    margin: 32px 40px;
    display: flex;
    flex-direction: column;    
    position: relative;
  }

  .anyjob-profile-page-cover-image {
    /* width: 100%; */
    width: auto;
    height: 180px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    resize: vertical;
  }

  .anyjob-profile-page-hero-personal-data {
    display: flex;
    flex-direction: column;
    padding: 0px 32px;
    padding-bottom: 56px;
    border: 1px solid #E0E0E0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .anyjob-profile-page-profile-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -74px;
    border: 4px solid #FFF;
  }

  .anyjob-profile-page-profile-default-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #e2e2e2;
    background-color: #fff;
  }

  .anyjob-onboarding-page-profile-default-icon {
    width: 60px;
    height: 60px;
    color: #e2e2e2;
  }

  .anyjob-profile-page-hero-personal-bio {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* width: 100%; */
    flex: 1;
    margin-top: 24px;
    margin-left: 24px;
  }


  .anyjob-profile-page-name-verification-status {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .anyjob-profile-page-hero-personal-bio button {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-profile-page-hero-full-name {
    color: var(--Black-1, #1A1A1A);
    font-feature-settings: 'cv11' on;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
  }

  .anyjob-profile-page-hero-short-bio {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    display: flex;
  }
  
  .anyjob-profile-page-edit-cover-image{
    width: 40px;
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 24px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    margin: 0px 40px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 50px;
  }

  .anyjob-profile-page-about-me-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-about-me-section-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  .anyjob-profile-page-about-me-section-edit-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section-bio {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 30px;
  }

  .anyjob-profile-page-experience-item-container {
    display: flex;
    margin-top: 24px;
  }

  .anyjob-profile-page-experience-item-icon {
    width: 72px;
    height: 72px;
  }

  .anyjob-profile-page-experience-item-contents {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 10px;
    width: 100%;
  }

  .anyjob-profile-page-experience-item-contents-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-experience-item-role {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 28.8px */
  }

  .anyjob-profile-page-experience-item-details-01 {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .anyjob-profile-page-experience-item-details-01-divider {
    width: 4px;
    height: 4px;
    margin: 0px 8px;
    background-color: #A8ADB7;
  }

  .anyjob-profile-page-experience-item-details-01-company-name {
    color: var(--Black-1, #1A1A1A);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-01-desc {
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-location {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-desc {
    color: var(--Black-1, #1A1A1A);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-divider {
    width: 100%;
    height: 1px;
    background-color: #d8d8d8;
    margin-top: 24px;
  }

  .anyjob-profile-page-show-more-btn {
    align-self: center;
    cursor: pointer;
    color: var(--Secondary-Color, #FF5851);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; 
    margin: 20px 0px;
  }

  .anyjob-profile-page-truncatable-text {
    color: #828282; 
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 10px;
  }

  .anyjob-profile-page-truncated-text {
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-profile-page-truncate-control {
    margin-top: 10px;
    color: var(--Secondary-Color, #FF5851);
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    cursor: pointer;
  }

  .anyjob-profile-page-skills-actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .anyjob-profile-page-skills-cancel-changes {
    color: var(--Black-1, #2d2d2d);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    cursor: pointer;
  }

  .anyjob-profile-page-skills-save-changes {
    height: 40px;
    border-radius: 40px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFC839;
    font-weight: 600;
    font-size: 14px;
  }

  .anyjob-profile-page-skills-editing-section {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .anyjob-profile-page-skills-editing-section-input-field {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #c7c7c7;
    font-size: 14px;
    padding: 8px;
  }

  .anyjob-profile-page-skills-suggestions-container {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    z-index: 9;
    padding: 10px;
    width: 250px;
    position: absolute;
    margin-top: 46px;
  }

  .anyjob-profile-page-skills-suggestion {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
  }
  
  .anyjob-profile-page-selected-skills {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }
  
  .anyjob-profile-page-selected-skill {
    display: flex;
    padding: 12px 20px;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    margin-right: 15px;
    margin-top: 10px;
  }
  
  .anyjob-profile-page-selected-skill p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.16px;
  }

  .anyjob-profile-page-current-skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-profile-page-current-skill {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 15px;
    margin-top: 10px;
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-profile-page-socials-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    margin: 40px;
  }
  
  .anyjob-profile-page-socials-item {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
  }
  
  .anyjob-profile-page-socials-item img {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-profile-page-socials-item-details {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-profile-page-socials-item-handles {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    text-wrap: wrap;
    word-wrap: break-word;
  }
  
  .anyjob-profile-page-socials-item-label {
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }
}

@media screen and (min-width: 600px) and (max-width: 719px) {
  .anyjob-profile-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }

  .anyjob-profile-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  

  .anyjob-profile-page-content {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    width: 100%;
    /* overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden; */
  }
  

  .anyjob-profile-page-meta-data {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    /* overflow: scroll;
    overflow-x: hidden; */
  }

  .anyjob-profile-page-hero-section {
    border-radius: 16px;
    /* border: 1px solid #E0E0E0; */
    background: var(--neutrals-0, #FFF);
    margin: 32px 20px;
    display: flex;
    flex-direction: column;    
    /* position: relative; */
  }

  .anyjob-profile-page-cover-image {
    /* width: 100%; */
    width: auto;
    height: 180px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    resize: vertical;
  }

  .anyjob-profile-page-hero-personal-data {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    padding-bottom: 56px;
    border: 1px solid #E0E0E0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .anyjob-profile-page-profile-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -74px;
    border: 4px solid #FFF;
  }

  .anyjob-profile-page-profile-default-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #e2e2e2;
    background-color: #fff;
  }

  .anyjob-onboarding-page-profile-default-icon {
    width: 60px;
    height: 60px;
    color: #e2e2e2;
  }

  .anyjob-profile-page-hero-personal-bio {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* width: 100%; */
    flex: 1;
    margin-top: 24px;
    /* margin-left: 15px; */
  }


  .anyjob-profile-page-name-verification-status {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .anyjob-profile-page-hero-personal-bio button {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);    
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-profile-page-hero-full-name {
    color: var(--Black-1, #1A1A1A);
    font-feature-settings: 'cv11' on;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
  }

  .anyjob-profile-page-hero-short-bio {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    display: flex;
  }
  
  .anyjob-profile-page-edit-cover-image{
    width: 40px;
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 24px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 30px;
  }

  .anyjob-profile-page-about-me-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-about-me-section-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  .anyjob-profile-page-about-me-section-edit-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section-bio {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 30px;
  }

  .anyjob-profile-page-experience-item-container {
    display: flex;
    margin-top: 24px;
  }

  .anyjob-profile-page-experience-item-icon {
    width: 72px;
    height: 72px;
  }

  .anyjob-profile-page-experience-item-contents {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 10px;
    width: 100%;
  }

  .anyjob-profile-page-experience-item-contents-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-experience-item-role {
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 28.8px */
  }

  .anyjob-profile-page-experience-item-details-01 {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .anyjob-profile-page-experience-item-details-01-divider {
    width: 4px;
    height: 4px;
    margin: 0px 8px;
    background-color: #A8ADB7;
  }

  .anyjob-profile-page-experience-item-details-01-company-name {
    color: var(--Black-1, #1A1A1A);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-01-desc {
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-location {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-desc {
    color: var(--Black-1, #1A1A1A);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-divider {
    width: 100%;
    height: 1px;
    background-color: #d8d8d8;
    margin-top: 24px;
  }

  .anyjob-profile-page-show-more-btn {
    align-self: center;
    cursor: pointer;
    color: var(--Secondary-Color, #FF5851);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; 
    margin: 20px 0px;
  }

  .anyjob-profile-page-truncatable-text {
    color: #828282; 
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 10px;
  }

  .anyjob-profile-page-truncated-text {
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-profile-page-truncate-control {
    margin-top: 10px;
    color: var(--Secondary-Color, #FF5851);
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    cursor: pointer;
  }

  .anyjob-profile-page-skills-actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .anyjob-profile-page-skills-cancel-changes {
    color: var(--Black-1, #2d2d2d);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    cursor: pointer;
  }

  .anyjob-profile-page-skills-save-changes {
    height: 40px;
    border-radius: 40px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFC839;
    font-weight: 600;
    font-size: 14px;
  }

  .anyjob-profile-page-skills-editing-section {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .anyjob-profile-page-skills-editing-section-input-field {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #c7c7c7;
    font-size: 14px;
    padding: 8px;
  }

  .anyjob-profile-page-skills-suggestions-container {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    z-index: 9;
    padding: 10px;
    width: 250px;
    position: absolute;
    margin-top: 46px;
  }

  .anyjob-profile-page-skills-suggestion {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
  }
  
  .anyjob-profile-page-selected-skills {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }
  
  .anyjob-profile-page-selected-skill {
    display: flex;
    padding: 8px 15px;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    margin-right: 15px;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .anyjob-profile-page-selected-skill p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.16px;
  }

  .anyjob-profile-page-current-skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-profile-page-current-skill {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 15px;
    margin-top: 10px;
    color: var(--Black-1, #1A1A1A);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-profile-page-socials-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  
  .anyjob-profile-page-socials-item {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
  }
  
  .anyjob-profile-page-socials-item img {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-profile-page-socials-item-details {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-profile-page-socials-item-handles {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    text-wrap: wrap;
    
  }
  
  .anyjob-profile-page-socials-item-label {
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }
  
}

@media screen  and (max-width: 599px) {
  .anyjob-profile-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }

  .anyjob-profile-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  

  .anyjob-profile-page-content {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    width: 100%;
    /* overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden; */
  }
  

  .anyjob-profile-page-meta-data {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    /* overflow: scroll;
    overflow-x: hidden; */
  }

  .anyjob-profile-page-hero-section {
    border-radius: 16px;
    background: var(--neutrals-0, #FFF);
    margin: 32px 20px;
    display: flex;
    flex-direction: column;    
    /* position: relative; */
  }

  .anyjob-profile-page-cover-image {
    /* width: 100%; */
    width: auto;
    height: 180px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    resize: vertical;
  }

  .anyjob-profile-page-hero-personal-data {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    padding-bottom: 56px;
    border: 1px solid #E0E0E0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .anyjob-profile-page-profile-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -74px;
    border: 4px solid #FFF;
  }

  .anyjob-profile-page-profile-default-image {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    margin-top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #e2e2e2;
    background-color: #fff;
  }

  .anyjob-onboarding-page-profile-default-icon {
    width: 60px;
    height: 60px;
    color: #e2e2e2;
  }

  .anyjob-profile-page-hero-personal-bio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* width: 100%; */
    flex: 1;
    margin-top: 24px;
    /* margin-left: 15px; */
  }

  .anyjob-profile-page-name-verification-status {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }

  .anyjob-profile-page-hero-personal-bio button {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);    
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    height: 50px;
    margin-top: 20px;
    width: 140px;
  }

  .anyjob-profile-page-hero-full-name {
    color: var(--Black-1, #1A1A1A);
    font-feature-settings: 'cv11' on;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
  }

  .anyjob-profile-page-hero-short-bio {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    display: flex;
  }
  
  .anyjob-profile-page-edit-cover-image{
    width: 40px;
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 24px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 30px;
  }

  .anyjob-profile-page-about-me-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-about-me-section-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  .anyjob-profile-page-about-me-section-edit-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .anyjob-profile-page-about-me-section-bio {
    color: var(--Grey1, #767676);    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 30px;
  }

  .anyjob-profile-page-experience-item-container {
    display: flex;
    margin-top: 24px;
  }

  .anyjob-profile-page-experience-item-icon {
    width: 72px;
    height: 72px;
  }

  .anyjob-profile-page-experience-item-contents {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 10px;
    width: 100%;
  }

  .anyjob-profile-page-experience-item-contents-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-profile-page-experience-item-role {
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 28.8px */
  }

  .anyjob-profile-page-experience-item-details-01 {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .anyjob-profile-page-experience-item-details-01-divider {
    width: 4px;
    height: 4px;
    margin: 0px 8px;
    background-color: #A8ADB7;
  }

  .anyjob-profile-page-experience-item-details-01-company-name {
    color: var(--Black-1, #1A1A1A);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-01-desc {
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-location {
    color: var(--Grey1, #767676);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-details-desc {
    color: var(--Black-1, #1A1A1A);
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  .anyjob-profile-page-experience-item-divider {
    width: 100%;
    height: 1px;
    background-color: #d8d8d8;
    margin-top: 24px;
  }

  .anyjob-profile-page-show-more-btn {
    align-self: center;
    cursor: pointer;
    color: var(--Secondary-Color, #FF5851);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; 
    margin: 20px 0px;
  }

  .anyjob-profile-page-truncatable-text {
    color: #828282; 
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-top: 10px;
  }

  .anyjob-profile-page-truncated-text {
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-top: 10px;
  }

  .anyjob-profile-page-truncate-control {
    margin-top: 10px;
    color: var(--Secondary-Color, #FF5851);
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    cursor: pointer;
  }

  .anyjob-profile-page-skills-actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .anyjob-profile-page-skills-cancel-changes {
    color: var(--Black-1, #2d2d2d);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    cursor: pointer;
  }

  .anyjob-profile-page-skills-save-changes {
    height: 40px;
    border-radius: 40px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFC839;
    font-weight: 600;
    font-size: 14px;
  }

  .anyjob-profile-page-skills-editing-section {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .anyjob-profile-page-skills-editing-section-input-field {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #c7c7c7;
    font-size: 14px;
    padding: 8px;
  }

  .anyjob-profile-page-skills-suggestions-container {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    z-index: 9;
    padding: 10px;
    width: 250px;
    position: absolute;
    margin-top: 46px;
  }

  .anyjob-profile-page-skills-suggestion {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
  }
  
  .anyjob-profile-page-selected-skills {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }
  
  .anyjob-profile-page-selected-skill {
    display: flex;
    padding: 8px 15px;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    margin-right: 15px;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .anyjob-profile-page-selected-skill p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.16px;
  }

  .anyjob-profile-page-current-skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-profile-page-current-skill {
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 15px;
    margin-top: 10px;
    color: var(--Black-1, #1A1A1A);    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }
  

  .anyjob-profile-page-socials-section {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--neutrals-0, #FFF);
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  
  .anyjob-profile-page-socials-item {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
  }
  
  .anyjob-profile-page-socials-item img {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-profile-page-socials-item-details {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-profile-page-socials-item-handles {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-wrap: wrap;
    word-wrap: break-word;
  }
  
  .anyjob-profile-page-socials-item-label {
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }
}


.anyjob-profile-logout-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.anyjob-profile-logout-icon img {
  margin-right: 10px;
}


.anyjob-profile-page-verified-icon {
  width: 24px;
}

.anyjob-profile-page-verify-btn {
  border-radius: 30px;
  border: 1px solid #FF5851;
  background: #FFD5D3;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;  
  gap: 10px;
  cursor: pointer;
  width: 200px;
  height: 50px;
}

.anyjob-profile-page-verify-btn p {  
  color: #FF5851;
  font-size: 14px;
  font-weight: 600;
}
