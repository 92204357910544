.anyjob-sidebar-logo-close-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



@media screen and (min-width: 720px) {
  .anyjob-sidebar-container {
    width: 263px;
    background-color: #FFF;
    border-right: 1px solid #e2e2e2;
    display: flex;
    flex-direction: column;
    height: 100vh;
    
  }

  .anyjob-sidebar-close-btn {
    width: 24px;
    height: 24px;
    display: none;
  }
  
  .anyjob-sidebar-logo {
    margin: 32px;
    width: 100px;
  }
  
  .anyjob-sidebar-nav-section {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  
  .anyjob-sidebar-nav-section-title {
    color: var(--neutral-gray-2-bdbdbd, #BDBDBD);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.24px;
    text-transform: uppercase;
    margin-left: 32px;
    margin-bottom: 8px;
  }
  
  .anyjob-sidebar-link-active {
    margin-top: 8px;
    display: flex;
    background-color: #FEFAEB;
    height: 36px;
    align-items: center;
    color: var(--Black-1, #1A1A1A);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.14px;
  }
  
  .anyjob-sidebar-link-active div {
    background-color: #F7CE38;
    width: 4px;
    height: 28px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 28px;
  }
  
  .anyjob-sidebar-link-active p {
    margin-left: 16px;
  }
  
  .anyjob-sidebar-link p {
    margin-left: 16px;
  }
  
  .anyjob-sidebar-link div {
    display: none;
  }
  
  .anyjob-sidebar-link {
    display: flex;
    height: 36px;
    align-items: center;
    margin-top: 8px;
    padding: 0px 32px;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.14px;
  }

  .anyjob-sidebar-profile-section {
    display: flex;
    align-items: center;
    padding-left: 32px;
    cursor: pointer;
  }

  .anyjob-sidebar-profile-picture {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    box-shadow: 2px 2px 7px 0px rgba(75, 75, 75, 0.07);
  }

  .anyjob-sidebar-profile-user-data {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 40px;
  }

  .anyjob-sidebar-profile-full-name {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);    
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
  }

  .anyjob-sidebar-profile-is-freelancer {
    color: var(--neutral-gray-1828282, #828282);
    margin-top: 4px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .anyjob-sidebar-profile-default-icon-container {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #FFF;
    border: 1px solid #c2c2c2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .anyjob-sidebar-profile-default-icon {
    width: 16px;
    height: 16px;
  }
  
}

@media screen and (max-width: 719px) {
  .anyjob-sidebar-container {
    width: 263px;
    background-color: #FFF;
    border-right: 1px solid #e2e2e2;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -263px;
    transition: right 0.3s ease; /* Use transition for smooth animation */
  }

  .anyjob-sidebar-close-btn {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }

  .anyjob-sidebar-container.anyjob-sidebar-open {
    left: 0;
  }
  
  .anyjob-sidebar-logo {
    margin: 32px;
    width: 100px;
  }
  
  .anyjob-sidebar-nav-section {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  
  .anyjob-sidebar-nav-section-title {
    color: var(--neutral-gray-2-bdbdbd, #BDBDBD);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.24px;
    text-transform: uppercase;
    margin-left: 32px;
    margin-bottom: 8px;
  }
  
  .anyjob-sidebar-link-active {
    margin-top: 8px;
    display: flex;
    background-color: #FEFAEB;
    height: 36px;
    align-items: center;
    color: var(--Black-1, #1A1A1A);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.14px;
  }
  
  .anyjob-sidebar-link-active div {
    background-color: #F7CE38;
    width: 4px;
    height: 28px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 28px;
  }
  
  .anyjob-sidebar-link-active p {
    margin-left: 16px;
  }
  
  .anyjob-sidebar-link p {
    margin-left: 16px;
  }
  
  .anyjob-sidebar-link div {
    display: none;
  }
  
  .anyjob-sidebar-link {
    display: flex;
    height: 36px;
    align-items: center;
    margin-top: 8px;
    padding: 0px 32px;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.14px;
  }

  .anyjob-sidebar-profile-section {
    display: flex;
    align-items: center;
    padding-left: 32px;    
    cursor: pointer;
  }

  .anyjob-sidebar-profile-picture {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    box-shadow: 2px 2px 7px 0px rgba(75, 75, 75, 0.07);
  }

  .anyjob-sidebar-profile-user-data {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 40px;
  }

  .anyjob-sidebar-profile-full-name {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);    
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
  }

  .anyjob-sidebar-profile-is-freelancer {
    color: var(--neutral-gray-1828282, #828282);
    margin-top: 4px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .anyjob-sidebar-profile-default-icon-container {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #FFF;
    border: 1px solid #c2c2c2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .anyjob-sidebar-profile-default-icon {
    width: 16px;
    height: 16px;
  }
  
}

.anyjob-sidebar-bottom-contents {
  position: absolute;
  bottom: 20px;
}

.anyjob-switch-mode {
  color: #1A1A1A;
  text-decoration: underline;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  padding-left: 32px;    
  margin-top: 10px;
}