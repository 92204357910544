.anyjob-avatar-container {
  background-color: #FFFBEC;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.anyjob-avatar-img {
  
}

.anyjob-xs {
  width: 28px;
  height: 28px;
  border-radius: 28px;
}

.anyjob-s {
  width: 36px;
  height: 36px;
  border-radius: 36px;
}

.anyjob-m {
  width: 42px;
  height: 42px;
  border-radius: 42px;
}

.anyjob-l {
  width: 56px;
  height: 56px;
  border-radius: 56px;
}

.anyjob-xl {
  width: 72px;
  height: 72px;
  border-radius: 72px;
}

.anyjob-avatar-user-icon {
  width: 20px;
}