
/*  */

.anyjob-edit-payment-acct-no-group {
  border-radius: 32px;
  width: 100%;
  height: 50px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-edit-payment-acct-no-group-focused {
  border-radius: 32px;
  width: 100%;
  height: 50px;
  border: 1px solid #FF5851;
  background: #FFF;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anyjob-edit-payment-acct-no-field {
  flex: 1;
  height: 46px;
  padding: 20px;
  margin: 2px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.anyjob-edit-payment-acct-no-group-verify-btn {
  display: flex;
  width: 200px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #FF5851);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.anyjob-edit-payment-acct-no-group-verified-btn {
  display: flex;
  width: 200px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Secondary-Color, #40D47F);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.anyjob-edit-payment-account-name {
  width: 100%;
  height: 45px;
  background: #C6FFBE;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0px 32px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 600;
  color: #1A1A1A;
}

.anyjob-edit-payment-form-submit-btn-disabled {
  border-radius: 100px;
  background: var(--Primary-Color, #BDBDBD);
  display: flex;
  width: 440px;
  height: 60px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  margin: 70px 0px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.anyjob-edit-profile-details-modal-form-actions-save-disabled {
  border-radius: 100px;
  background: var(--Primary-Color, #BDBDBD);
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  width: 250px;
  height: 50px;
  margin-left: 30px;
}