


@media screen and (min-width: 769px) {
  .anyjob-add-item-card-btn {
    border-radius: 16px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF; 
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .anyjob-add-item-card-btn-2 {
    border-radius: 16px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF; 
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .anyjob-add-item-card-btn p {
    color: #888;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }
  
  .anyjob-add-item-card-btn span {
    background-color: #FF5851;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    
  }
  
  .anyjob-add-item-card-btn-2 span {
    background-color: #FF5851;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin: 0px 20px;
    cursor: pointer;
  }
  
  .anyjob-add-item-card-btn-icon {
    width: 32px;
    height: 32px;
    color: #FFF;
  }
}

@media screen and (max-width: 768px) {
  .anyjob-add-item-card-btn {
    border-radius: 16px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF; 
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .anyjob-add-item-card-btn-2 {
    border-radius: 16px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF; 
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
  }
  
  .anyjob-add-item-card-btn p {
    color: #888;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }
  
  .anyjob-add-item-card-btn span {
    background-color: #FF5851;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    
  }
  
  .anyjob-add-item-card-btn-2 span {
    background-color: #FF5851;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin: 0px 20px;
    cursor: pointer;
  }
  
  .anyjob-add-item-card-btn-icon {
    width: 32px;
    height: 32px;
    color: #FFF;
  }
}

