

@media screen and (min-width: 1200px) {
  .anyjob-job-details-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-job-details-page-content {
    display: flex;
    overflow: hidden;
    /* height: 60%; */
  }

  .anyjob-job-details-page-content-details{
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e2e2e2;
    flex: 1;
    /* height: 100vh; */
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-job-details-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-job-details-page-content-heading {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .anyjob-job-details-page-content-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
  } 

  .anyjob-job-details-page-subheading {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .anyjob-job-details-page-subheading span {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    margin: 0px 10px;
    background-color: #767676;
  }

  .anyjob-job-details-page-subheading p {
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }

  .anyjob-jobs-details-page-job-description {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin: 50px 40px;
    padding-bottom: 100px;
  }

  .anyjob-job-details-page-location-type-n-pay {
    display: flex;
    align-items: center;
  }

  .anyjob-job-details-page-location-type-n-pay span {
    width: 1px;
    height: 34px;
    background-color: #e2e2e2;
    margin: 0px 20px;
  }

  .anyjob-job-details-page-location-type {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #FFEFEE;
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-job-details-page-compensation {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-job-details-page-content-meta {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* height: 100vh; */
    overflow: scroll;
    overflow-x: hidden;
    padding: 32px 0px;
  }

  .anyjob-job-details-page-cta {
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 32px;
    margin-right: 32px;
    border-radius: 50px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    justify-content: center;
    width: 300px;
    padding: 17px 50px;
    align-items: flex-start;
    gap: 10px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }

  .anyjob-job-details-page-meta-data-sub {
    padding: 24px 32px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .anyjob-job-details-page-meta-data-sub-2 {
    padding: 24px 32px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
  }

  .anyjob-job-details-page-meta-data-sub-title {
    color: #1A1A1A;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }

  .anyjob-job-details-page-meta-data-sub-desc {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-top: 12px;
  }

  .anyjob-job-details-page-meta-data-sub-skill {
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; 
    letter-spacing: 0.32px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 12px 24px;
    align-items: center;
    margin-top: 12px;
  }

}

@media screen and (min-width: 1050px) and (max-width: 1199px) {
  .anyjob-job-details-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-job-details-page-content {
    display: flex;
    overflow: hidden;
  }

  .anyjob-job-details-page-content-details{
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e2e2e2;
    flex: 1;
    /* height: 100vh; */
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-job-details-page-content-hero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-job-details-page-content-heading {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .anyjob-job-details-page-content-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
  } 

  .anyjob-job-details-page-subheading {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .anyjob-job-details-page-subheading span {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    margin: 0px 10px;
    background-color: #767676;
  }

  .anyjob-job-details-page-subheading p {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }


  .anyjob-jobs-details-page-job-description {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin: 50px 40px;
    padding-bottom: 100px;
  }

  .anyjob-job-details-page-location-type-n-pay {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }

  .anyjob-job-details-page-location-type-n-pay span {
    width: 1px;
    height: 34px;
    background-color: #e2e2e2;
    margin: 0px 20px;
  }

  .anyjob-job-details-page-location-type {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #FFEFEE;
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-job-details-page-compensation {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-job-details-page-content-meta {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: scroll;
    overflow-x: hidden;
    padding: 32px 0px;
    /* height: 100vh; */
  }

  .anyjob-job-details-page-cta {
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 32px;
    margin-right: 32px;
    border-radius: 50px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    justify-content: center;
    width: 300px;
    padding: 17px 50px;
    align-items: flex-start;
    gap: 10px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }

  .anyjob-job-details-page-meta-data-sub {
    padding: 24px 32px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .anyjob-job-details-page-meta-data-sub-2 {
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .anyjob-job-details-page-meta-data-sub-title {
    color: #1A1A1A;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }

  .anyjob-job-details-page-meta-data-sub-desc {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-top: 12px;
  }

  .anyjob-job-details-page-meta-data-sub-skill {
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; 
    letter-spacing: 0.32px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 12px 24px;
    align-items: center;
    margin-top: 12px;
  }

}

@media screen and (max-width: 1049px) {
  .anyjob-job-details-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-job-details-page-content {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-job-details-page-content-details{
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e2e2e2;
    
  }

  .anyjob-job-details-page-content-hero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-job-details-page-content-heading {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .anyjob-job-details-page-content-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
  } 

  .anyjob-job-details-page-subheading {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .anyjob-job-details-page-subheading span {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    margin: 0px 10px;
    background-color: #767676;
  }

  .anyjob-job-details-page-subheading p {
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }


  .anyjob-jobs-details-page-job-description {
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin: 30px 40px;
    padding-bottom: 20px;
  }

  .anyjob-job-details-page-location-type-n-pay {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }

  .anyjob-job-details-page-location-type-n-pay span {
    width: 1px;
    height: 34px;
    background-color: #e2e2e2;
    margin: 0px 20px;
  }

  .anyjob-job-details-page-location-type {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #FFEFEE;
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-job-details-page-compensation {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }

  .anyjob-job-details-page-content-meta {
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 0px;
  }

  .anyjob-job-details-page-cta {
    margin-bottom: 50px;
    margin-left: 32px;
    margin-right: 32px;
    border-radius: 50px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    justify-content: center;
    width: 300px;
    padding: 17px 50px;
    align-items: flex-start;
    gap: 10px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }

  .anyjob-job-details-page-meta-data-sub {
    padding: 24px 32px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .anyjob-job-details-page-meta-data-sub-2 {
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
  }

  .anyjob-job-details-page-meta-data-sub-title {
    color: #1A1A1A;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }

  .anyjob-job-details-page-meta-data-sub-desc {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-top: 12px;
  }

  .anyjob-job-details-page-meta-data-sub-skill {
    color: var(--Black-1, #1A1A1A);    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; 
    letter-spacing: 0.32px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    display: flex;
    padding: 12px 24px;
    align-items: center;
    margin-top: 12px;
  }

}
