@media screen and (min-width: 1420px) {
  .anyjob-contact-us-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-contact-us-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  }
  
  .anyjob-contact-us-title {
    text-align: center;
    color: var(--Black, #2D2D2D);
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 90px */
  }
  
  .anyjob-contact-us-sub-title {
    color: var(--Grey-1, #555);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    margin-top: 16px;
  }
  
  .anyjob-contact-us-form-and-contact-info {
    display: flex;
    align-items: center;
    margin: 100px;
  }
  
  .anyjob-contact-us-contact-info-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-right: 120px;
    flex: 1;
  }
  
  .anyjob-contact-us-contact-item {
    display: flex;
    align-items: center;
    border-radius: 102.68px;
    border: 1.027px solid var(--Grey-3, #E0E0E0);
    background: var(--White, #FFF);
    box-shadow: 0px 2.05361px 10.26804px 0px rgba(78, 78, 78, 0.05);
    padding: 24px;
    width: 530px;
  }
  
  .anyjob-contact-us-contact-item p {
    color: var(--Black, #2D2D2D);  
    font-size: 18.482px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27.724px */
    margin-left: 24px;
  }
  
  .anyjob-contact-us-contact-item a {
    color: var(--Black, #2D2D2D);  
    font-size: 18.482px;
    font-style: normal;
    font-weight: 700;
    margin-left: 10px;
  }
  .anyjob-contact-us-contact-item img {
    width: 48px;
    height: 48px;
  }
  
  
  
  .anyjob-contact-us-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 16px;
    background: #FDF5D7;
    padding: 50px 100px;
  }
  
  .anyjob-contact-us-form-title {
    color: var(--color-grey-100, #1A1A1A);
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 123.81% */
    letter-spacing: -0.758px;
  }
  
  .anyjob-contact-us-form-sub-title {
    color: var(--color-grey-60, #767676);
    font-feature-settings: 'clig' off, 'liga' off;
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.289px;
    margin-bottom: 32px;
  }
  
  .anyjob-contact-us-form-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  
  }
  
  .anyjob-contact-us-form-input-label {
    color: var(--Grey-1, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-contact-us-form-input-field {
    padding: 0px 24px;
    border-radius: 50px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 12px;
    display: flex;
    align-items: center;
    height: 60px;
  }
  
  .anyjob-contact-us-form-textarea-field {
    padding: 20px 24px;
    border-radius: 25px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 12px;
    display: flex;
    align-items: center;
    min-height: 190px;
  }
  
  .anyjob-contact-us-form-submit-btn {
    border-radius: 100px;
    background: #F7CE38;
    box-shadow: 0px 10px 20px 0px rgba(170, 53, 197, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 60px;
    color: #1A1A1A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 32px;
    cursor: pointer;
  }
  
  .anyjob-contact-us-form-submit-btn:hover {
    box-shadow: 0px 0px 0px 0px rgba(170, 53, 197, 0.10);
    background: #e6c035;
  }
}
@media screen and (min-width: 1020px) and (max-width: 1419px) {
  .anyjob-contact-us-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-contact-us-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  }
  
  .anyjob-contact-us-title {
    text-align: center;
    color: var(--Black, #2D2D2D);
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 90px */
  }
  
  .anyjob-contact-us-sub-title {
    color: var(--Grey-1, #555);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    margin-top: 16px;
  }
  
  .anyjob-contact-us-form-and-contact-info {
    display: flex;
    align-items: center;
    margin: 100px 50px;
  }
  
  .anyjob-contact-us-contact-info-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-right: 20px;
    flex: 1;
  }
  
  .anyjob-contact-us-contact-item {
    display: flex;
    align-items: center;
    border-radius: 102.68px;
    border: 1.027px solid var(--Grey-3, #E0E0E0);
    background: var(--White, #FFF);
    box-shadow: 0px 2.05361px 10.26804px 0px rgba(78, 78, 78, 0.05);
    padding: 24px;
    width: 470px;
  }
  
  .anyjob-contact-us-contact-item p {
    color: var(--Black, #2D2D2D);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27.724px */
    margin-left: 24px;
  }
  
  .anyjob-contact-us-contact-item a {
    color: var(--Black, #2D2D2D);  
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-left: 10px;
  }
  .anyjob-contact-us-contact-item img {
    width: 42px;
    height: 42px;
  }
  
  
  
  .anyjob-contact-us-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 16px;
    background: #FDF5D7;
    padding: 50px;
  }
  
  .anyjob-contact-us-form-title {
    color: var(--color-grey-100, #1A1A1A);
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 123.81% */
    letter-spacing: -0.758px;
  }
  
  .anyjob-contact-us-form-sub-title {
    color: var(--color-grey-60, #767676);
    font-feature-settings: 'clig' off, 'liga' off;
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.289px;
    margin-bottom: 32px;
  }
  
  .anyjob-contact-us-form-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  
  }
  
  .anyjob-contact-us-form-input-label {
    color: var(--Grey-1, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-contact-us-form-input-field {
    padding: 0px 24px;
    border-radius: 50px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 12px;
    display: flex;
    align-items: center;
    height: 60px;
  }
  
  .anyjob-contact-us-form-textarea-field {
    padding: 20px 24px;
    border-radius: 25px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 12px;
    display: flex;
    align-items: center;
    min-height: 190px;
  }
  
  .anyjob-contact-us-form-submit-btn {
    border-radius: 100px;
    background: #F7CE38;
    box-shadow: 0px 10px 20px 0px rgba(170, 53, 197, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 60px;
    color: #1A1A1A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 32px;
    cursor: pointer;
  }
  
  .anyjob-contact-us-form-submit-btn:hover {
    box-shadow: 0px 0px 0px 0px rgba(170, 53, 197, 0.10);
    background: #e6c035;
  }
}

@media screen and (min-width: 925px) and (max-width: 1019px) {
  .anyjob-contact-us-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-contact-us-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  
  .anyjob-contact-us-title {
    text-align: center;
    color: var(--Black, #2D2D2D);
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 90px */
  }
  
  .anyjob-contact-us-sub-title {
    color: var(--Grey-1, #555);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    margin-top: 16px;
  }
  
  .anyjob-contact-us-form-and-contact-info {
    display: flex;
    align-items: center;
    margin: 50px;
  }
  
  .anyjob-contact-us-contact-info-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-right: 20px;
    flex: 1;
  }
  
  .anyjob-contact-us-contact-item {
    display: flex;
    align-items: center;
    border-radius: 102.68px;
    border: 1.027px solid var(--Grey-3, #E0E0E0);
    background: var(--White, #FFF);
    box-shadow: 0px 2.05361px 10.26804px 0px rgba(78, 78, 78, 0.05);
    padding: 16px;
    width: 350px;
  }
  
  .anyjob-contact-us-contact-item p {
    color: var(--Black, #2D2D2D);  
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27.724px */
    margin-left: 16px;
  }
  
  .anyjob-contact-us-contact-item a {
    color: var(--Black, #2D2D2D);  
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin-left: 6px;
  }
  .anyjob-contact-us-contact-item img {
    width: 36px;
    height: 36px;
  }
  
  
  
  .anyjob-contact-us-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 16px;
    background: #FDF5D7;
    padding: 50px 30px;
  }
  
  .anyjob-contact-us-form-title {
    color: var(--color-grey-100, #1A1A1A);
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 123.81% */
    letter-spacing: -0.758px;
  }
  
  .anyjob-contact-us-form-sub-title {
    color: var(--color-grey-60, #767676);
    font-feature-settings: 'clig' off, 'liga' off;
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.289px;
    margin-bottom: 32px;
  }
  
  .anyjob-contact-us-form-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  
  }
  
  .anyjob-contact-us-form-input-label {
    color: var(--Grey-1, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-contact-us-form-input-field {
    padding: 0px 24px;
    border-radius: 50px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 12px;
    display: flex;
    align-items: center;
    height: 60px;
  }
  
  .anyjob-contact-us-form-textarea-field {
    padding: 20px 24px;
    border-radius: 25px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 12px;
    display: flex;
    align-items: center;
    min-height: 190px;
  }
  
  .anyjob-contact-us-form-submit-btn {
    border-radius: 100px;
    background: #F7CE38;
    box-shadow: 0px 10px 20px 0px rgba(170, 53, 197, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 60px;
    color: #1A1A1A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 32px;
    cursor: pointer;
  }
  
  .anyjob-contact-us-form-submit-btn:hover {
    box-shadow: 0px 0px 0px 0px rgba(170, 53, 197, 0.10);
    background: #e6c035;
  }
}

@media screen and (min-width: 481px) and (max-width: 924px) {
  .anyjob-contact-us-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-contact-us-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
  }
  
  .anyjob-contact-us-title {
    text-align: center;
    color: var(--Black, #2D2D2D);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 90px */
  }
  
  .anyjob-contact-us-sub-title {
    color: var(--Grey-1, #555);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    margin-top: 10px;
  }
  
  .anyjob-contact-us-form-and-contact-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* margin: 50px; */
  }
  
  .anyjob-contact-us-contact-info-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 70px;
  }
  
  .anyjob-contact-us-contact-item {
    display: flex;
    align-items: center;
    border-radius: 102.68px;
    border: 1.027px solid var(--Grey-3, #E0E0E0);
    background: var(--White, #FFF);
    box-shadow: 0px 2.05361px 10.26804px 0px rgba(78, 78, 78, 0.05);
    padding: 16px;
    width: 400px;
  }
  
  .anyjob-contact-us-contact-item p {
    color: var(--Black, #2D2D2D);  
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27.724px */
    margin-left: 16px;
  }
  
  .anyjob-contact-us-contact-item a {
    color: var(--Black, #2D2D2D);  
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin-left: 6px;
  }
  .anyjob-contact-us-contact-item img {
    width: 36px;
    height: 36px;
  }
  
  
  
  .anyjob-contact-us-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FDF5D7;
    padding: 100px 30px;
    margin-top: 50px;
  }
  
  .anyjob-contact-us-form-title {
    color: var(--color-grey-100, #1A1A1A);
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 123.81% */
    letter-spacing: -0.758px;
  }
  
  .anyjob-contact-us-form-sub-title {
    color: var(--color-grey-60, #767676);
    font-feature-settings: 'clig' off, 'liga' off;
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.289px;
    margin-bottom: 32px;
  }
  
  .anyjob-contact-us-form-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  
  }
  
  .anyjob-contact-us-form-input-label {
    color: var(--Grey-1, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-contact-us-form-input-field {
    padding: 0px 24px;
    border-radius: 50px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 12px;
    display: flex;
    align-items: center;
    height: 60px;
  }
  
  .anyjob-contact-us-form-textarea-field {
    padding: 20px 24px;
    border-radius: 25px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 12px;
    display: flex;
    align-items: center;
    min-height: 190px;
  }
  
  .anyjob-contact-us-form-submit-btn {
    border-radius: 100px;
    background: #F7CE38;
    box-shadow: 0px 10px 20px 0px rgba(170, 53, 197, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 60px;
    color: #1A1A1A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 32px;
    cursor: pointer;
  }
  
  .anyjob-contact-us-form-submit-btn:hover {
    box-shadow: 0px 0px 0px 0px rgba(170, 53, 197, 0.10);
    background: #e6c035;
  }
}

@media screen and (max-width: 480px) {
  .anyjob-contact-us-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-contact-us-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 70px 20px;
  }
  
  .anyjob-contact-us-title {
    text-align: center;
    color: var(--Black, #2D2D2D);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 90px */
  }
  
  .anyjob-contact-us-sub-title {
    color: var(--Grey-1, #555);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    margin-top: 10px;
  }
  
  .anyjob-contact-us-form-and-contact-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: -100px;
    /* margin: 50px; */
  }
  
  .anyjob-contact-us-contact-info-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0px 20px;
  }
  
  .anyjob-contact-us-contact-item {
    display: flex;
    align-items: center;
    border-radius: 102.68px;
    border: 1.027px solid var(--Grey-3, #E0E0E0);
    background: var(--White, #FFF);
    box-shadow: 0px 2.05361px 10.26804px 0px rgba(78, 78, 78, 0.05);
    padding: 10px;
    width: 100%;
  }
  
  .anyjob-contact-us-contact-item p {
    color: var(--Black, #2D2D2D);  
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27.724px */
    margin-left: 16px;
  }
  
  .anyjob-contact-us-contact-item a {
    color: var(--Black, #2D2D2D);  
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
  .anyjob-contact-us-contact-item img {
    width: 36px;
    height: 36px;
  }
  
  
  
  .anyjob-contact-us-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FDF5D7;
    padding: 50px 20px;
    margin-top: 50px;
  }
  
  .anyjob-contact-us-form-title {
    color: var(--color-grey-100, #1A1A1A);
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 123.81% */
    letter-spacing: -0.758px;
  }
  
  .anyjob-contact-us-form-sub-title {
    color: var(--color-grey-60, #767676);
    font-feature-settings: 'clig' off, 'liga' off;
    margin-top: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.289px;
    margin-bottom: 32px;
  }
  
  .anyjob-contact-us-form-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  
  }
  
  .anyjob-contact-us-form-input-label {
    color: var(--Grey-1, #555);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-contact-us-form-input-field {
    padding: 0px 16px;
    border-radius: 50px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 10px;
    display: flex;
    align-items: center;
    height: 60px;
  }
  
  .anyjob-contact-us-form-textarea-field {
    padding: 16px;
    border-radius: 25px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin-top: 10px;
    display: flex;
    align-items: center;
    min-height: 190px;
  }
  
  .anyjob-contact-us-form-submit-btn {
    border-radius: 100px;
    background: #F7CE38;
    box-shadow: 0px 10px 20px 0px rgba(170, 53, 197, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 60px;
    color: #1A1A1A;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 32px;
    cursor: pointer;
  }
  
  .anyjob-contact-us-form-submit-btn:hover {
    box-shadow: 0px 0px 0px 0px rgba(170, 53, 197, 0.10);
    background: #e6c035;
  }
}

.anyjob-contact-page-err-msg {
  font-size: 13px;
  margin-top: 6px;
  color: #FF5851;
}