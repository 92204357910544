.anyjob-message-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.anyjob-message-page-content-container {
  display: flex;
  flex-direction: row;
  padding: 40px;
  height: 100%;
}

.anyjob-message-page-nav-panel-container {
  width: 300px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.anyjob-message-page-nav-panel-search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  padding: 0px 10px;
  height: 55px;
  border-color: #E0E0E0;
  width: 100%;
  /* background-color: aqua; */
}

.anyjob-message-page-nav-panel-search-container input {
  margin-left: 10px;
  outline: 0px;
  height: 55px;
  flex: 1;
}

.anyjob-message-page-nav-panel-blank-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.anyjob-message-page-nav-panel-blank-container p {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.anyjob-message-page-nav-panel-list {
  flex: 1;
  display: flex;
  flex-direction: column;  
}

.anyjob-message-page-nav-panel-item {  
  cursor: pointer;
  padding: 20px;
  display: flex;
  align-items: center;
}

.anyjob-message-panel-item-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.anyjob-message-panel-item-profile-picture-default-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #E9E9E9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anyjob-message-panel-item-details {
  flex: 1;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.anyjob-message-panel-item-details-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.anyjob-message-panel-item-name {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.anyjob-message-panel-item-sub-text {
  color: #828282;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* display: inline-block; */
}

.anyjob-unread-message-container {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: #40D47F;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;  
}

.anyjob-unread-message-container p {
  font-size: 12px;
  color: #fff;
  font-weight: 700;
}

.anyjob-blank-message-container {
  flex: 1;
  border-radius: 10px;
  background-color: #fff;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.anyjob-blank-message-container img {
  width: 260px;
  margin-bottom: 40px;
}

.anyjob-blank-message-container p {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-align: center;
}