

@media screen and (min-width: 850px) {
  .anyjob-jobs-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-jobs-page-search-container {
    border-radius: 16px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    display: flex;
    padding: 24px 48px;
    justify-content: center;
    align-items: center;
    margin: 30px 40px;
  }

  .anyjob-jobs-page-search-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
  }
  
  .anyjob-jobs-page-search-icon {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-jobs-page-search-input {
    flex: 1;
    margin: 0px 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--Gray-5, #E0E0E0);
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    outline: none;
    box-shadow: none;
  }
  
  .anyjob-jobs-page-search-btn {
    display: flex;
    padding: 0px 35px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 50px;
    background: var(--Primary-Color, #F7CE38);
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  
  .anyjob-jobs-page-job-list {
    width: 100%;
    flex: 1;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    border-top: 1px solid #e2e2e2;
    padding: 40px;
  }
  
  .anyjob-jobs-page-job-item-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    border: 1.297px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .anyjob-jobs-page-job-item-data-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-jobs-page-job-item-title-n-amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-jobs-page-job-item-details-n-cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .anyjob-jobs-page-job-item-title {
    color: var(--Black-1, #1A1A1A);  
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  
  .anyjob-jobs-page-job-item-amt {
    color: var(--Black-1, #1A1A1A);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  
  .anyjob-jobs-page-job-item-client-location {
    display: flex;
    align-items: center;
  }
  
  .anyjob-jobs-page-job-item-sub-title {
    color: var(--Grey1, #767676);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.642px; /* 102.673% */
    letter-spacing: 0.48px;
  }
  
  .anyjob-jobs-page-job-item-client-location span {
    background-color: #767676;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin: 0px 20px;
  }
  

  
  .anyjob-jobs-page-job-item-location-type {
    border-radius: 31.126px;
    background: #FFEFEE;
    display: flex;
    padding: 12px 28px;
    justify-content: center;
    align-items: center;
    gap: 12.969px;
    color: var(--Black-1, #1A1A1A);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.642px; /* 123.208% */
    letter-spacing: 0.4px;
  }
  
  .anyjob-jobs-page-job-item-job-description {
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-jobs-page-job-item-apply-btn {
    border-radius: 45px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    gap: 12.969px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    height: 45px;
    width: 160px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .anyjob-pagination-controls-container {
    display: flex;
    align-self: flex-end;
    margin: 40px;
  
  }
  
  .anyjob-pagination-controls-container p  {
    margin-right: 10px;
    color: var(--neutral-gray-1828282, #828282);
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
  }
  
  .anyjob-pagination-controls-btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .anyjob-jobs-page-job-item-apply-btn-2 {
    display: none;
  }
}

@media screen and (min-width: 720px)  and (max-width: 849px)  {
  .anyjob-jobs-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-jobs-page-search-container {
    border-radius: 16px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    margin: 30px 30px;
  }

  .anyjob-jobs-page-search-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
  }
  
  .anyjob-jobs-page-search-icon {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-jobs-page-search-input {
    flex: 1;
    margin: 0px 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--Gray-5, #E0E0E0);
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    outline: none;
    box-shadow: none;
  }
  
  .anyjob-jobs-page-search-btn {
    display: flex;
    padding: 10px 30px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 50px;
    background: var(--Primary-Color, #F7CE38);
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  
  .anyjob-jobs-page-job-list {
    width: 100%;
    flex: 1;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    border-top: 1px solid #e2e2e2;
    padding: 30px;
  }
  
  .anyjob-jobs-page-job-item-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* justify-content: center;
    align-items: center; */
    border-radius: 12px;
    border: 1.297px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .anyjob-jobs-page-job-item-data-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }


  .anyjob-jobs-page-job-item-title-n-amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-jobs-page-job-item-details-n-cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .anyjob-jobs-page-job-item-title {
    color: var(--Black-1, #1A1A1A);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  .anyjob-jobs-page-job-item-amt {
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  
  .anyjob-jobs-page-job-item-client-location {
    display: flex;
    align-items: center;
  }
  
  .anyjob-jobs-page-job-item-sub-title {
    color: var(--Grey1, #767676);  
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.642px; /* 102.673% */
    letter-spacing: 0.48px;
  }
  
  .anyjob-jobs-page-job-item-client-location span {
    background-color: #767676;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin: 0px 20px;
  }
  
  
  
  .anyjob-jobs-page-job-item-job-description {
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-jobs-page-job-item-apply-btn {
    border-radius: 45px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    gap: 12.969px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    height: 45px;
    width: 160px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .anyjob-pagination-controls-container {
    display: flex;
    align-self: flex-end;
    margin: 40px;
  
  }
  
  .anyjob-pagination-controls-container p  {
    margin-right: 10px;
  }
  
  .anyjob-pagination-controls-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .anyjob-jobs-page-job-item-apply-btn-2 {
    display: none;
  }
}

@media screen and (min-width: 520px) and (max-width: 719px)  {
  .anyjob-jobs-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-jobs-page-search-container {
    border-radius: 16px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    display: flex;
    padding: 18px;
    justify-content: center;
    align-items: center;
    margin: 30px 30px;
  }

  .anyjob-jobs-page-search-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
  }
  
  .anyjob-jobs-page-search-icon {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-jobs-page-search-input {
    flex: 1;
    margin: 0px 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--Gray-5, #E0E0E0);
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    outline: none;
    box-shadow: none;
  }
  
  .anyjob-jobs-page-search-btn {
    display: flex;
    padding: 10px 30px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 50px;
    background: var(--Primary-Color, #F7CE38);
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  
  .anyjob-jobs-page-job-list {
    width: 100%;
    flex: 1;
    /* position: relative; */
    overflow: scroll;
    overflow-x: hidden;
    border-top: 1px solid #e2e2e2;
    padding: 30px;
  }
  
  .anyjob-jobs-page-job-item-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* justify-content: center;
    align-items: center; */
    border-radius: 12px;
    border: 1.297px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    cursor: pointer;
    margin-bottom: 20px;
  }
  


  .anyjob-jobs-page-job-item-title-n-amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .anyjob-jobs-page-job-item-details-n-cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .anyjob-jobs-page-job-item-title {
    color: var(--Black-1, #1A1A1A);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.642px; /* 77.005% */
    letter-spacing: 0.64px;
  }

  .anyjob-jobs-page-job-item-amt {
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  
  .anyjob-jobs-page-job-item-client-location {
    display: flex;
    align-items: center;
  }
  
  .anyjob-jobs-page-job-item-sub-title {
    color: var(--Grey1, #767676);  
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.642px; /* 102.673% */
    letter-spacing: 0.48px;
  }
  
  .anyjob-jobs-page-job-item-client-location span {
    background-color: #767676;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin: 0px 20px;
  }
  
  .anyjob-jobs-page-job-item-job-description {
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-jobs-page-job-item-apply-btn {
    border-radius: 45px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    gap: 12.969px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    height: 45px;
    width: 160px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .anyjob-pagination-controls-container {
    display: flex;
    align-self: flex-end;
    margin: 40px;
  
  }
  
  .anyjob-pagination-controls-container p  {
    margin-right: 10px;
  }
  
  .anyjob-pagination-controls-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .anyjob-jobs-page-job-item-apply-btn-2 {
    display: none;
  }
}

@media screen and (max-width: 519px)  {
  .anyjob-jobs-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-jobs-page-search-container {
    border-radius: 16px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }

  .anyjob-jobs-page-search-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
  }
  
  .anyjob-jobs-page-search-icon {
    width: 24px;
    height: 24px;
  }
  
  .anyjob-jobs-page-search-input {
    flex: 1;
    margin-left: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--Gray-5, #E0E0E0);
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    outline: none;
    box-shadow: none;    
  }
  
  .anyjob-jobs-page-search-btn {
    display: flex;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    gap: 10px;
    border-radius: 50px;
    background: var(--Primary-Color, #F7CE38);
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  
  .anyjob-jobs-page-job-list {
    width: 100%;
    flex: 1;
    /* position: relative; */
    overflow: scroll;
    overflow-x: hidden;
    border-top: 1px solid #e2e2e2;
    padding: 20px;
  }
  
  .anyjob-jobs-page-job-item-container {
    display: flex;
    flex-direction: column;
    padding: 14px;
    /* justify-content: center;
    align-items: center; */
    border-radius: 12px;
    border: 1.297px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .anyjob-jobs-page-job-item-data-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .anyjob-jobs-page-job-item-title-n-amount {
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-jobs-page-job-item-details-n-cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 6px; */
  }
  
  .anyjob-jobs-page-job-item-title {
    color: var(--Black-1, #1A1A1A);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  .anyjob-jobs-page-job-item-amt {
    color: var(--Black-1, #1A1A1A);  
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .anyjob-jobs-page-job-item-client-location {
    display: flex;
    margin: 16px 0px;
    align-items: center;
  }
  
  .anyjob-jobs-page-job-item-sub-title {
    color: var(--Grey1, #767676);  
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  
  .anyjob-jobs-page-job-item-client-location span {
    background-color: #767676;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin: 0px 20px;
  }
  
  .anyjob-jobs-page-job-item-job-description {
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-jobs-page-job-item-apply-btn {
    display: none;
  }
  
  .anyjob-jobs-page-job-item-apply-btn-2 {
    border-radius: 64.846px;
    background: var(--Primary-Color, #F7CE38);
    display: flex;
    height: 45px;
    justify-content: center;
    align-items: center;
    gap: 12.969px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    justify-content: center;
  }
  
  .anyjob-pagination-controls-container {
    display: flex;
    align-self: flex-end;
    margin: 40px;
  
  }
  
  .anyjob-pagination-controls-container p  {
    margin-right: 10px;
  }
  
  .anyjob-pagination-controls-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.anyjob-jobs-page-job-item-apply-btn {
  cursor: pointer;
}

.anyjob-jobs-page-empty-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.anyjob-jobs-page-empty-list-container p {
  font-size: 16px;  
  margin-top: 20px;
}

.anyjob-jobs-page-empty-list-container img {
  width: 200px;
  margin-top: 100px;
}

.anyjob-jobs-page-post-job {
  display: flex;
  padding: 14px 50px;
  width: 280px;
  align-self: center;
  text-align: center;
  justify-content: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--Primary-Color, #F7CE38);
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 40px;
  cursor: pointer;
}