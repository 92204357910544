.menu-list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}

@media screen and (min-width: 999px) {
  .anyjob-nav-bar-logo {
    width: 95px;
  }

  .anyjob-mobile-nav-bar {
    display: none;
  }
  .anyjob-mobile-nav-bar-menu {
    display: none;
  }
  .anyjob-nav-bar {
    /* width: 100%; */
    background-color: white;
    display: flex;
    min-height: 80px;
    padding: 12px 100px;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    position: sticky;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 10px 20px 0px rgba(22, 22, 22, 0.06);
  }

  .anyjob-nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .anyjob-nav-link-active {
    color: #FF5851;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    padding: 0px 20px;
  }

  .anyjob-nav-link {
    color: #767676;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0px 20px;
  }

  .anyjob-nav-ctas {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 100px;
  }

  .anyjob-nav-login {
    color: #1A1A1A;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }

  .anyjob-nav-signup {
    border-radius: 25px;
    background: #F7CE38;
    display: flex;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    margin-left: 30px;
  }
}


@media screen and (max-width: 998px) {
  .anyjob-nav-bar {
    display: none;
  }

  .anyjob-nav-bar-logo {
    width: 95px;
  }

  .anyjob-mobile-nav-bar {
    display: flex;
    height: 66px;
    background-color: #FFF;
    box-shadow: 0px 10px 20px 0px rgba(22, 22, 22, 0.06);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    position: sticky;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
  }

  .anyjob-mobile-nav-bar-controls {
    display: flex;
  }

  .anyjob-mobile-nav-bar-menu-icon {
    cursor: pointer;
    margin-right: 20px;
    width: 26px;
  }

  .anyjob-mobile-nav-bar-menu-icon-container {
    cursor: pointer;
  }

  .anyjob-nav-signup {
    border-radius: 25px;
    background: #F7CE38;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    margin-left: 30px;
    height: 42px;
    width: 130px;
  }

  .anyjob-mobile-nav-bar-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: #fff;    
    opacity: 1;
    right: 0px;
    top: 0px;
    z-index: 9;
    transition: all 0.5s ease-in-out;
  }

  .anyjob-mobile-nav-bar-close-menu {
    display: flex;
    flex-direction: column;
    position: absolute;    
    background-color: #fff;
    width: 100%;
    height: 100vh;
    right: -100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .anyjob-mobile-nav-bar-close-menu-btn {
    cursor: pointer;
    padding: 20px;
  }

  .anyjob-mobile-menu-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
  }

  .anyjob-nav-menu-item div {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
  }

  .anyjob-nav-menu-item div:hover{
    color: #1A1A1A;
    padding: 10px 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    background: #FFFAE9;
    border-left: 3px solid #F7CE38;
    cursor: pointer;
  }

  .anyjob-nav-menu-item-active div{
    color: #1A1A1A;
    padding: 10px 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    background: #FDF5D7;
    border-left: 3px solid #F7CE38;
    cursor: pointer;
  }

  .anyjob-mobile-nav-ctas {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #F2F2F2;
    width: 100%;
    margin-top: 60px;
    padding-top: 60px;
  }

  .anyjob-mobile-nav-ctas-login {
    color: #1A1A1A;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }

  .anyjob-mobile-nav-ctas-signup {
    border-radius: 25px;
    background: #F7CE38;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1A1A1A;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 320px;
    height: 50px;
    margin-top: 20px;
  }

  .anyjob-mobile-nav-ctas-signup:hover {
    background: #EDC537;
  }
}

