@media screen and (min-width: 1150px) {
  .anyjob-withdrawal-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }
  .anyjob-withdrawal-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-withdrawal-page-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }
  
  .anyjob-withdrawal-page-title {
    font-size: 28px;
    font-weight: 600;
    color: #1A1A1A;
    text-align: center;
  }
  
  .anyjob-withdrawal-page-sub-title {
    font-size: 16px;
    margin-top: 10px;
    color: #828282;
    text-align: center;
  }
  
  .anyjob-withdrawal-page-content {
    margin: 80px 140px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-withdrawal-payment-details-container {
    border: 1px solid var(--Gray-5, #E0E0E0);
    border-radius: 24px;
    padding-right: 40px;
    padding-left: 40px;
    margin-top: 10px;
    background: #FFF;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-withdrawal-label {
    color: var(--Grey-1, #555);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-withdrawal-payment-details-row {
    display: flex;
    flex-direction: row;
    margin: 30px 0px;
  }
  
  .anyjob-withdrawal-payment-details-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    
  }
  
  .anyjob-withdrawal-payment-details-data {
    font-size: 18px;
    font-weight: 600;
    color: #1A1A1A;
    margin-top: 10px;
  }
  
  .anyjob-withdrawal-payment-details-btn {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border: 1px solid var(--Gray-5, #F7CE38);
    border-radius: 50px;
    background-color: #FFFBEC;
  }
  
  .anyjob-withdrawal-page-btn-0 {
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #E0E0E0;
    margin-top: 40px;
    align-self: flex-end;
  }
  
  .anyjob-withdrawal-page-btn-1 {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #F7CE38;
    margin-top: 40px;
    align-self: flex-end;
  }
  
  .anyjob-withdrawal-page-btn-2 {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #F7CE38;
    margin-top: 40px;
    align-self: center;
  }
  
  .anyjob-withdrawal-no-payment-details-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  
  .anyjob-withdrawal-no-payment-details-container-title {
    font-size: 24px;
    font-weight: 600;
    color: #1A1A1A;
    text-align: center;
  }
  
  .anyjob-withdrawal-no-payment-details-container-sub-title {
    font-size: 14px;
    color: #828282;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
  .anyjob-withdrawal-selected-amount-container {
    border: 1px solid var(--Gray-5, #F7CE38);
    border-radius: 24px;
    background-color: #FFFBEC;
    width: 558px;
    height: 110px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1A1A1A;
    font-weight: 600;
    font-size: 45px;
    margin-bottom: 80px;
    cursor: pointer;
  }
  
  .anyjob-withdrawal-payment-summary-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    height: 50px;
    width: 550px;
  }
  
  .anyjob-withdrawal-payment-summary-item-label {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
  }
  
  .anyjob-withdrawal-payment-summary-item-value {
    color: #1A1A1A;
    font-weight: 600;
    font-size: 18px;
  }
}

@media screen and (min-width: 920px) and (max-width: 1149px) {
  .anyjob-withdrawal-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }
  .anyjob-withdrawal-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-withdrawal-page-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }
  
  .anyjob-withdrawal-page-title {
    font-size: 28px;
    font-weight: 600;
    color: #1A1A1A;
    text-align: center;
  }
  
  .anyjob-withdrawal-page-sub-title {
    font-size: 16px;
    margin-top: 10px;
    color: #828282;
    text-align: center;
  }
  
  .anyjob-withdrawal-page-content {
    margin: 80px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-withdrawal-payment-details-container {
    border: 1px solid var(--Gray-5, #E0E0E0);
    border-radius: 24px;
    padding-right: 40px;
    padding-left: 40px;
    margin-top: 10px;
    background: #FFF;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-withdrawal-label {
    color: var(--Grey-1, #555);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-withdrawal-payment-details-row {
    display: flex;
    flex-direction: row;
    margin: 30px 0px;
  }
  
  .anyjob-withdrawal-payment-details-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    
  }
  
  .anyjob-withdrawal-payment-details-data {
    font-size: 18px;
    font-weight: 600;
    color: #1A1A1A;
    margin-top: 10px;
  }
  
  .anyjob-withdrawal-payment-details-btn {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 50px;
    border: 1px solid var(--Gray-5, #F7CE38);
    border-radius: 50px;
    background-color: #FFFBEC;
  }
  
  .anyjob-withdrawal-page-btn-0 {
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #E0E0E0;
    margin-top: 40px;
    align-self: flex-end;
  }
  
  .anyjob-withdrawal-page-btn-1 {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #F7CE38;
    margin-top: 40px;
    align-self: flex-end;
  }
  
  .anyjob-withdrawal-page-btn-2 {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #F7CE38;
    margin-top: 40px;
    align-self: center;
  }
  
  .anyjob-withdrawal-no-payment-details-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  
  .anyjob-withdrawal-no-payment-details-container-title {
    font-size: 24px;
    font-weight: 600;
    color: #1A1A1A;
    text-align: center;
  }
  
  .anyjob-withdrawal-no-payment-details-container-sub-title {
    font-size: 14px;
    color: #828282;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
  .anyjob-withdrawal-selected-amount-container {
    border: 1px solid var(--Gray-5, #F7CE38);
    border-radius: 24px;
    background-color: #FFFBEC;
    width: 350px;
    height: 110px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1A1A1A;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 80px;
    cursor: pointer;
  }
  
  .anyjob-withdrawal-payment-summary-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    height: 50px;
    width: 400px;
  }
  
  .anyjob-withdrawal-payment-summary-item-label {
    color: #828282;
    font-weight: 500;
    font-size: 14px;
  }
  
  .anyjob-withdrawal-payment-summary-item-value {
    color: #1A1A1A;
    font-weight: 600;
    font-size: 16px;
  }
}

@media screen and (min-width: 600px) and (max-width: 919px) {
  .anyjob-withdrawal-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }
  .anyjob-withdrawal-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-withdrawal-page-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }
  
  .anyjob-withdrawal-page-title {
    font-size: 28px;
    font-weight: 600;
    color: #1A1A1A;
    text-align: center;
  }
  
  .anyjob-withdrawal-page-sub-title {
    font-size: 16px;
    margin-top: 10px;
    color: #828282;
    text-align: center;
  }
  
  .anyjob-withdrawal-page-content {
    margin: 80px 50px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-withdrawal-payment-details-container {
    border: 1px solid var(--Gray-5, #E0E0E0);
    border-radius: 24px;
    padding-right: 40px;
    padding-left: 40px;
    margin-top: 10px;
    background: #FFF;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-withdrawal-label {
    color: var(--Grey-1, #555);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-withdrawal-payment-details-row {
    display: flex;
    flex-direction: row;
    margin: 30px 0px;
  }
  
  .anyjob-withdrawal-payment-details-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    
  }
  
  .anyjob-withdrawal-payment-details-data {
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    margin-top: 10px;
  }
  
  .anyjob-withdrawal-payment-details-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    border: 1px solid var(--Gray-5, #F7CE38);
    border-radius: 50px;
    background-color: #FFFBEC;
  }
  
  .anyjob-withdrawal-page-btn-0 {
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #E0E0E0;
    margin-top: 40px;
    align-self: flex-end;
  }
  
  .anyjob-withdrawal-page-btn-1 {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #F7CE38;
    margin-top: 40px;
    align-self: flex-end;
  }
  
  .anyjob-withdrawal-page-btn-2 {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #F7CE38;
    margin-top: 40px;
    align-self: center;
  }
  
  .anyjob-withdrawal-no-payment-details-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  
  .anyjob-withdrawal-no-payment-details-container-title {
    font-size: 24px;
    font-weight: 600;
    color: #1A1A1A;
    text-align: center;
  }
  
  .anyjob-withdrawal-no-payment-details-container-sub-title {
    font-size: 14px;
    color: #828282;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
  .anyjob-withdrawal-selected-amount-container {
    border: 1px solid var(--Gray-5, #F7CE38);
    border-radius: 24px;
    background-color: #FFFBEC;
    width: 350px;
    height: 110px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1A1A1A;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 80px;
    cursor: pointer;
  }
  
  .anyjob-withdrawal-payment-summary-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    height: 50px;
    width: 350px;
  }
  
  .anyjob-withdrawal-payment-summary-item-label {
    color: #828282;
    font-weight: 500;
    font-size: 14px;
  }
  
  .anyjob-withdrawal-payment-summary-item-value {
    color: #1A1A1A;
    font-weight: 600;
    font-size: 16px;
  }
}

@media screen and (max-width: 599px) {
  .anyjob-withdrawal-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
  }
  .anyjob-withdrawal-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-withdrawal-page-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .anyjob-withdrawal-page-title {
    font-size: 28px;
    font-weight: 600;
    color: #1A1A1A;
    text-align: center;
  }
  
  .anyjob-withdrawal-page-sub-title {
    font-size: 16px;
    margin-top: 10px;
    color: #828282;
    text-align: center;
  }
  
  .anyjob-withdrawal-page-content {
    margin: 50px 20px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-withdrawal-payment-details-container {
    border: 1px solid var(--Gray-5, #E0E0E0);
    border-radius: 24px;
    padding-right: 40px;
    padding-left: 40px;
    margin-top: 10px;
    background: #FFF;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-withdrawal-label {
    color: var(--Grey-1, #555);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-withdrawal-payment-details-row {
    display: flex;
    flex-direction: row;
    margin: 30px 0px;
  }
  
  .anyjob-withdrawal-payment-details-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    
  }
  
  .anyjob-withdrawal-payment-details-data {
    font-size: 14px;
    font-weight: 600;
    color: #1A1A1A;
    margin-top: 10px;
  }
  
  .anyjob-withdrawal-payment-details-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    border: 1px solid var(--Gray-5, #F7CE38);
    border-radius: 50px;
    background-color: #FFFBEC;
  }
  
  .anyjob-withdrawal-page-btn-0 {
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #E0E0E0;
    margin-top: 40px;
    align-self: flex-end;
  }
  
  .anyjob-withdrawal-page-btn-1 {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #F7CE38;
    margin-top: 40px;
    align-self: center;
  }
  
  .anyjob-withdrawal-page-btn-2 {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 50px;
    border-radius: 50px;
    background-color: #F7CE38;
    margin-top: 40px;
    align-self: center;
  }
  
  .anyjob-withdrawal-no-payment-details-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  
  .anyjob-withdrawal-no-payment-details-container-title {
    font-size: 24px;
    font-weight: 600;
    color: #1A1A1A;
    text-align: center;
  }
  
  .anyjob-withdrawal-no-payment-details-container-sub-title {
    font-size: 14px;
    color: #828282;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
  .anyjob-withdrawal-selected-amount-container {
    border: 1px solid var(--Gray-5, #F7CE38);
    border-radius: 24px;
    background-color: #FFFBEC;
    width: 300px;
    height: 110px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1A1A1A;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 80px;
    cursor: pointer;
  }
  
  .anyjob-withdrawal-payment-summary-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    height: 50px;
    width: 300px;
  }
  
  .anyjob-withdrawal-payment-summary-item-label {
    color: #828282;
    font-weight: 500;
    font-size: 14px;
  }
  
  .anyjob-withdrawal-payment-summary-item-value {
    color: #1A1A1A;
    font-weight: 600;
    font-size: 16px;
  }
}

.anyjob-withdrawal-payment-summary {
  padding: 30px 0px;
}