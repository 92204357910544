

@media screen and (min-width: 850px) {
  .anyjob-task-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-submit-task-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-submit-task-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-submit-task-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
  }

  .anyjob-submit-task-page-contents {
    padding: 40px;
  }

  .anyjob-submit-task-page-contents form {
    display: flex;
    flex-direction: column;
  }

  .anyjob-submit-task-form {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 40px 32px;
    margin-bottom: 50px;
  }

  .anyjob-submit-task-input-group {
    margin-top: 10px;
  }

  .anyjob-submit-task-input-group-label {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
  }

  .anyjob-submit-task-input-group textarea {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    margin-top: 20px;
    min-height: 182px;
    width: 100%;
    outline: none;
    box-shadow: none;
    align-content: flex-start;
    padding: 20px;
    /* margin-bottom: 30px; */
  }

  .anyjob-submit-task-file-upload {
    border-radius: 12px;
    border: 2px dashed #FFC839;
    background: #FFFBEC;
    width: 100%;
    display: flex;
    height: 182px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-submit-task-file-upload-label {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }

  .anyjob-submit-task-file-upload-label-highlight {
    color: var(--Primary-Color, #F7CE38);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }

  .anyjob-submit-task-file-upload-note {
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-top: 10px;
  }

  .anyjob-submit-task-submit {
    display: flex;
    align-self: flex-end;
    align-items: center;
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    padding: 17px 141px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-bottom: 100px;
  }

  .anyjob-submit-task-submit-btn-container {
    display: flex;
    flex-direction: row;
    
  }

  .anyjob-submit-task-submit-btn {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    /* padding: 15px 141px; */
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 50px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 40px;
    cursor: pointer;
  }

  .anyjob-submit-task-dynamic-input-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    /* width: 400px; */
  }
  
  .anyjob-submit-task-dynamic-input-label {
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 500;
    margin-bottom: 6px;  
  }
  
  .anyjob-submit-task-dynamic-input-textarea {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    min-height: 130px;
    width: 100%;
    outline: none;
    box-shadow: none;
    align-content: flex-start;
    padding: 10px 20px;
    font-size: 14px;
  }
  
  .anyjob-submit-task-dynamic-input-text {
    border-radius: 50px;
    border: 1px solid #E0E0E0;
    height: 50px;
    width: 100%;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    padding: 0px 20px;
  }

  .anyjob-submit-task-dynamic-input-radio-span {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 10px 0px;
    align-items: center;
  }

  .anyjob-submit-task-dynamic-input-radio-span p {
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 600;
  }
  
  .anyjob-submit-task-dynamic-input-radio {
    border: 1px solid #E0E0E0;
    height: 24px;
    width: 24px;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    padding: 0px 20px;
  }

  .anyjob-submit-task-btn2 {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    border-radius: 100px;
    background: var(--Primary-Color, #FF7570);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 261px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 20px;
    cursor: pointer;
  }

  .anyjob-submitted-task-stats {
    border-radius: 16px;
    border: 1px solid #F7CE38;
    background: #FFFBEC;
    padding: 40px 32px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
  }
  
  .anyjob-submitted-task-stats-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  
  .anyjob-submitted-task-stats-item-title {
    font-size: 14px;
    color: #1A1A1A;
  }
  
  .anyjob-submitted-task-stats-item-value {
    font-size: 20px;
    color: #1A1A1A;
    font-weight: 600;
  
  }
  
  .anyjob-submitted-task-stats-item-cta {
    font-size: 16px;
    color: #1A1A1A;
    font-weight: 600;
    height: 50px;
    width: 120px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7CE38;
    cursor: pointer;
  }
  
}


@media screen and (min-width: 720px)  and (max-width: 849px)  {
  .anyjob-task-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-submit-task-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-submit-task-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-submit-task-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
  }

  .anyjob-submit-task-page-contents {
    padding: 40px;
  }

  .anyjob-submit-task-page-contents form {
    display: flex;
    flex-direction: column;
  }

  .anyjob-submit-task-form {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 40px 32px;
    margin-bottom: 50px;
  }

  .anyjob-submit-task-input-group-label {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
  }

  .anyjob-submit-task-input-group textarea {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    margin-top: 20px;
    min-height: 182px;
    width: 100%;
    outline: none;
    box-shadow: none;
    align-content: flex-start;
    padding: 20px;
    /* margin-bottom: 30px; */
  }

  .anyjob-submit-task-file-upload {
    border-radius: 12px;
    border: 2px dashed #FFC839;
    background: #FFFBEC;
    width: 100%;
    display: flex;
    height: 182px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-submit-task-file-upload-label {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }

  .anyjob-submit-task-file-upload-label-highlight {
    color: var(--Primary-Color, #F7CE38);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }

  .anyjob-submit-task-file-upload-note {
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-top: 10px;
  }

  .anyjob-submit-task-submit {
    display: flex;
    align-self: flex-end;
    align-items: center;
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    padding: 12px 80px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-bottom: 100px;
  }

  .anyjob-submit-task-submit-btn-container {
    display: flex;
    flex-direction: row;
  }

  .anyjob-submit-task-submit-btn {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    /* padding: 15px 141px; */
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 50px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 40px;
    cursor: pointer;
  }

  .anyjob-submit-task-dynamic-input-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    /* width: 400px; */
  }
  
  .anyjob-submit-task-dynamic-input-label {
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 500;
    margin-bottom: 6px;  
  }
  
  .anyjob-submit-task-dynamic-input-textarea {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    min-height: 130px;
    width: 100%;
    outline: none;
    box-shadow: none;
    align-content: flex-start;
    padding: 10px 20px;
    font-size: 14px;
  }


  .anyjob-submit-task-dynamic-input-radio-span {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 10px 0px;
    align-items: center;
  }

  .anyjob-submit-task-dynamic-input-radio-span p {
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 600;
  }
  
  .anyjob-submit-task-dynamic-input-radio {
    border: 1px solid #E0E0E0;
    height: 24px;
    width: 24px;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    padding: 0px 20px;
  }

  
  .anyjob-submit-task-dynamic-input-text {
    border-radius: 50px;
    border: 1px solid #E0E0E0;
    height: 50px;
    width: 100%;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    padding: 0px 20px;
  }

  .anyjob-submit-task-btn2 {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    border-radius: 100px;
    background: var(--Primary-Color, #FF7570);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 261px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 20px;
    cursor: pointer;
  }

  .anyjob-submitted-task-stats {
    border-radius: 16px;
    border: 1px solid #F7CE38;
    background: #FFFBEC;
    padding: 40px 32px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
  }
  
  .anyjob-submitted-task-stats-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  
  .anyjob-submitted-task-stats-item-title {
    font-size: 14px;
    color: #1A1A1A;
  }
  
  .anyjob-submitted-task-stats-item-value {
    font-size: 18px;
    color: #1A1A1A;
    font-weight: 600;
  
  }
  
  .anyjob-submitted-task-stats-item-cta {
    font-size: 16px;
    color: #1A1A1A;
    font-weight: 600;
    height: 50px;
    width: 120px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7CE38;
    cursor: pointer;
  }
  
}

@media screen and (max-width: 719px){
  .anyjob-task-page-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
  }

  .anyjob-submit-task-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-submit-task-page-content-hero {
    display: flex;
    align-items: center;
    padding: 32px 40px;
    border-bottom: 1px solid #e2e2e2;
  }

  .anyjob-submit-task-title {
    color: var(--Black-1, #1A1A1A);    
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
  }

  .anyjob-submit-task-page-contents {
    padding: 20px;
  }

  .anyjob-submit-task-page-contents form {
    display: flex;
    flex-direction: column;
  }

  .anyjob-submit-task-form {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 40px 32px;
    margin-bottom: 50px;
  }

  .anyjob-submit-task-input-group-label {
    color: var(--Black-1, #1A1A1A);    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
  }

  .anyjob-submit-task-input-group textarea {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    margin-top: 20px;
    min-height: 182px;
    width: 100%;
    outline: none;
    box-shadow: none;
    align-content: flex-start;
    padding: 20px;
  }

  .anyjob-submit-task-file-upload {
    border-radius: 12px;
    border: 2px dashed #FFC839;
    background: #FFFBEC;
    width: 100%;
    display: flex;
    height: 182px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .anyjob-submit-task-file-upload-label {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }

  .anyjob-submit-task-file-upload-label-highlight {
    color: var(--Primary-Color, #F7CE38);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }

  .anyjob-submit-task-file-upload-note {
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-top: 10px;
  }

  .anyjob-submit-task-submit {
    display: flex;
    align-self: flex-end;
    align-items: center;
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    padding: 12px 80px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-bottom: 100px;
  }

  .anyjob-submit-task-submit-btn-container {
    display: flex;
    flex-direction: row;
  }

  .anyjob-submit-task-submit-btn {
    display: flex;
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    /* padding: 15px 141px; */
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 50px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 40px;
    cursor: pointer;
  }

  .anyjob-submit-task-dynamic-input-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 95%;
  }
  
  .anyjob-submit-task-dynamic-input-label {
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 500;
    margin-bottom: 6px;  
  }
  
  .anyjob-submit-task-dynamic-input-textarea {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    min-height: 130px;
    width: 100%;
    outline: none;
    box-shadow: none;
    align-content: flex-start;
    padding: 10px 20px;
    font-size: 14px;
  }
  
  .anyjob-submit-task-dynamic-input-radio-span {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 10px 0px;
    align-items: center;
  }

  .anyjob-submit-task-dynamic-input-radio-span p {
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 600;
  }
  
  .anyjob-submit-task-dynamic-input-radio {
    border: 1px solid #E0E0E0;
    height: 24px;
    width: 24px;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    padding: 0px 20px;
  }

  
  .anyjob-submit-task-dynamic-input-text {
    border-radius: 50px;
    border: 1px solid #E0E0E0;
    height: 50px;
    width: 100%;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    padding: 0px 20px;
  }

  .anyjob-submit-task-btn2 {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    border-radius: 100px;
    background: var(--Primary-Color, #FF7570);
    box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 95%;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-top: 20px;
    cursor: pointer;
  }

  .anyjob-submitted-task-stats {
    border-radius: 16px;
    border: 1px solid #F7CE38;
    background: #FFFBEC;
    padding: 30px 20px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
  }
  
  .anyjob-submitted-task-stats-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  
  .anyjob-submitted-task-stats-item-title {
    font-size: 12px;
    color: #1A1A1A;
  }
  
  .anyjob-submitted-task-stats-item-value {
    font-size: 16px;
    color: #1A1A1A;
    font-weight: 600;
  
  }
  
  .anyjob-submitted-task-stats-item-cta {
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 600;
    height: 45px;
    width: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7CE38;
    cursor: pointer;
  }
  
}

.anyjob-submit-task-file-upload-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.anyjob-submit-task-upload-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.anyjob-submit-task-attachment-container {
  width: 261px;
  height: 185px;
  border-radius: 12px;
  background-color: #ECECEC;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  padding: 15px;
  margin-top: 15px;
  margin-right: 20px;
}

.anyjob-submit-task-attachment-container img {
  width: 67px;
  align-self: center;
  margin-top: 10px;
}

.anyjob-submit-task-attachment-container p {
  font-size: 12px;
  font-weight: 600;
  color: #1A1A1A;
  text-align: center;
  margin-top: 10px;
}

.anyjob-submit-task-remove-attachment {
  align-self: flex-end;
  cursor: pointer;
  z-index: 99;
}

.anyjob-submit-task-remove-attachment-2 {
  cursor: pointer;
  /* z-index: 3;
  position: absolute; */
  float: right;
  position: absolute;
  right: 10px;
  z-index: 12;
}

.anyjob-submit-task-remove-attachment-icon {
  /* color: #e2e2e2; */
  margin: 10px;
}

.anyjob-submit-task-attachment-img-container {
  width: 261px;
  height: 185px;
  border-radius: 12px;
  margin-top: 15px;
  display: flex;
  position: relative;
  margin-right: 20px;
  background-color: #ECECEC;
}

.anyjob-submit-task-attachment-img-container img {
  /* width: 261px;
  height: 185px; */
  border-radius: 12px;
  width: 100%; /* set width to fill container */
  height: 100%; /* set height to fill container */
  object-fit: contain; /* maintain aspect ratio and fit inside container */
  display: block; /* make sure image is treated as a block element */
  margin: auto;
}

.anyjob-submit-task-attachment-video-container {
  width: 261px;
  height: 185px;
  border-radius: 12px;
  margin-top: 15px;
  display: flex;
  position: relative;
  margin-right: 20px;
}

.anyjob-submit-task-attachment-video-container video {
  width: 261px;
  height: 185px;
  border-radius: 12px;
}

.anyjob-submit-task-upload-btn {
  border-radius: 12px;
  border: 2px dashed #FFC839;
  background: #FFFBEC;
  width: 261px;
  display: flex;
  height: 185px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-right: 20px;
  flex-direction: column;
}

.anyjob-submit-task-map-container-box {
  /* width: 261px; */
  height: 185px;
  border-radius: 12px;
  display: block;
  background-color: #f5dd85;
  box-sizing: border-box;
  margin-top: 20px;
  z-index: 0;
}

.anyjob-submit-task-map-container {
  /* width: 261px; */
  height: 185px;
  border-radius: 12px;
  z-index: 0;
}

.anyjob-submit-task-btn {
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  border-radius: 100px;
  background: var(--Primary-Color, #F7CE38);
  box-shadow: 0px 10px 20px 0px rgba(159, 93, 226, 0.10);
  display: flex;
  padding: 10px 40px;
  color: var(--Black-1, #1A1A1A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  margin-top: 20px;
  cursor: pointer;
}



.anyjob-submit-task-note {
  color: #1A1A1A;
  font-size: 12px;
  margin-top: 10px;
}

.anyjob-task-details-comments-container {
  border-radius: 16px;
  border: 1px solid #e2e2e2;
  background: #FFF;
  width: 100%;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
}

.anyjob-task-details-comment-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.anyjob-task-details-comments-blocks {
  margin-top: 20px;
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
}

.anyjob-task-details-comment {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.anyjob-task-details-comment-avatar {
  display: flex;
  width: 60px;
  height: 60px;
  background-color: #5E6D55;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
}

.anyjob-task-details-comment-avatar p {
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
}

.anyjob-task-details-comment-content {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.anyjob-task-details-comment-meta-data {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.anyjob-task-details-comment-meta-data-name {
  font-size: 16px;
  font-weight: 600;
  color: #1A1A1A;

}

.anyjob-task-details-comment-meta-data-date {
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  margin-left: 15px;
}

.anyjob-task-details-comment-message {
  font-size: 14px;
  color: #1A1A1A;
  margin-top: 10px;
}