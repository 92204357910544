

@media screen and (min-width: 600px) {
  .anyjob-notification-banner-container {
    display: flex;
    border-radius: 20px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    padding: 24px 32px;
    position: relative;
    gap: 24px;
    margin: 20px 40px;
  }
  
  .anyjob-notification-banner-icon {
    height: 58.844px;
  }
  
  .anyjob-notification-banner-contents {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-notification-banner-title {
    color: var(--Secondary-Color, #FF5851);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
  }
  
  .anyjob-notification-banner-msg {
    margin-top: 10px;
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }
  
  .anyjob-notification-banner-controls-container {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .anyjob-notification-banner-controls-close {
    color: black;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  
  .anyjob-notification-banner-controls-left {
    color: black;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

@media screen and (max-width: 599px) {
  .anyjob-notification-banner-container {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    padding: 20px;
    position: relative;
    gap: 16px;
    margin: 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .anyjob-notification-banner-icon {
    height: 58.844px;
  }
  
  .anyjob-notification-banner-contents {
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-notification-banner-title {
    color: var(--Secondary-Color, #FF5851);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
  }
  
  .anyjob-notification-banner-msg {
    margin-top: 10px;
    color: var(--Grey1, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }
  
  .anyjob-notification-banner-controls-container {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .anyjob-notification-banner-controls-close {
    color: black;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  
  .anyjob-notification-banner-controls-left {
    color: black;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}