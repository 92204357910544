

@media screen and (min-width: 1400px) {
  .anyjob-job-widget-view-job-details-2 {
    display: none;
  }
  
  .anyjob-job-widget-container {
    border-radius: 16px;
    border: 1px solid #e2e2e2;
    background: #FFF;
    width: 100%;
    padding: 40px 32px;
    display: flex;
    align-items: flex-start;
  }
  
  .anyjob-job-widget-details {
    display: flex;
    flex-direction: column;
    /* width: 850px; */
  }
  
  .anyjob-job-widget-details-heading {
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
    margin-bottom: 10px;
  }
  
  .anyjob-job-widget-details-title {
    color: var(--Black-1, #1A1A1A);  
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
    margin-bottom: 15px;
  }
  
  .anyjob-job-widget-details span {
    display: flex;
    align-items: center;
  }
  
  .anyjob-job-widget-details-sub-title {
    color: var(--Grey1, #767676);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-sub-title-2 {
    color: var(--Grey1, #767676);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-divider-1 {
    margin-left: 40px;
    margin-right: 20px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-divider-2 {
    margin: 0px 40px;
    width: 1px;
    height: 34px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-pill {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #FFEFEE;
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }
  
  .anyjob-job-widget-job-description {
    /* width: 1020px; */
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin: 30px 0px;
  }
  
  .anyjob-job-widget-skills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .anyjob-job-widget-skill {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  
  .anyjob-job-widget-view-job-details {
    color: var(--Secondary-Color, #FF5851);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }
  
  .anyjob-job-widget-divider {
    width: 2px;
    height: 240px;
    align-self: center;
    background-color: #E8E8E8;
    margin: 0px 40px;
  }
  
  .anyjob-job-widget-meta-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }
  
  .anyjob-job-widget-meta-data-title {
    color: #1A1A1A;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 10px;
  }
  
  .anyjob-job-widget-meta-data-value {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 30px;
  }


}

@media screen and (min-width: 1250px) and (max-width: 1399px)  {
  .anyjob-job-widget-view-job-details-2 {
    display: none;
  }

  .anyjob-job-widget-container {
    border-radius: 16px;
    border: 1px solid #e2e2e2;
    background: #FFF;
    width: 100%;
    padding: 40px 32px;
    display: flex;
    align-items: flex-start;
  }
  
  .anyjob-job-widget-details {
    display: flex;
    flex-direction: column;
    /* width: 650px; */
  }
  
  .anyjob-job-widget-details-heading {
    color: var(--Black-1, #1A1A1A);  
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
    margin-bottom: 24px;
  }
  
  .anyjob-job-widget-details-title {
    color: var(--Black-1, #1A1A1A);  
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
    margin-bottom: 20px;
  }
  
  .anyjob-job-widget-details span {
    display: flex;
    align-items: center;
  }
  
  .anyjob-job-widget-details-sub-title {
    color: var(--Grey1, #767676);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-sub-title-2 {
    color: var(--Grey1, #767676);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-divider-1 {
    margin-left: 40px;
    margin-right: 20px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-divider-2 {
    margin: 0px 40px;
    width: 1px;
    height: 34px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-pill {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #FFEFEE;
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }
  
  .anyjob-job-widget-job-description {
    /* width: 1020px; */
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin: 30px 0px;
  }
  
  .anyjob-job-widget-skills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .anyjob-job-widget-skill {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  
  .anyjob-job-widget-view-job-details {
    color: var(--Secondary-Color, #FF5851);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }
  
  .anyjob-job-widget-divider {
    width: 2px;
    height: 240px;
    align-self: center;
    background-color: #E8E8E8;
    margin: 0px 40px;
  }
  
  .anyjob-job-widget-meta-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }
  
  .anyjob-job-widget-meta-data-title {
    color: #1A1A1A;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 10px;
  }
  
  .anyjob-job-widget-meta-data-value {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1035px) and (max-width: 1249px)  {
  .anyjob-job-widget-view-job-details-2 {
    display: none;
  }
  .anyjob-job-widget-container {
    border-radius: 16px;
    border: 1px solid #e2e2e2;
    background: #FFF;
    width: 100%;
    padding: 40px 32px;
    display: flex;
    align-items: flex-start;
  }
  
  .anyjob-job-widget-details {
    display: flex;
    flex-direction: column;
    /* width: 450px; */
  }
  
  .anyjob-job-widget-details-heading {
    color: var(--Black-1, #1A1A1A);  
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
    margin-bottom: 24px;
  }
  
  .anyjob-job-widget-details-title {
    color: var(--Black-1, #1A1A1A);  
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
    margin-bottom: 20px;
  }
  
  .anyjob-job-widget-details span {
    display: flex;
    align-items: center;
  }
  
  .anyjob-job-widget-details-sub-title {
    color: var(--Grey1, #767676);  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-sub-title-2 {
    color: var(--Grey1, #767676);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-divider-1 {
    margin-left: 20px;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-divider-2 {
    margin: 0px 20px;
    width: 1px;
    height: 34px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-pill {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #FFEFEE;
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }
  
  .anyjob-job-widget-job-description {
    /* width: 1020px; */
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin: 30px 0px;
  }
  
  .anyjob-job-widget-skills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .anyjob-job-widget-skill {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  
  .anyjob-job-widget-view-job-details {
    color: var(--Secondary-Color, #FF5851);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }
  
  .anyjob-job-widget-divider {
    width: 2px;
    height: 240px;
    align-self: center;
    background-color: #E8E8E8;
    margin: 0px 40px;
  }
  
  .anyjob-job-widget-meta-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }
  
  .anyjob-job-widget-meta-data-title {
    color: #1A1A1A;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 10px;
  }
  
  .anyjob-job-widget-meta-data-value {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 720px) and (max-width: 1034px)  {
  .anyjob-job-widget-view-job-details-2 {
    display: none;
  }
  .anyjob-job-widget-container {
    border-radius: 16px;
    border: 1px solid #e2e2e2;
    background: #FFF;
    width: 100%;
    padding: 40px 32px;
    display: flex;
    align-items: flex-start;
  }
  
  .anyjob-job-widget-details {
    display: flex;
    flex-direction: column;
    /* width: 350px; */
  }
  
  .anyjob-job-widget-details-heading {
    color: var(--Black-1, #1A1A1A);  
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
    margin-bottom: 24px;
  }
  
  .anyjob-job-widget-details-title {
    color: var(--Black-1, #1A1A1A);  
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
    margin-bottom: 20px;
  }
  
  .anyjob-job-widget-details span {
    display: flex;
    align-items: center;
  }
  
  .anyjob-job-widget-details-sub-title {
    color: var(--Grey1, #767676);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-sub-title-2 {
    color: var(--Grey1, #767676);  
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-divider-1 {
    margin-left: 20px;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-divider-2 {
    margin: 0px 12px;
    width: 1px;
    height: 34px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-pill {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #FFEFEE;
    color: var(--Black-1, #1A1A1A);  
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }
  
  .anyjob-job-widget-job-description {
    /* width: 1020px; */
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin: 30px 0px;
  }
  
  .anyjob-job-widget-skills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .anyjob-job-widget-skill {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  
  .anyjob-job-widget-view-job-details {
    color: var(--Secondary-Color, #FF5851);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    text-decoration-line: underline;
  }
  
  .anyjob-job-widget-divider {
    width: 2px;
    height: 240px;
    align-self: center;
    background-color: #E8E8E8;
    margin: 0px 20px;
  }
  
  .anyjob-job-widget-meta-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }
  
  .anyjob-job-widget-meta-data-title {
    color: #1A1A1A;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 10px;
  }
  
  .anyjob-job-widget-meta-data-value {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 719px) {
  .anyjob-job-widget-container {
    border-radius: 16px;
    border: 1px solid #e2e2e2;
    background: #FFF;
    width: 100%;
    padding: 40px 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .anyjob-job-widget-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* width: 0px; */
  }
  
  .anyjob-job-widget-details-heading {
    color: var(--Black-1, #1A1A1A);  
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    letter-spacing: 0.64px;
    margin-bottom: 24px;
  }
  
  .anyjob-job-widget-details-title {
    color: var(--Black-1, #1A1A1A);  
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
    margin-bottom: 20px;
  }
  
  .anyjob-job-widget-details span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* width: 100%; */
  }
  
  .anyjob-job-widget-details-sub-title {
    color: var(--Grey1, #767676);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-sub-title-2 {
    color: var(--Grey1, #767676);  
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }
  
  .anyjob-job-widget-details-divider-1 {
    margin-left: 20px;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-divider-2 {
    margin: 0px 12px;
    width: 1px;
    height: 34px;
    border-radius: 6px;
    background-color: #E8E8E8;
  }
  
  .anyjob-job-widget-details-pill {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #FFEFEE;
    color: var(--Black-1, #1A1A1A);  
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
  }
  
  .anyjob-job-widget-job-description {
    /* width: 1020px; */
    max-height: 5em; /* Adjust the height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin: 30px 0px;
    width: 100%;
  }
  
  .anyjob-job-widget-skills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .anyjob-job-widget-skill {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid #FFC839;
    background: #FFFBEC;
    color: var(--Black-1, #1A1A1A);  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.32px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  
  .anyjob-job-widget-view-job-details {
    color: var(--Secondary-Color, #FF5851);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    text-decoration-line: underline;
    display: none;
  }
  
  .anyjob-job-widget-view-job-details-2 {
    color: var(--Secondary-Color, #FF5851);  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    text-decoration-line: underline;
    margin-top: 20px;
  }
  
  .anyjob-job-widget-divider {
    width: 2px;
    height: 240px;
    align-self: center;
    background-color: #E8E8E8;
    margin: 0px 20px;
    display: none;
  }
  
  .anyjob-job-widget-meta-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    margin-top: 50px;
  }
  
  .anyjob-job-widget-meta-data-title {
    color: #1A1A1A;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 10px;
  }
  
  .anyjob-job-widget-meta-data-value {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    margin-bottom: 30px;
  }
}