
@media screen and (min-width: 1100px)  {
  .anyjob-faq-page-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-faq-page-header-sec {
    background: #FDF5D7;
    padding: 150px 222px 150px 221px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  
  }
  .anyjob-faq-page-header-title {
    color: var(--Black, #2D2D2D);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 96px */
    letter-spacing: 2.56px;
  }
  
  .anyjob-faq-page-header-sub-title {
    color: var(--Grey-1, #555);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 42px */
    letter-spacing: 1.12px;
    margin-top: 20px;
  }
  
  .anyjob-faq-page-content-sec {
    padding: 100px;
  }
  
  .anyjob-active-faq-item {
    border-radius: 16px;
    border: 2px solid #F7CE38;
    background: var(--Neutral-100, #FFF);
    box-shadow: 0px 6px 16px 0px rgba(247, 206, 56, 0.10);
    padding: 40px 32px;
    cursor: pointer;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
  }
  
  .anyjob-faq-item {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--Neutral-100, #FFF);
    padding: 40px 32px;
    cursor: pointer;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-faq-item-content {
    flex: 1;
    margin-right: 100px;
  }
  
  .anyjob-faq-item-title {
    color: #1A1A1A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 116.667% */
  }
  
  .anyjob-faq-item-desc {
    color: #767676;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    margin-top: 20px;
  }
  
  .anyjob-faq-item-close-btn {
    width: 50px;
    height: 50px;
    background-color: #F7CE38;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .anyjob-faq-item-close-btn-icon {
    /* color: #FFF; */
    font-size: 24px;
  }
  
  .anyjob-faq-item-open-btn {
    width: 50px;
    height: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.019);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .anyjob-faq-item-close-btn-icon {
    /* color: #FFF; */
    font-size: 24px;
    color: #F7CE38;
  }
  
}

@media screen and (min-width: 900px) and (max-width: 1099px)  {
  .anyjob-faq-page-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-faq-page-header-sec {
    background: #FDF5D7;
    padding: 150px 222px 150px 221px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  
  }
  .anyjob-faq-page-header-title {
    color: var(--Black, #2D2D2D);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 96px */
    letter-spacing: 2.56px;
  }
  
  .anyjob-faq-page-header-sub-title {
    color: var(--Grey-1, #555);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 42px */
    letter-spacing: 1.12px;
    margin-top: 20px;
  }
  
  .anyjob-faq-page-content-sec {
    padding: 100px;
  }
  
  .anyjob-active-faq-item {
    border-radius: 16px;
    border: 2px solid #F7CE38;
    background: var(--Neutral-100, #FFF);
    box-shadow: 0px 6px 16px 0px rgba(247, 206, 56, 0.10);
    padding: 40px 32px;
    cursor: pointer;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
  }
  
  .anyjob-faq-item {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--Neutral-100, #FFF);
    padding: 40px 32px;
    cursor: pointer;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-faq-item-content {
    flex: 1;
    margin-right: 50px;
  }
  
  .anyjob-faq-item-title {
    color: #1A1A1A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 116.667% */
  }
  
  .anyjob-faq-item-desc {
    color: #767676;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    margin-top: 20px;
  }
  
  .anyjob-faq-item-close-btn {
    width: 50px;
    height: 50px;
    background-color: #F7CE38;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .anyjob-faq-item-close-btn-icon {
    /* color: #FFF; */
    font-size: 24px;
  }
  
  .anyjob-faq-item-open-btn {
    width: 50px;
    height: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.019);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .anyjob-faq-item-close-btn-icon {
    /* color: #FFF; */
    font-size: 24px;
    color: #F7CE38;
  }
  
}

@media screen and (min-width: 550px) and (max-width: 899px)  {
  .anyjob-faq-page-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-faq-page-header-sec {
    background: #FDF5D7;
    padding: 100px 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  
  }
  .anyjob-faq-page-header-title {
    color: var(--Black, #2D2D2D);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 96px */
    letter-spacing: 2.56px;
  }
  
  .anyjob-faq-page-header-sub-title {
    color: var(--Grey-1, #555);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 42px */
    letter-spacing: 1.12px;
    margin-top: 20px;
  }
  
  .anyjob-faq-page-content-sec {
    padding: 100px 50px;
  }
  
  .anyjob-active-faq-item {
    border-radius: 16px;
    border: 2px solid #F7CE38;
    background: var(--Neutral-100, #FFF);
    box-shadow: 0px 6px 16px 0px rgba(247, 206, 56, 0.10);
    padding: 40px 32px;
    cursor: pointer;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
  }
  
  .anyjob-faq-item {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--Neutral-100, #FFF);
    padding: 40px 32px;
    cursor: pointer;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-faq-item-content {
    flex: 1;
    margin-right: 50px;
  }
  
  .anyjob-faq-item-title {
    color: #1A1A1A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 116.667% */
  }
  
  .anyjob-faq-item-desc {
    color: #767676;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    margin-top: 20px;
  }
  
  .anyjob-faq-item-close-btn {
    width: 36px;
    height: 36px;
    background-color: #F7CE38;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .anyjob-faq-item-close-btn-icon {
    /* color: #FFF; */
    font-size: 16px;
  }
  
  .anyjob-faq-item-open-btn {
    width: 36px;
    height: 36px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.019);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .anyjob-faq-item-open-btn-icon {
    /* color: #FFF; */
    font-size: 16px;
    color: #F7CE38;
    font-weight: 700;
  }
  
}

@media screen and (max-width: 549px)  {
  .anyjob-faq-page-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-faq-page-header-sec {
    background: #FDF5D7;
    padding: 60px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  
  }
  .anyjob-faq-page-header-title {
    color: var(--Black, #2D2D2D);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 96px */
    letter-spacing: 2.56px;
  }
  
  .anyjob-faq-page-header-sub-title {
    color: var(--Grey-1, #555);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 42px */
    letter-spacing: 1.12px;
    margin-top: 20px;
  }
  
  .anyjob-faq-page-content-sec {
    padding: 60px 20px;
  }
  
  .anyjob-active-faq-item {
    border-radius: 16px;
    border: 2px solid #F7CE38;
    background: var(--Neutral-100, #FFF);
    box-shadow: 0px 6px 16px 0px rgba(247, 206, 56, 0.10);
    padding: 40px 32px;
    cursor: pointer;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
  }
  
  .anyjob-faq-item {
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: var(--Neutral-100, #FFF);
    padding: 40px 32px;
    cursor: pointer;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .anyjob-faq-item-content {
    flex: 1;
    margin-right: 20px;
  }
  
  .anyjob-faq-item-title {
    color: #1A1A1A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 116.667% */
  }
  
  .anyjob-faq-item-desc {
    color: #767676;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    margin-top: 20px;
  }
  
  .anyjob-faq-item-close-btn {
    width: 36px;
    height: 36px;
    background-color: #F7CE38;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .anyjob-faq-item-close-btn-icon {
    /* color: #FFF; */
    font-size: 16px;
  }
  
  .anyjob-faq-item-open-btn {
    width: 36px;
    height: 36px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.019);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .anyjob-faq-item-open-btn-icon {
    /* color: #FFF; */
    font-size: 16px;
    color: #F7CE38;
    font-weight: 700;
  }
  
}