@media screen and (min-width: 520px) {
  .anyjob-edit-profile-details-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1,
  }
  
  .anyjob-edit-profile-details-modal-contents {
    width: 100%;
    max-width: 680px;
    height: 850px;
    background-color: #FFF;
    border-radius: 24px;
    align-self: center;
    justify-self: center;
    margin: 20px;
  }
  
  .anyjob-edit-profile-details-modal-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FF5851;
    height: 80px;
    padding: 0px 24px;
  }
  
  .anyjob-edit-profile-details-modal-contents-header p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.48px;
  }
  
  .anyjob-edit-profile-details-modal-close-btn {
    padding: 10px;
    cursor: pointer;
    
  }
  
  .anyjob-edit-profile-details-modal-close-icon {
    width: 32px;
    height: 32px;
    color: black;
  }
  
  .anyjob-edit-profile-details-modal-form {
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    /* width: 100%;
    height: 550px;
    position: relative;
    overflow: scroll;
    overflow-x: hidden; */
  }
  
  .anyjob-edit-profile-details-modal-contents-sub-form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 750px;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  
  .anyjob-edit-profile-details-modal-form-input-group {
    margin-top: 24px;
    width: 100%;
  }
  
  .anyjob-edit-profile-details-modal-form-input-group-label {
    color: var(--Black-1, #1A1A1A);  
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-edit-profile-details-modal-form-input-group-field {
    outline: none;
    box-shadow: none;
  
    border-radius: 50px;
    border: 0.673px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
  }
  
  .anyjob-edit-profile-details-modal-form-textarea {
    outline: none;
    box-shadow: none;
  
    border-radius: 24px;
    border: 0.673px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    min-height: 120px;
    margin-top: 10px;
    padding: 15px;
    width: 100%;
  }
  
  .anyjob-edit-profile-details-modal-form-divider {
    width: 100%;
    height: 1px;
    background-color: #e2e2e2;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  .anyjob-edit-profile-details-modal-form-err-msg {
    font-size: 12px;
    color: #FF5851;
    margin-top: 6px;
  }
  
  .anyjob-edit-profile-details-modal-form-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
  }
  
  .anyjob-edit-profile-details-modal-form-actions-cancel {
    cursor: pointer;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
  
  .anyjob-edit-profile-details-modal-form-actions-save {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    width: 250px;
    height: 50px;
    margin-left: 30px;
  }
  
  
  
  .anyjob-edit-profile-details-upload-profile {
    display: flex;
    flex-direction: row;
    align-items: center;  
    margin: 24px;
  }
  
  .anyjob-edit-profile-details-profile-icon-container {
    border: 3px solid #e6e6e6;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px;
    background-color: #fff;
  
    box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
  }
  
  .anyjob-edit-profile-details-profile-icon {
    width: 64px;
    height: 64px;
    color: #e6e6e6;
  }
  
  .anyjob-edit-profile-details-upload-profile-picture-btn {
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    display: flex;
    padding: 12px 30px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-left: 32px;
  }
  
  .anyjob-edit-profile-details-profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 148px;
    border: 4.44px solid var(--neutral-white, #FFF);
    box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
  }
} 
@media screen and (max-width: 519px) {
  .anyjob-edit-profile-details-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1,
  }
  
  .anyjob-edit-profile-details-modal-contents {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    align-self: center;
    justify-self: center;
  }
  
  .anyjob-edit-profile-details-modal-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FF5851;
    height: 80px;
    padding: 0px 24px;
  }
  
  .anyjob-edit-profile-details-modal-contents-header p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.48px;
  }
  
  .anyjob-edit-profile-details-modal-close-btn {
    padding: 10px;
    cursor: pointer;
    
  }
  
  .anyjob-edit-profile-details-modal-close-icon {
    width: 32px;
    height: 32px;
    color: black;
  }
  
  .anyjob-edit-profile-details-modal-form {
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    /* width: 100%;
    height: 550px;
    position: relative;
    overflow: scroll;
    overflow-x: hidden; */
  }
  
  .anyjob-edit-profile-details-modal-contents-sub-form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  
  .anyjob-edit-profile-details-modal-form-input-group {
    margin-top: 24px;
    width: 100%;
  }
  
  .anyjob-edit-profile-details-modal-form-input-group-label {
    color: var(--Black-1, #1A1A1A);  
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .anyjob-edit-profile-details-modal-form-input-group-field {
    outline: none;
    box-shadow: none;
  
    border-radius: 50px;
    border: 0.673px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    height: 50px;
    margin-top: 10px;
    padding: 0px 15px;
    width: 100%;
  }
  
  .anyjob-edit-profile-details-modal-form-textarea {
    outline: none;
    box-shadow: none;
  
    border-radius: 24px;
    border: 0.673px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    min-height: 120px;
    margin-top: 10px;
    padding: 15px;
    width: 100%;
  }
  
  .anyjob-edit-profile-details-modal-form-divider {
    width: 100%;
    height: 1px;
    background-color: #e2e2e2;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  .anyjob-edit-profile-details-modal-form-err-msg {
    font-size: 12px;
    color: #FF5851;
    margin-top: 6px;
  }
  
  .anyjob-edit-profile-details-modal-form-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
  }
  
  .anyjob-edit-profile-details-modal-form-actions-cancel {
    cursor: pointer;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
  
  .anyjob-edit-profile-details-modal-form-actions-save {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    width: 250px;
    height: 50px;
    margin-left: 30px;
  }
  
  
  
  .anyjob-edit-profile-details-upload-profile {
    display: flex;
    flex-direction: column;
    align-items: center;  
    margin: 24px;
    gap: 20px;
  }
  
  .anyjob-edit-profile-details-profile-icon-container {
    border: 3px solid #e6e6e6;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px;
    background-color: #fff;
  
    box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
  }
  
  .anyjob-edit-profile-details-profile-icon {
    width: 64px;
    height: 64px;
    color: #e6e6e6;
  }
  
  .anyjob-edit-profile-details-upload-profile-picture-btn {
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    display: flex;
    padding: 12px 30px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-left: 32px;
  }
  
  .anyjob-edit-profile-details-profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 148px;
    border: 4.44px solid var(--neutral-white, #FFF);
    box-shadow: 4.44px 5.92px 14.8px 7.4px rgba(71, 71, 71, 0.04);
  }
} 
