
@media screen and (min-width: 860px) {
  .anyjob-verify-acct-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1,
  }
  
  .anyjob-verify-acct-modal-contents {
    width: 650px;
    height: 700px;
    background-color: #FFF;
    border-radius: 24px;
    align-self: center;
    justify-self: center;
    margin-top: 20;
  }
  
  .anyjob-verify-acct-modal-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FF5851;
    height: 80px;
    padding: 0px 24px;
  }
  
  .anyjob-verify-acct-modal-contents-header p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.48px;
  }
  
  .anyjob-verify-acct-modal-close-btn {
    padding: 10px;
    cursor: pointer;
    
  }
  
  .anyjob-verify-acct-modal-close-icon {
    width: 32px;
    height: 32px;
    color: black;
  }
  
  .anyjob-verify-acct-modal-form {
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-verify-acct-modal-contents-sub-form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 750px;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-verify-acct-modal-form-err-msg {
    font-size: 12px;
    color: #FF5851;
    margin-top: 6px;
  }
  
  .anyjob-verify-acct-modal-form-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
  }
  
  .anyjob-verify-acct-modal-form-actions-cancel {
    cursor: pointer;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
  
  .anyjob-verify-acct-modal-form-actions-save {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    width: 250px;
    height: 50px;
    margin-left: 30px;
  }


  .anyjob-verify-acct-modal-otp-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    margin-bottom: 20px;
  }

  .anyjob-verify-acct-modal-img-0 {
    height: 155px;

  }

  .anyjob-verify-acct-modal-img-1 {
    height: 90px;

  }

  .anyjob-verify-acct-modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #1A1A1A;
    margin-top: 20px;
    text-align: center;
  }

  .anyjob-verify-acct-modal-sub-title {
    font-size: 16px;
    color: #555555;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .anyjob-verify-acct-modal-input-container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    width: 100%;
  }

  .anyjob-verify-acct-modal-input-label {
    font-size: 14px;
    color: #555555;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .anyjob-verify-acct-modal-otp-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .anyjob-verify-acct-modal-otp-input {
    border-radius: 8px;
    border: 1px solid var(--Grey-2, #BDBDBD);
    width: 64px;
    height: 64px;
    margin-right: 20px;
    display: flex;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
  }

  .anyjob-verify-acct-modal-otp-input:focus {
    border-radius: 8px;
    border: 1px solid var(--Grey-2, #FF5851);
    width: 64px;
    height: 64px;
  }

  .anyjob-verify-acct-modal-btn {
    background-color: #F7CE38;
    width: 470px;
    height: 64px;
    border-radius: 50px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
  }

  .anyjob-verify-acct-modal-btn:hover {
    background-color: #EDC635;
    width: 465px;
    height: 64px;
  }

  .anyjob-verify-acct-modal-btn-1 {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    padding: 15px;
    letter-spacing: 1px;
    color: #FF5851;
  }

  .anyjob-verify-acct-modal-btn-1-disabled {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    padding: 15px;
    letter-spacing: 1px;
    color: #BDBDBD;
  }

  .anyjob-verify-acct-modal-resend-otp-container {
    display: flex;
    align-items: center;
  }

  .anyjob-verify-acct-modal-resend-otp-container p {
    font-size: 16px;
    color: #1A1A1A;
  }

  .anyjob-verify-acct-modal-err {
    color: #FF5851;
    font-size: 12px;
    margin-top: 10px;
  }
  
}

@media screen and (min-width: 650px) and (max-width: 859px) {
  .anyjob-verify-acct-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1,
  }
  
  .anyjob-verify-acct-modal-contents {
    width: 650px;
    height: 700px;
    background-color: #FFF;
    border-radius: 24px;
    align-self: center;
    justify-self: center;
    margin-top: 20;
  }
  
  .anyjob-verify-acct-modal-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FF5851;
    height: 80px;
    padding: 0px 24px;
  }
  
  .anyjob-verify-acct-modal-contents-header p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.48px;
  }
  
  .anyjob-verify-acct-modal-close-btn {
    padding: 10px;
    cursor: pointer;
    
  }
  
  .anyjob-verify-acct-modal-close-icon {
    width: 32px;
    height: 32px;
    color: black;
  }
  
  .anyjob-verify-acct-modal-form {
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-verify-acct-modal-contents-sub-form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 750px;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-verify-acct-modal-form-err-msg {
    font-size: 12px;
    color: #FF5851;
    margin-top: 6px;
  }
  
  .anyjob-verify-acct-modal-form-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
  }
  
  .anyjob-verify-acct-modal-form-actions-cancel {
    cursor: pointer;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
  
  .anyjob-verify-acct-modal-form-actions-save {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    width: 250px;
    height: 50px;
    margin-left: 30px;
  }


  .anyjob-verify-acct-modal-otp-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    margin-bottom: 20px;
  }

  .anyjob-verify-acct-modal-img-0 {
    height: 155px;

  }

  .anyjob-verify-acct-modal-img-1 {
    height: 90px;

  }

  .anyjob-verify-acct-modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #1A1A1A;
    margin-top: 20px;
    text-align: center;
  }

  .anyjob-verify-acct-modal-sub-title {
    font-size: 16px;
    color: #555555;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .anyjob-verify-acct-modal-input-container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    width: 100%;
  }

  .anyjob-verify-acct-modal-input-label {
    font-size: 14px;
    color: #555555;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .anyjob-verify-acct-modal-otp-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .anyjob-verify-acct-modal-otp-input {
    border-radius: 8px;
    border: 1px solid var(--Grey-2, #BDBDBD);
    width: 64px;
    height: 64px;
    margin-right: 20px;
    display: flex;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
  }

  .anyjob-verify-acct-modal-otp-input:focus {
    border-radius: 8px;
    border: 1px solid var(--Grey-2, #FF5851);
    width: 64px;
    height: 64px;
  }

  .anyjob-verify-acct-modal-btn {
    background-color: #F7CE38;
    width: 470px;
    height: 64px;
    border-radius: 50px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
  }

  .anyjob-verify-acct-modal-btn:hover {
    background-color: #EDC635;
    width: 465px;
    height: 64px;
  }

  .anyjob-verify-acct-modal-btn-1 {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    padding: 15px;
    letter-spacing: 1px;
    color: #FF5851;
  }

  .anyjob-verify-acct-modal-btn-1-disabled {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    padding: 15px;
    letter-spacing: 1px;
    color: #BDBDBD;
  }

  .anyjob-verify-acct-modal-resend-otp-container {
    display: flex;
    align-items: center;
  }

  .anyjob-verify-acct-modal-resend-otp-container p {
    font-size: 16px;
    color: #1A1A1A;
  }

  .anyjob-verify-acct-modal-err {
    color: #FF5851;
    font-size: 12px;
    margin-top: 10px;
  }
  
}

@media screen and (max-width: 649px) {
  .anyjob-verify-acct-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1,
  }
  
  .anyjob-verify-acct-modal-contents {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    align-self: center;
    justify-self: center;
    margin-top: 20;
  }
  
  .anyjob-verify-acct-modal-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FF5851;
    height: 80px;
    padding: 0px 24px;
  }
  
  .anyjob-verify-acct-modal-contents-header p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.48px;
  }
  
  .anyjob-verify-acct-modal-close-btn {
    padding: 10px;
    cursor: pointer;
    
  }
  
  .anyjob-verify-acct-modal-close-icon {
    width: 32px;
    height: 32px;
    color: black;
  }
  
  .anyjob-verify-acct-modal-form {
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
  }
  
  .anyjob-verify-acct-modal-contents-sub-form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 750px;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .anyjob-verify-acct-modal-form-err-msg {
    font-size: 12px;
    color: #FF5851;
    margin-top: 6px;
  }
  
  .anyjob-verify-acct-modal-form-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
  }
  
  .anyjob-verify-acct-modal-form-actions-cancel {
    cursor: pointer;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
  
  .anyjob-verify-acct-modal-form-actions-save {
    border-radius: 100px;
    background: var(--Primary-Color, #F7CE38);
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    width: 250px;
    height: 50px;
    margin-left: 30px;
  }


  .anyjob-verify-acct-modal-otp-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    margin-bottom: 20px;
  }

  .anyjob-verify-acct-modal-img-0 {
    height: 120px;
    margin-top: 20px;

  }

  .anyjob-verify-acct-modal-img-1 {
    height: 90px;

  }

  .anyjob-verify-acct-modal-title {
    font-size: 18px;
    font-weight: 600;
    color: #1A1A1A;
    margin-top: 20px;
    text-align: center;
  }

  .anyjob-verify-acct-modal-sub-title {
    font-size: 12px;
    color: #555555;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .anyjob-verify-acct-modal-input-container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    width: 100%;
  }

  .anyjob-verify-acct-modal-input-label {
    font-size: 14px;
    color: #555555;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .anyjob-verify-acct-modal-otp-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .anyjob-verify-acct-modal-otp-input {
    border-radius: 8px;
    border: 1px solid var(--Grey-2, #BDBDBD);
    width: 50px;
    height: 50px;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
  }

  .anyjob-verify-acct-modal-otp-input:focus {
    border-radius: 8px;
    border: 1px solid var(--Grey-2, #FF5851);
    width: 52px;
    height: 52px;
  }

  .anyjob-verify-acct-modal-btn {
    background-color: #F7CE38;
    max-width: 350px;
    width: 100%;
    height: 50px;
    border-radius: 50px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
  }

  .anyjob-verify-acct-modal-btn:hover {
    background-color: #EDC635;
  }

  .anyjob-verify-acct-modal-btn-1 {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    padding: 15px;
    letter-spacing: 1px;
    color: #FF5851;
  }

  .anyjob-verify-acct-modal-btn-1-disabled {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    padding: 15px;
    letter-spacing: 1px;
    color: #BDBDBD;
  }

  .anyjob-verify-acct-modal-resend-otp-container {
    display: flex;
    align-items: center;
  }

  .anyjob-verify-acct-modal-resend-otp-container p {
    font-size: 16px;
    color: #1A1A1A;
  }

  .anyjob-verify-acct-modal-err {
    color: #FF5851;
    font-size: 12px;
    margin-top: 10px;
  }
  
}
