@media screen and (min-width: 1300px) {
  .anyjob-homepage-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-homepage-hero {
    background: #F7CE38;
    display: flex;
    flex-direction: row;
  }

  .anyjob-homepage-hero-image-container {
    padding-top: 150px;
    flex: 1;
    /* width: 50%; */
  }

  .anyjob-homepage-hero-image-container img {
    width: 670px;
  }

  .anyjob-homepage-hero-contents {
    padding-top: 200px;
    padding-left: 100px;
    flex: 1;
    /* width: 50%; */
  }

  .anyjob-homepage-hero-contents-heading {
    color: #1A1A1A;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-hero-contents-desc {
    color: #555;
    margin-top: 24px;
    margin-right: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
  }


  .anyjob-homepage-hero-contents-cta {
    display: flex;
    width: 281px;
    height: 56px;
    background: #FFF;
    align-self: baseline;
    margin-top: 60px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center; 
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .anyjob-homepage-hero-stats-container {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 50px;
    gap: 50px;
  }
  
  .anyjob-homepage-hero-stats-text {
    font-weight: 600;
    color: #1A1A1A;
    font-size: 16px;
  }

  .anyjob-homepage-hero-contents-brows-job-container {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 177px;
    border-radius: 16px;
    background: #FFF;
    margin-top: 60px;
    height: 80px;
    margin-right: 100px;
  }

  .anyjob-partnering-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 50px;
    overflow-x: scroll;
  }

  .anyjob-partnering-brand img {
    margin: 0px 11px;
  }

  .anyjob-homepage-heading {
    color: #1A1A1A;
    font-size: 42px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-desc {
    color: #555;
    margin-top: 10px;
    font-size: 18px;
    /* font-style: normal; */
    font-weight: 500;
    /* line-height: 150%; 36px */
  }

  .anyjob-homepage-categories {
    margin-top: 140px;
  }

  .anyjob-homepage-categories-title {
    text-align: center;
  }

  .anyjob-homepage-categories-grid-container {
    margin-top: 100px;
    margin-left: 100px;
    margin-right: 100px;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 4 equal-width columns */
    gap: 10px; 
  }

  .anyjob-homepage-categories-grid-item {
    width: 286px;
    height: 292px;
    padding: 0px 20px;
    border-radius: 24px;
    background: #FFF;
    margin-top: 50px;
    margin-bottom: 30px;
    /* Card Shadow */
    box-shadow: 0px 10px 60px 0px rgba(120, 120, 120, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .anyjob-homepage-categories-grid-item:hover {
    border-bottom:  8px solid  #F7CE38;
  }

  .anyjob-homepage-categories-grid-item img {  
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: 103px;
  }

  .anyjob-homepage-categories-grid-item h2 {
    color: #1A1A1A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }

  .anyjob-homepage-categories-grid-item p {
    color: #767676;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    margin-top: 10px;
  }

  .anyjob-homepage-signup {
    border-radius: 50px;
    background: #F7CE38;
    display: flex;
    width: 200px;
    height: 60px;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 40px;
  }

  .anyjob-homepage-freelancer-sec {
    display: flex;
    flex-direction: row;
    margin: 100px;

  }

  .anyjob-homepage-freelancer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50px;
    margin-right: 100px;
  }

  .anyjob-homepage-freelancer-content-btn {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-client-sec {
    display: flex;
    flex-direction: row;
    margin: 100px;

  }

  .anyjob-homepage-client-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 100px;
    margin-right: 150px;
  }

  .anyjob-homepage-client-content-btn {
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #F7CE38);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-subscription {
    background-image: url('../../assets/svg/subcribe-bg.svg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 576px;
    margin-top: 100px;
    padding: 100px 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .anyjob-homepage-subscription p {
    text-align: center;
    margin-top: 20px;
  }

  .anyjob-homepage-subscription-input-container {
    border-radius: 50px;
    height: 54px;
    border: 1px solid #D2D2D2;
    background: #FFF;
    display: flex;
    padding-left: 0px;
    justify-content: flex-end;
    align-items: center;
    width: 450px;
    margin-top: 60px;
  }

  .anyjob-homepage-subscription-input-container button {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .anyjob-homepage-subscription-input-container button:hover {
    background-color: #FB4D46;
  }

  .anyjob-homepage-subscription-input-container input {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none;
    width: 220px;
  }

  .anyjob-homepage-subscription-input-container input:focus {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none
  }
}

@media screen and (min-width: 1024px) and (max-width: 1299px) {
  .anyjob-homepage-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-homepage-hero {
    background: #F7CE38;
    display: flex;
    flex-direction: row;
  }

  .anyjob-homepage-hero-image-container {
    padding-top: 150px;
    flex: 1;
  }

  .anyjob-homepage-hero-image-container img {
    width: 670px;
  }

  .anyjob-homepage-hero-contents {
    padding-top: 140px;
    padding-left: 100px;
    flex: 1;
  }

  .anyjob-homepage-hero-contents-heading {
    color: #1A1A1A;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-hero-contents-desc {
    color: #555;
    margin-top: 24px;
    margin-right: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
  }


  .anyjob-homepage-hero-contents-cta {
    display: flex;
    width: 281px;
    height: 56px;
    background: #FFF;
    align-self: baseline;
    margin-top: 60px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center; 
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .anyjob-homepage-hero-stats-container {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 50px;
    gap: 50px;
  }
  
  .anyjob-homepage-hero-stats-text {
    font-weight: 600;
    color: #1A1A1A;
    font-size: 16px;
  }

  .anyjob-homepage-hero-contents-brows-job-container {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 177px;
    border-radius: 16px;
    background: #FFF;
    margin-top: 60px;
    height: 80px;
    margin-right: 100px;
  }

  .anyjob-partnering-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 50px;
    overflow-x: scroll;
  }

  .anyjob-partnering-brand img {
    margin: 0px 11px;
  }

  .anyjob-homepage-heading {
    color: #1A1A1A;
    font-size: 36px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-desc {
    color: #555;
    margin-top: 10px;
    font-size: 18px;
    /* font-style: normal; */
    font-weight: 500;
    /* line-height: 150%; 36px */
  }

  .anyjob-homepage-categories {
    margin-top: 140px;
  }

  .anyjob-homepage-categories-title {
    text-align: center;
  }

  .anyjob-homepage-categories-grid-container {
    margin-top: 100px;
    margin-left: 100px;
    margin-right: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 4 equal-width columns */
    gap: 10px; 
  }

  .anyjob-homepage-categories-grid-item {
    width: 266px;
    height: 292px;
    padding: 0px 20px;
    border-radius: 24px;
    background: #FFF;
    margin-top: 50px;
    margin-bottom: 30px;
    /* Card Shadow */
    box-shadow: 0px 10px 60px 0px rgba(120, 120, 120, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .anyjob-homepage-categories-grid-item:hover {
    border-bottom:  8px solid  #F7CE38;
  }

  .anyjob-homepage-categories-grid-item img {  
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: 103px;
  }

  .anyjob-homepage-categories-grid-item h2 {
    color: #1A1A1A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }

  .anyjob-homepage-categories-grid-item p {
    color: #767676;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    margin-top: 10px;
  }

  .anyjob-homepage-signup {
    border-radius: 50px;
    background: #F7CE38;
    display: flex;
    width: 200px;
    height: 60px;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 40px;
  }

  .anyjob-homepage-freelancer-sec {
    display: flex;
    flex-direction: row;
    margin: 100px;

  }

  .anyjob-homepage-freelancer-sec img {
    width: 450px;
  }

  .anyjob-homepage-freelancer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50px;
    /* margin-right: 100px; */
  }

  .anyjob-homepage-freelancer-content-btn {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-client-sec {
    display: flex;
    flex-direction: row;
    margin: 100px;

  }

  .anyjob-homepage-client-sec img {
    width: 450px;
  }

  .anyjob-homepage-client-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 100px; */
    margin-right: 150px;
  }
  

  .anyjob-homepage-client-content-btn {
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #F7CE38);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-subscription {
    background-image: url('../../assets/svg/subcribe-bg.svg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 576px;
    margin-top: 100px;
    padding: 100px 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .anyjob-homepage-subscription p {
    text-align: center;
    margin-top: 20px;
  }

  .anyjob-homepage-subscription-input-container {
    border-radius: 50px;
    height: 54px;
    border: 1px solid #D2D2D2;
    background: #FFF;
    display: flex;
    padding-left: 0px;
    justify-content: flex-end;
    align-items: center;
    width: 450px;
    margin-top: 60px;
  }

  .anyjob-homepage-subscription-input-container button {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .anyjob-homepage-subscription-input-container button:hover {
    background-color: #FB4D46;
  }

  .anyjob-homepage-subscription-input-container input {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none;
    width: 220px;
  }

  .anyjob-homepage-subscription-input-container input:focus {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .anyjob-homepage-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-homepage-hero {
    background: #F7CE38;
    display: flex;
    flex-direction: row;
  }

  .anyjob-homepage-hero-image-container {
    padding-top: 150px;
    flex: 1;
  }

  .anyjob-homepage-hero-image-container img {
    width: 670px;
  }

  .anyjob-homepage-hero-contents {
    padding-top: 100px;
    padding-left: 100px;
    flex: 1;
  }

  .anyjob-homepage-hero-contents-heading {
    color: #1A1A1A;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-hero-contents-desc {
    color: #555;
    margin-top: 24px;
    margin-right: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
  }


  .anyjob-homepage-hero-contents-cta {
    display: flex;
    width: 220px;
    height: 45px;
    background: #FFF;
    align-self: baseline;
    margin-top: 30px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center; 
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .anyjob-homepage-hero-stats-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 50px;
    gap: 20px;
  }
  
  .anyjob-homepage-hero-stats-text {
    font-weight: 600;
    color: #1A1A1A;
    font-size: 14px;
  }

  .anyjob-homepage-hero-contents-brows-job-container {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 177px;
    border-radius: 16px;
    background: #FFF;
    margin-top: 60px;
    height: 80px;
    margin-right: 100px;
  }

  .anyjob-partnering-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 50px;
    overflow-x: scroll;
  }

  .anyjob-partnering-brand img {
    margin: 0px 11px;
  }

  .anyjob-homepage-heading {
    color: #1A1A1A;
    font-size: 32px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-desc {
    color: #555;
    margin-top: 10px;
    font-size: 14px;
    /* font-style: normal; */
    font-weight: 500;
    /* line-height: 150%; 36px */
  }

  .anyjob-homepage-categories {
    margin-top: 140px;
  }

  .anyjob-homepage-categories-title {
    text-align: center;
  }

  .anyjob-homepage-categories-grid-container {
    margin-top: 100px;
    margin-left: 100px;
    margin-right: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 4 equal-width columns */
    gap: 10px; 
  }

  .anyjob-homepage-categories-grid-item {
    width: 266px;
    height: 292px;
    padding: 0px 20px;
    border-radius: 24px;
    background: #FFF;
    margin-top: 50px;
    margin-bottom: 30px;
    /* Card Shadow */
    box-shadow: 0px 10px 60px 0px rgba(120, 120, 120, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .anyjob-homepage-categories-grid-item:hover {
    border-bottom:  8px solid  #F7CE38;
  }

  .anyjob-homepage-categories-grid-item img {  
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: 103px;
  }

  .anyjob-homepage-categories-grid-item h2 {
    color: #1A1A1A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }

  .anyjob-homepage-categories-grid-item p {
    color: #767676;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    margin-top: 10px;
  }

  .anyjob-homepage-signup {
    border-radius: 50px;
    background: #F7CE38;
    display: flex;
    width: 200px;
    height: 60px;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 40px;
  }

  .anyjob-homepage-freelancer-sec {
    display: flex;
    flex-direction: row;
    margin: 100px;

  }

  .anyjob-homepage-freelancer-sec img {
    width: 350px;
  }

  .anyjob-homepage-freelancer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50px;
    /* margin-right: 100px; */
  }

  .anyjob-homepage-freelancer-content-btn {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-client-sec {
    display: flex;
    flex-direction: row;
    margin: 100px;

  }

  .anyjob-homepage-client-sec img {
    width: 350px;
  }

  .anyjob-homepage-client-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
  }
  

  .anyjob-homepage-client-content-btn {
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #F7CE38);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-subscription {
    background-image: url('../../assets/svg/subcribe-bg.svg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 576px;
    margin-top: 100px;
    padding: 100px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .anyjob-homepage-subscription p {
    text-align: center;
    margin-top: 20px;
  }

  .anyjob-homepage-subscription-input-container {
    border-radius: 50px;
    height: 54px;
    border: 1px solid #D2D2D2;
    background: #FFF;
    display: flex;
    padding-left: 0px;
    justify-content: flex-end;
    align-items: center;
    width: 450px;
    margin-top: 60px;
  }

  .anyjob-homepage-subscription-input-container button {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .anyjob-homepage-subscription-input-container button:hover {
    background-color: #FB4D46;
  }

  .anyjob-homepage-subscription-input-container input {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none;
    width: 220px;
  }

  .anyjob-homepage-subscription-input-container input:focus {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .anyjob-homepage-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-homepage-hero {
    background: #F7CE38;
    display: flex;
    flex-direction: column;
  }

  .anyjob-homepage-hero-image-container {
    padding-top: 50px;
    flex: 1;
  }

  .anyjob-homepage-hero-image-container img {
    width: 670px;
  }

  .anyjob-homepage-hero-contents {
    padding-top: 100px;
    padding-left: 50px;
    flex: 1;
  }

  .anyjob-homepage-hero-contents-heading {
    color: #1A1A1A;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-hero-contents-desc {
    color: #555;
    margin-top: 24px;
    margin-right: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
  }


  .anyjob-homepage-hero-contents-cta {
    display: flex;
    width: 220px;
    height: 45px;
    background: #FFF;
    align-self: baseline;
    margin-top: 30px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center; 
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .anyjob-homepage-hero-stats-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 50px;
    gap: 20px;
  }
  
  .anyjob-homepage-hero-stats-text {
    font-weight: 600;
    color: #1A1A1A;
    font-size: 14px;
  }

  .anyjob-homepage-hero-contents-brows-job-container {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 177px;
    border-radius: 16px;
    background: #FFF;
    margin-top: 60px;
    height: 80px;
    margin-right: 100px;
  }

  .anyjob-partnering-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    overflow-x: scroll;
  }

  .anyjob-partnering-brand img {
    margin: 0px 11px;
  }

  .anyjob-homepage-heading {
    color: #1A1A1A;
    font-size: 32px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-desc {
    color: #555;
    margin-top: 10px;
    font-size: 14px;
    /* font-style: normal; */
    font-weight: 500;
    /* line-height: 150%; 36px */
  }

  .anyjob-homepage-categories {
    margin-top: 140px;
  }

  .anyjob-homepage-categories-title {
    text-align: center;
  }

  .anyjob-homepage-categories-grid-container {
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr); /* Creates 4 equal-width columns */
    gap: 10px; 
  }

  .anyjob-homepage-categories-grid-item {
    width: 240px;
    height: 260px;
    padding: 0px 20px;
    border-radius: 24px;
    background: #FFF;
    margin-top: 50px;
    margin-bottom: 30px;
    /* Card Shadow */
    box-shadow: 0px 10px 60px 0px rgba(120, 120, 120, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;
    cursor: pointer;
  }

  .anyjob-homepage-categories-grid-item:hover {
    border-bottom:  8px solid  #F7CE38;
  }

  .anyjob-homepage-categories-grid-item img {  
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: 80px;
  }

  .anyjob-homepage-categories-grid-item h2 {
    color: #1A1A1A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }

  .anyjob-homepage-categories-grid-item p {
    color: #767676;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    margin-top: 10px;
  }

  .anyjob-homepage-signup {
    border-radius: 50px;
    background: #F7CE38;
    display: flex;
    width: 200px;
    height: 60px;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 40px;
  }

  .anyjob-homepage-freelancer-sec {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 100px 50px;

  }

  .anyjob-homepage-freelancer-sec img {
    width: 350px;
  }

  .anyjob-homepage-freelancer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50px;
    margin-bottom: 50px;
    /* margin-right: 100px; */
  }

  .anyjob-homepage-freelancer-content-btn {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-client-sec {
    display: flex;
    flex-direction: column;
    margin: 100px;

  }

  .anyjob-homepage-client-sec img {
    width: 350px;
  }

  .anyjob-homepage-client-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
    margin-bottom: 50px;
  }
  

  .anyjob-homepage-client-content-btn {
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #F7CE38);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-subscription {
    background-image: url('../../assets/svg/subcribe-bg.svg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 576px;
    margin-top: 100px;
    padding: 100px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .anyjob-homepage-subscription p {
    text-align: center;
    margin-top: 20px;
  }

  .anyjob-homepage-subscription-input-container {
    border-radius: 50px;
    height: 54px;
    border: 1px solid #D2D2D2;
    background: #FFF;
    display: flex;
    padding-left: 0px;
    justify-content: flex-end;
    align-items: center;
    width: 450px;
    margin-top: 60px;
  }

  .anyjob-homepage-subscription-input-container button {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .anyjob-homepage-subscription-input-container button:hover {
    background-color: #FB4D46;
  }

  .anyjob-homepage-subscription-input-container input {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none;
    width: 220px;
  }

  .anyjob-homepage-subscription-input-container input:focus {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none
  }
}

@media screen and (max-width: 480px) {
  .anyjob-homepage-container {
    width: 100%;
    background: #FFF;
    height: 100vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }

  .anyjob-homepage-hero {
    background: #F7CE38;
    display: flex;
    flex-direction: column;
  }

  .anyjob-homepage-hero-image-container {
    padding-top: 50px;
    flex: 1;
  }

  .anyjob-homepage-hero-image-container img {
    width: 670px;
  }

  .anyjob-homepage-hero-contents {
    padding-top: 50px;
    padding-left: 20px;
    flex: 1;
  }

  .anyjob-homepage-hero-contents-heading {
    color: #1A1A1A;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-hero-contents-desc {
    color: #555;
    margin-top: 10px;
    margin-right: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
  }


  .anyjob-homepage-hero-contents-cta {
    display: flex;
    width: 220px;
    height: 45px;
    background: #FFF;
    align-self: baseline;
    margin-top: 30px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: var(--Black-1, #1A1A1A);
    text-align: center; 
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .anyjob-homepage-hero-stats-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .anyjob-homepage-hero-stats-text {
    font-weight: 600;
    color: #1A1A1A;
    font-size: 14px;
  }

  .anyjob-homepage-hero-contents-brows-job-container {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 177px;
    border-radius: 16px;
    background: #FFF;
    margin-top: 60px;
    height: 80px;
    margin-right: 100px;
  }

  .anyjob-partnering-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
    overflow-x: scroll;
  }

  .anyjob-partnering-brand img {
    margin: 0px 11px;
  }

  .anyjob-homepage-heading {
    color: #1A1A1A;
    font-size: 28px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: 150%; /* 90px */
  }

  .anyjob-homepage-desc {
    color: #555;
    margin-top: 10px;
    font-size: 14px;
    /* font-style: normal; */
    font-weight: 500;
    /* line-height: 150%; 36px */
  }

  .anyjob-homepage-categories {
    margin-top: 140px;
  }

  .anyjob-homepage-categories-title {
    text-align: center;
  }

  .anyjob-homepage-categories-grid-container {
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    /* align-self: center; */
    grid-template-columns: repeat(1, 1fr); /* Creates 4 equal-width columns */
    gap: 10px; 
  }

  .anyjob-homepage-categories-grid-item {
    width: 320px;
    height: 260px;
    padding: 0px 20px;
    border-radius: 24px;
    background: #FFF;
    margin-top: 50px;
    margin-bottom: 30px;
    /* Card Shadow */
    box-shadow: 0px 10px 60px 0px rgba(120, 120, 120, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;
    cursor: pointer;
  }

  .anyjob-homepage-categories-grid-item:hover {
    border-bottom:  8px solid  #F7CE38;
  }

  .anyjob-homepage-categories-grid-item img {  
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: 120px;
  }

  .anyjob-homepage-categories-grid-item h2 {
    color: #1A1A1A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }

  .anyjob-homepage-categories-grid-item p {
    color: #767676;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    margin-top: 10px;
  }

  .anyjob-homepage-signup {
    border-radius: 50px;
    background: #F7CE38;
    display: flex;
    width: 200px;
    height: 60px;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 40px;
  }

  .anyjob-homepage-freelancer-sec {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 100px 20px;

  }

  .anyjob-homepage-freelancer-sec img {
    width: 350px;
  }

  .anyjob-homepage-freelancer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 50px; */
    margin-bottom: 50px;
    /* margin-right: 100px; */
  }

  .anyjob-homepage-freelancer-content-btn {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-client-sec {
    display: flex;
    flex-direction: column;
    margin: 100px 20px;

  }

  .anyjob-homepage-client-sec img {
    width: 350px;
  }

  .anyjob-homepage-client-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
    margin-bottom: 50px;
  }
  

  .anyjob-homepage-client-content-btn {
    color: #1A1A1A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #F7CE38);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }

  .anyjob-homepage-subscription {
    background-image: url('../../assets/svg/subcribe-bg.svg');
    background-size: cover;
    background-position: center;
    width: 100%;
    /* height: 576px; */
    margin-top: 100px;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
  }

  .anyjob-homepage-subscription-heading {
    font-size: 24px;
    text-align: left;
  }

  .anyjob-homepage-subscription p {
    text-align: left;
    margin-top: 20px;
  }

  .anyjob-homepage-subscription-input-container {
    border-radius: 50px;
    height: 54px;
    border: 1px solid #D2D2D2;
    background: #FFF;
    display: flex;
    padding-left: 0px;
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    width: 350px;
    margin-top: 60px;
  }

  .anyjob-homepage-subscription-input-container button {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    border-radius: 50px;
    background: var(--Secondary-Color, #FF5851);
    box-shadow: 0px 15px 30px 0px rgba(255, 88, 81, 0.08);
    width: 150px;
    height: 55px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .anyjob-homepage-subscription-input-container button:hover {
    background-color: #FB4D46;
  }

  .anyjob-homepage-subscription-input-container input {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none;
    width: 180px;
  }

  .anyjob-homepage-subscription-input-container input:focus {
    border: none;
    outline: none;
    background-color: transparent; /* To make it transparent */
    box-shadow: none
  }
}

.anyjob-homepage-hero-contents-heading-italic {
  font-style: italic;
}

