@media screen and (min-width: 620px) {
  .anyjob-notifications-modal {
    display: flex;
    position: relative;
    height: 100vh;
    flex: 1,
  }
  
  .anyjob-notifications-modal-contents {
    width: 552px;
    height: 432px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(174, 173, 173, 0.03);
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 40px;
  }

  .anyjob-notifications-modal-notification-item-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  
  .anyjob-notifications-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }
  
  .anyjob-notifications-modal-header-title {
    color: var(--neutral-gray-1828282, #828282);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
  
  .anyjob-notifications-modal-header-cta {
    color: var(--Secondary-Color, #FF5851);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
    cursor: pointer;
  }
  
  .anyjob-notifications-modal-content-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
  }
  
  .anyjob-notifications-modal-content-empty-state {
    display: flex;
    height: 360px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .anyjob-notifications-modal-content-empty-state p {
    color: var(--neutral-gray-1828282, #828282);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-top: 10px;
  }
  
  .anyjob-notifications-modal-notification-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px 24px;
    cursor: pointer;
  }
  
  .anyjob-notifications-modal-notification-item:hover {
    background-color: #f3f3f3;
  }
  
  .anyjob-notifications-modal-notification-item-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .anyjob-notifications-modal-notification-item-title {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
  }
  
  .anyjob-notifications-modal-notification-item-message {
    color: var(--neutral-gray-1828282, #828282);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}

@media screen and (min-width: 420px) and (max-width: 619px) {
  .anyjob-notifications-modal {
    display: flex;
    position: relative;
    height: 100vh;
    flex: 1,
  }
  
  .anyjob-notifications-modal-contents {
    width: 352px;
    height: 432px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(174, 173, 173, 0.03);
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 40px;
  }
  
  .anyjob-notifications-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }

  .anyjob-notifications-modal-notification-item-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  
  .anyjob-notifications-modal-header-title {
    color: var(--neutral-gray-1828282, #828282);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
  
  .anyjob-notifications-modal-header-cta {
    color: var(--Secondary-Color, #FF5851);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
    cursor: pointer;
  }
  
  .anyjob-notifications-modal-content-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
  }
  
  .anyjob-notifications-modal-content-empty-state {
    display: flex;
    height: 360px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .anyjob-notifications-modal-content-empty-state p {
    color: var(--neutral-gray-1828282, #828282);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-top: 10px;
  }
  
  .anyjob-notifications-modal-notification-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px 24px;
    cursor: pointer;
  }
  
  .anyjob-notifications-modal-notification-item:hover {
    background-color: #f3f3f3;
  }
  
  .anyjob-notifications-modal-notification-item-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .anyjob-notifications-modal-notification-item-title {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
  }
  
  .anyjob-notifications-modal-notification-item-message {
    color: var(--neutral-gray-1828282, #828282);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}

@media screen  and (max-width: 419px) {
  .anyjob-notifications-modal {
    display: flex;
    position: relative;
    height: 100vh;
    flex: 1,
  }
  
  .anyjob-notifications-modal-contents {
    width: auto;

    height: 432px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(174, 173, 173, 0.03);
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 40px;
  }
  
  .anyjob-notifications-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }

  .anyjob-notifications-modal-notification-item-heading {
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
  }
  
  .anyjob-notifications-modal-header-title {
    color: var(--neutral-gray-1828282, #828282);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
  
  .anyjob-notifications-modal-header-cta {
    color: var(--Secondary-Color, #FF5851);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
    cursor: pointer;
  }
  
  .anyjob-notifications-modal-content-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
  }
  
  .anyjob-notifications-modal-content-empty-state {
    display: flex;
    height: 360px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .anyjob-notifications-modal-content-empty-state p {
    color: var(--neutral-gray-1828282, #828282);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-top: 10px;
  }
  
  .anyjob-notifications-modal-notification-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px 24px;
    cursor: pointer;
  }
  
  .anyjob-notifications-modal-notification-item:hover {
    background-color: #f3f3f3;
  }
  
  .anyjob-notifications-modal-notification-item-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .anyjob-notifications-modal-notification-item-title {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);  
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
  }
  
  .anyjob-notifications-modal-notification-item-message {
    color: var(--neutral-gray-1828282, #828282);  
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}

